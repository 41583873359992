var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-base",
    [
      _c(
        "template",
        { slot: "form" },
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("text-field", {
                            attrs: {
                              required: true,
                              type: _vm.currentPasswordFieldType,
                              rules:
                                _vm.$validator.rules.required(
                                  "Current password"
                                ),
                              label: "Current Password",
                              "append-icon": _vm.iconShowCurrentPassword,
                            },
                            on: {
                              "click:append": function ($event) {
                                return _vm.toggleShowCurrentPassword()
                              },
                            },
                            model: {
                              value: _vm.currentPassword,
                              callback: function ($$v) {
                                _vm.currentPassword = $$v
                              },
                              expression: "currentPassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("text-field", {
                            attrs: {
                              required: true,
                              type: _vm.passwordFieldType,
                              rules: _vm.$validator.rules.password,
                              "append-icon": _vm.iconShowPassword,
                              label: "New Password",
                            },
                            on: {
                              "click:append": function ($event) {
                                return _vm.toggleShowPassword()
                              },
                            },
                            model: {
                              value: _vm.newPassword,
                              callback: function ($$v) {
                                _vm.newPassword = $$v
                              },
                              expression: "newPassword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "form-actions" },
        [
          _c("password-restrictions", { attrs: { password: _vm.newPassword } }),
          _c("div", { staticClass: "mt-6" }, [
            _c("p", { staticClass: "text-center small" }, [
              _vm._v(
                " You will be asked to login again after changing the password! "
              ),
            ]),
          ]),
          _c("action-group", {
            attrs: {
              "confirm-icon": "upload",
              "confirm-label": "Change Password",
            },
            on: {
              cancel: function ($event) {
                return _vm.$emit("close")
              },
              confirm: _vm.submit,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }