var render = function render(_c, _vm) {
  return _c(
    "v-overlay",
    {
      attrs: {
        value: _vm.props.isVisible,
        absolute: true,
        color: _vm.props.color,
        opacity: _vm.props.opacity,
      },
    },
    [
      _c("div", { staticClass: "overlay-contents" }, [
        _c("svg", { attrs: { height: "150", width: "180" } }, [
          _c("g", [
            _c("circle", {
              attrs: { cx: "90", cy: "80", r: "60", fill: "#E5F1F7" },
            }),
            _c(
              "foreignObject",
              {
                staticClass: "node",
                attrs: { x: "60", y: "50", width: "60", height: "60" },
              },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "icon",
                    attrs: { color: "secondary lighten-4" },
                  },
                  [_vm._v("mdi-warehouse")]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c("h3", { staticClass: "align-center" }, [
          _vm._v("No warehouse or dock selected"),
        ]),
        _c("p", [
          _vm._v(
            "Please select a warehouse along with one or more docks to continue"
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }