var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { class: _vm.flat ? "elevation-0" : "" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                {
                  staticClass: "search-field",
                  attrs: { justify: "space-between" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c("text-field", {
                        staticClass: "mb-5",
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: "Search users",
                          "persistent-label": "",
                        },
                        model: {
                          value: _vm.filters.searchStr,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "searchStr", $$v)
                          },
                          expression: "filters.searchStr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "4" } },
                    [
                      _vm.novaCore.isInternalUser(_vm.$me) && !_vm.orgId
                        ? _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                tile: "",
                                "active-class": "primary",
                                dense: "",
                                title: "Show only users from the selected type",
                                mandatory: "",
                              },
                              model: {
                                value: _vm.filters.showUsersFromType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filters,
                                    "showUsersFromType",
                                    $$v
                                  )
                                },
                                expression: "filters.showUsersFromType",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "data-testid": "user-list-warehouse-toggle",
                                    value: "warehouse",
                                    primary: "",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "px-2" }, [
                                    _vm._v("Warehouse users"),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "data-testid": "user-list-carrier-toggle",
                                    value: "carrier",
                                    primary: "",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "px-2" }, [
                                    _vm._v("Carrier users"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _vm.novaCore.canUserInviteUsers(_vm.$me)
                ? _c("invite-user-dialog", { staticClass: "action-button" })
                : _vm._e(),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.$users,
              loading: _vm.loading,
              "server-items-length": _vm.$userPagination.total,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              "footer-props": _vm.footerProps,
              options: _vm.options,
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:options": function ($event) {
                _vm.options = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.fullName",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(`${item.firstName} ${item.lastName}`) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [
                    _c(
                      "copy-content",
                      {
                        attrs: {
                          content: item.id,
                          label: "Click to copy user id",
                        },
                      },
                      [_vm._v(" " + _vm._s(item.id) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.email",
                fn: function ({ item }) {
                  return [
                    _c(
                      "copy-content",
                      {
                        attrs: {
                          content: item.email,
                          label: "Click to copy user email",
                        },
                      },
                      [_vm._v(" " + _vm._s(item.email) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.role",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(_vm.getRole(item.role)) + " ")]
                },
              },
              {
                key: "item.isEmailVerified",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(item.isEmailVerified ? "Yes" : "No") + " "
                    ),
                  ]
                },
              },
              {
                key: "header.warehouseAccessList",
                fn: function () {
                  return [
                    _c("span", { staticClass: "d-inline-block mr-1" }, [
                      _vm._v("Warehouses"),
                    ]),
                    _c("help-icon-tooltip", [
                      _vm._v(
                        "List of all warehouses this user is restricted to"
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.warehouseAccessList",
                fn: function ({ item }) {
                  return [
                    item.warehouseAccessList?.length > 0
                      ? [
                          _c(
                            "v-btn",
                            {
                              staticClass: "link px-0",
                              attrs: { text: "", "x-small": "" },
                              on: {
                                click: function ($event) {
                                  _vm.warehouseDialog = {
                                    shouldShow: true,
                                    entity: item,
                                  }
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getWarehouseList(
                                      item?.warehouseAccessList
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      : _c("span", [_vm._v("Unrestricted")]),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "row-actions" },
                      [
                        _vm.isLoginAsUserVisible(item)
                          ? _c("icon-tooltip-button", {
                              attrs: {
                                "icon-class": "mr-2",
                                size: "large",
                                tooltip: "Login as User",
                                icon: "login",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.loginAsUser(item)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.isLoginAsCarrierVisible(item)
                          ? _c("user-login-as-carrier-dialog", {
                              attrs: { user: item },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "icon-tooltip-button",
                                          _vm._g(
                                            {
                                              attrs: {
                                                "icon-class": "mr-2",
                                                size: "large",
                                                tooltip: "Login as Carrier",
                                                disabled: !item.isEmailVerified,
                                                icon: "truck-delivery-outline",
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                        _c("audit-log-entity-icon-button", {
                          attrs: {
                            item: item,
                            timezone: "UTC",
                            "entity-name": `${item.firstName} ${item.lastName}`,
                            "entity-type": _vm.novaCore.DatabaseEntities.User,
                          },
                        }),
                        _vm.isLoginAsCarrierVisible(item)
                          ? _c("transfer-appointment-dialog", {
                              attrs: { "source-user": item },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "icon-tooltip-button",
                                          _vm._g(
                                            {
                                              attrs: {
                                                "icon-class": "mr-2",
                                                size: "large",
                                                tooltip:
                                                  "Transfer appointments",
                                                icon: "transit-transfer",
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                        _vm.novaCore.isInternalRole(_vm.$me.role) &&
                        item.id !== _vm.$me.id
                          ? _c("icon-tooltip-button", {
                              attrs: {
                                "icon-class": "mr-2",
                                size: "large",
                                tooltip: "Verify User Email",
                                disabled: item.isEmailVerified,
                                icon: "mdi-email-check-outline",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.verifyEmail(item)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.canUserTakeAction(item)
                          ? _c("icon-tooltip-button", {
                              attrs: {
                                "icon-class": "mr-2",
                                size: "large",
                                loading: _vm.sendingPasswordResetId === item.id,
                                tooltip: "Send Password Reset",
                                icon: "key-change",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.sendPasswordReset(item)
                                },
                              },
                            })
                          : _c("span", { staticClass: "d-inline-block mx-4" }),
                        _vm.novaCore.isInternalRole(_vm.$me.role) &&
                        item.id !== _vm.$me.id
                          ? _c("icon-tooltip-button", {
                              attrs: {
                                "icon-class": "mr-2",
                                size: "large",
                                disabled: item.isEmailVerified,
                                loading:
                                  _vm.sendingVerificationEmailId === item.id,
                                tooltip: "Resend Verification Email",
                                icon: "mdi-email-send-outline",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.resendVerificationEmail(item)
                                },
                              },
                            })
                          : _vm._e(),
                        _c("edit-user-dialog", {
                          attrs: { user: item },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (slotProps) {
                                  return [
                                    _vm.novaCore.canUserUpdateUser(
                                      _vm.$me,
                                      item.id,
                                      item
                                    ) && _vm.canUserTakeAction(item)
                                      ? _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  id: item.id,
                                                  size: "large",
                                                  color: "secondary",
                                                },
                                              },
                                              "v-icon",
                                              slotProps.attrs,
                                              false
                                            ),
                                            slotProps.on
                                          ),
                                          [_vm._v(" mdi-pencil ")]
                                        )
                                      : _c("span", {
                                          staticClass: "d-inline-block mx-4",
                                        }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm.novaCore.canUserDeleteUser(_vm.$me, item) &&
                        _vm.canUserTakeAction(item)
                          ? _c("icon-tooltip-button", {
                              attrs: {
                                size: "large",
                                tooltip: "Delete User",
                                delete: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showDeleteUserConfirmation(item)
                                },
                              },
                            })
                          : _c("span", { staticClass: "d-inline-block mx-2" }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.deleting,
              callback: function ($$v) {
                _vm.deleting = $$v
              },
              expression: "deleting",
            },
          }),
        ],
        1
      ),
      _vm.warehouseDialog.entity
        ? _c("list-warehouse-dialog", {
            attrs: {
              "warehouse-dialog": _vm.warehouseDialog,
              warehouses: _vm.warehouseDialog.entity.warehouseAccessList,
              label: `${_vm.warehouseDialog.entity.firstName} is assigned to the following locations:`,
              header: `Warehouses ${_vm.warehouseDialog.entity.firstName} is assigned to`,
            },
            on: { close: _vm.closeWarehouseDialog },
          })
        : _vm._e(),
      _vm.deleting?.length > 0
        ? _c("transfer-reserves-dialog", {
            attrs: {
              "show-dialog": _vm.showTransferReserves,
              "deleted-user": _vm.deleting[0],
            },
            on: {
              confirm: _vm.confirmTransferReservesDialog,
              close: _vm.cancelTransferReservesDialog,
            },
          })
        : _vm._e(),
      _vm.deleting?.length > 0
        ? _c("transfer-appointment-dialog", {
            attrs: {
              "show-dialog": _vm.showTransferAppointments,
              "source-user": _vm.deleting[0],
              "cancel-label": "Delete appointments",
              "cancel-color": "red",
              "cancel-icon": "delete-forever",
            },
            on: {
              confirm: _vm.confirmTransferAppointmentsDialog,
              close: _vm.cancelTransferAppointmentsDialog,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }