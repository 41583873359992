<template>
  <div class="wrapper d-flex justify-center align-center" :key="renderKey">
    <v-menu
      :offset-y="!isCompact"
      :offset-x="isCompact"
      :internal-activator="true"
      :close-on-click="true"
      :right="isCompact">
      <template v-slot:activator="{ on, attrs }">
        <button-base
          v-if="!isCompact"
          after-icon="chevron-down"
          class="content-size"
          text
          dark
          v-bind="attrs"
          v-on="on"
          width="100%"
          data-testid="account-nav-activator"
          append>
          <span class="text-truncate">
            {{ userDisplayName }}
          </span>
        </button-base>
        <v-tooltip v-else right>
          <template v-slot:activator="{ on: tooltipOn }">
            <div v-on="tooltipOn">
              <v-btn
                v-bind="attrs"
                v-on="on"
                data-testid="account-nav-activator"
                icon
                small
                class="inverted-color">
                <v-icon small>mdi-account</v-icon>
              </v-btn>
            </div>
          </template>
          <span>My Account</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item class="account-option" v-for="(item, index) in accountOptions" :key="index">
          <button-base
            small
            text
            block
            :before-icon="item.icon"
            class="text-left"
            @click="handleClick(item.action)">
            <span class="flex-grow-1 justify-start">
              {{ item.title }}
            </span>
          </button-base>
        </v-list-item>
      </v-list>
    </v-menu>

    <my-account-dialog
      :show-dialog="dialogs.showAccountDialog"
      :external-activator="true"
      @close="dialogs.showAccountDialog = false"></my-account-dialog>
  </div>
</template>

<script>
import renderMixin from '@satellite/components/mixins/renderMixin';
/**
 * @displayName Account Navigation
 */
export default {
  mixins: [renderMixin],
  props: {
    isCompact: {
      type: Boolean,
      required: false,
      default: false
    },
    accountOptions: {
      type: Array,
      required: false,
      default() {
        return [
          {
            title: 'Account',
            icon: 'account',
            action: 'openAccountDialog'
          },
          {
            title: 'Log Out',
            icon: 'logout',
            action: 'logout'
          }
        ];
      }
    }
  },
  computed: {
    userDisplayName() {
      return this.$me ? `${this.$me.firstName} ${this.$me.lastName}` : 'Guest User';
    }
  },
  data() {
    return {
      dialogs: {
        showAccountDialog: false
      }
    };
  },
  methods: {
    handleClick(action) {
      this[action]();
    },
    /**
     * Open the account dialog
     * @public
     */
    openAccountDialog() {
      this.dialogs.showAccountDialog = true;
    },
    /**
     * Logout of account
     * @public
     */
    logout() {
      this.$store.dispatch('Auth/logout');
      window.location.replace('/login');
    }
  },
  watch: {
    isCompact() {
      this.incrementRenderKey();
    }
  }
};
</script>

<style lang="scss">
.content-size {
  span {
    max-width: 100%;
  }
}

.wrapper {
  width: 100%;
}
</style>
