var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.appointmentWithWarehouse?.warehouse?.id
    ? _c("div", { staticClass: "full-height" }, [
        _c("section", { staticClass: "sidebar-content" }, [
          _c(
            "div",
            { staticClass: "sidebar" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { vertical: "", centered: false, "hide-slider": "" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", { key: "status" }, [_vm._v("Status & Details")]),
                  _c(
                    "v-tab",
                    {
                      key: "claims_support",
                      attrs: {
                        disabled: _vm.claimSupportTriggers.length === 0,
                      },
                    },
                    [_vm._v(" Claims Support ")]
                  ),
                  _c("v-tab", { key: "gate_management" }, [
                    _vm._v("Gate Management"),
                  ]),
                  _c("v-tab", { key: "drivers_chat" }, [
                    _vm._v("Drivers Chat"),
                  ]),
                  _c("v-tab", { key: "audit_log" }, [_vm._v("Audit Log")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.appointmentWithWarehouse?.id
            ? _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        { key: "details", attrs: { transition: false } },
                        [
                          _vm.tab === 0
                            ? _c(
                                "appointment-details-status",
                                _vm._b(
                                  {
                                    attrs: {
                                      appointment: _vm.appointmentWithWarehouse,
                                    },
                                  },
                                  "appointment-details-status",
                                  _vm.$props,
                                  false
                                )
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { key: "claims_support", attrs: { transition: false } },
                        [
                          _c("tab-content-centered", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "content",
                                  fn: function () {
                                    return [
                                      _vm.tab === 1
                                        ? _c(
                                            "appointment-details-claims",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  appointment:
                                                    _vm.appointmentWithWarehouse,
                                                },
                                              },
                                              "appointment-details-claims",
                                              _vm.$props,
                                              false
                                            )
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2487624745
                            ),
                          }),
                        ],
                        1
                      ),
                      _c("v-tab-item", {
                        key: "gate_management",
                        attrs: { transition: false },
                      }),
                      _c("v-tab-item", {
                        key: "drivers_chat",
                        attrs: { transition: false },
                      }),
                      _c(
                        "v-tab-item",
                        {
                          key: "audit_log",
                          attrs: { transition: false },
                          on: { click: _vm.trackApptDetailsMixpanelEvent },
                        },
                        [
                          _c("tab-content-centered", {
                            staticClass: "audit-log-tab-wrapper",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "content",
                                  fn: function () {
                                    return [
                                      _vm.tab === 4
                                        ? _c(
                                            "appointment-details-audit-log",
                                            _vm._b(
                                              {},
                                              "appointment-details-audit-log",
                                              _vm.$props,
                                              false
                                            )
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1028431629
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }