<template>
  <dialog-base
    v-if="appointment.warehouse?.id"
    :key="renderKey"
    v-bind="$props"
    header="Edit Booking Details"
    @close="close"
    content-class="details-dialog edit-custom-fields-dialog"
    transition="dialog-bottom-transition"
    loading-message="updating appointment"
    max-width="100%"
    :value="showDialog"
    width="1200px">
    <template #customHeader>
      <header class="details-header">
        <dialog-header header="Edit Booking Details" hide-close-icon>
          <template #prepend-button>
            <v-btn icon text class="mr-2" @click="close"><v-icon>mdi-arrow-left</v-icon></v-btn>
          </template>
        </dialog-header>
        <v-btn class="pa-3" icon light @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </header>
    </template>

    <template v-slot:activator="slotProps" v-if="!externalActivator"></template>
    <template v-slot:body>
      <details-content-box>
        <custom-fields
          hide-details
          :persistent-placeholder="false"
          outlined
          :display-label="false"
          v-model="customFieldsModel"
          :appointment="appointment"
          :warehouse="appointment.warehouse"
          :timezone="appointment.warehouse.timezone"></custom-fields>
      </details-content-box>
    </template>
    <template #dialog-actions>
      <div class="py-4 d-flex justify-end full-width">
        <outline-button class="mr-4" @click="close">Nevermind</outline-button>
        <primary-button @click="$emit('save', customFieldsModel)">Save Changes</primary-button>
      </div>
    </template>
  </dialog-base>
</template>

<script>
import { dialogBaseProps, useRenderKey, useDialogBase } from '@/composables';
import DialogHeader from '@satellite/components/elements/dialogs/DialogHeader.vue';
import DetailsContentBox from '@/modules/appointments/components/details/DetailsContentBox.vue';
import appointmentMixinBase from '@satellite/components/mixins/appointmentMixinBase';
import { ref } from 'vue';

export default {
  mixins: [appointmentMixinBase],
  components: { DetailsContentBox, DialogHeader },
  props: {
    ...dialogBaseProps,
    appointment: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  setup(props, context) {
    const customFieldsModel = ref(
      props.appointment.customfields ?? props.appointment.warehouse.customApptFieldsTemplate
    );

    const { renderKey } = useRenderKey();
    const onClose = () => {
      context.emit('close');
    };

    const onDismiss = () => context.emit('close');

    const { close } = useDialogBase({
      onClose,
      onDismiss
    });

    return {
      renderKey,
      close,
      customFieldsModel
    };
  }
};
</script>

<style lang="scss">
.details-dialog.edit-custom-fields-dialog.v-dialog {
  padding: 0 !important;
  align-self: flex-end;
  margin-bottom: 0;
  margin-top: 0;
  max-height: calc(100% - 48px) !important;
  height: calc(100% - 48px);

  .dialog-box {
    max-height: 100%;
  }

  .v-card {
    &__title {
      padding: 0 !important;
    }

    &__text {
      padding: 24px 0 !important;
      background-color: $color-background-transparency-inverted !important;

      > div {
        justify-content: center;
        display: flex;
      }
    }
  }

  .details-header {
    padding: 24px;
  }

  .details-container {
    margin-bottom: 0;
    width: 500px !important;
    max-width: 90%;
  }

  .custom-field-wrapper {
    .v-input--switch {
      margin-top: 0;
    }
  }

  .custom-field-wrapper:not(:first-child) {
    padding-top: 20px;
  }
}
</style>
