var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.appointmentWithWarehouse?.warehouse?.id
    ? _c("div", { staticClass: "full-height" }, [
        _c(
          "section",
          {
            staticClass: "sidebar-content",
            class: { "drivers-chat-tab": _vm.tab === "drivers_chat" },
          },
          [
            _c(
              "div",
              { staticClass: "sidebar" },
              [
                _c(
                  "v-tabs",
                  {
                    attrs: { vertical: "", centered: false, "hide-slider": "" },
                    on: { change: _vm.handleTabChange },
                    model: {
                      value: _vm.tab,
                      callback: function ($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab",
                    },
                  },
                  [
                    _c(
                      "v-tab",
                      { key: "details", attrs: { "tab-value": "details" } },
                      [_vm._v("Status & Details")]
                    ),
                    _c(
                      "v-tab",
                      {
                        attrs: {
                          "tab-value": "claims_support",
                          disabled: _vm.claimSupportTriggers.length === 0,
                        },
                      },
                      [_vm._v(" Claims Support ")]
                    ),
                    _c("v-tab", { attrs: { "tab-value": "gate_management" } }, [
                      _vm._v("Gate Management"),
                    ]),
                    _c(
                      "v-tab",
                      { attrs: { "tab-value": "drivers_chat" } },
                      [
                        _vm._v(" Drivers Chat "),
                        _vm.unreadMessageCount
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ml-1",
                                attrs: {
                                  "x-small": "",
                                  color: "primary",
                                  "data-testid":
                                    "drivers-chat-tab-message-count",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.unreadMessageCount < 100
                                        ? _vm.unreadMessageCount
                                        : "99+"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("v-tab", { attrs: { "tab-value": "audit_log" } }, [
                      _vm._v("Audit Log"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.appointmentWithWarehouse?.id
              ? _c(
                  "div",
                  {
                    staticClass: "content",
                    class: {
                      "drivers-chat-container": _vm.tab === "drivers_chat",
                    },
                  },
                  [
                    _c(
                      "v-tabs-items",
                      {
                        model: {
                          value: _vm.tab,
                          callback: function ($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab",
                        },
                      },
                      [
                        _c(
                          "v-tab-item",
                          { attrs: { value: "details", transition: false } },
                          [
                            _vm.tab === "details"
                              ? _c(
                                  "appointment-details-status",
                                  _vm._b(
                                    {
                                      attrs: {
                                        appointment:
                                          _vm.appointmentWithWarehouse,
                                      },
                                    },
                                    "appointment-details-status",
                                    _vm.$props,
                                    false
                                  )
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-tab-item",
                          {
                            attrs: {
                              value: "claims_support",
                              transition: false,
                            },
                          },
                          [
                            _c("tab-content-centered", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _vm.tab === "claims_support"
                                          ? _c(
                                              "appointment-details-claims",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    appointment:
                                                      _vm.appointmentWithWarehouse,
                                                  },
                                                },
                                                "appointment-details-claims",
                                                _vm.$props,
                                                false
                                              )
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2605926513
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-tab-item",
                          {
                            attrs: {
                              value: "gate_management",
                              transition: false,
                            },
                          },
                          [
                            _c("tab-content-centered", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _vm.tab === "gate_management"
                                          ? _c(
                                              "appointment-details-gate-management",
                                              {
                                                attrs: {
                                                  triggers:
                                                    _vm.gateManagementTriggers,
                                                  appointment:
                                                    _vm.appointmentWithWarehouse,
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1780200626
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-tab-item",
                          {
                            attrs: { value: "drivers_chat", transition: false },
                          },
                          [
                            _vm.tab === "drivers_chat"
                              ? _c("appointment-details-drivers-chat", {
                                  attrs: {
                                    "message-thread": _vm.messageThreadRef,
                                    warehouse:
                                      _vm.appointmentWithWarehouse.warehouse,
                                    "asset-visit": _vm.assetVisit,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-tab-item",
                          {
                            attrs: { value: "audit_log", transition: false },
                            on: { click: _vm.trackApptDetailsMixpanelEvent },
                          },
                          [
                            _c("tab-content-centered", {
                              staticClass: "audit-log-tab-wrapper",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _vm.tab === "audit_log"
                                          ? _c(
                                              "appointment-details-audit-log",
                                              _vm._b(
                                                {},
                                                "appointment-details-audit-log",
                                                _vm.$props,
                                                false
                                              )
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                4116707503
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }