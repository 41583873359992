<template>
  <div class="time-selector">
    <v-container class="date-header pb-0">
      <section class="d-flex align-center">
        <div class="d-flex flex-1 justify-space-between date-nav align-center">
          <v-btn text @click="previousDates">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <div class="d-flex align-center">
            <date-picker
              v-model="selectedDate"
              :icon-mode="true"
              :open-dates="openDates"
              @date-navigation="setOpenDates"></date-picker>
            <span class="caption">{{ dateTimeHeader }}</span>
          </div>
          <v-btn text @click="nextDates">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
        <dock-select
          hide-icon
          custom-persistent-label="Docks"
          :clearable="false"
          :multi-select="false"
          :docks="docks"
          v-if="isReschedule && allowDockSelect"
          class="flex-1"
          v-model="selectedDock"></dock-select>
      </section>

      <div class="d-flex date-labels mt-4">
        <div
          v-for="item in dateRange"
          :key="`slot-${item.date}-header`"
          class="text-center time-column">
          <h3>
            <span class="d-block">{{ item.day }}</span>
            {{ item.date }}
          </h3>
        </div>
      </div>
    </v-container>

    <div class="time-grid d-flex mb-4 container pt-0">
      <template v-if="!processing">
        <div
          v-for="item in dateRange"
          :key="`slot-${item.date}-times`"
          class="text-center time-column d-flex flex-column align-center">
          <template v-if="availability[item.key] && availability[item.key].length">
            <template v-for="(time, i) in availability[item.key]">
              <secondary-button
                v-if="value && JSON.stringify(time.start) === JSON.stringify(value.start)"
                :key="`${i}-time`"
                class="time-button"
                :data-date-time="
                  formatTime(
                    time.start.format(),
                    novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeNoSpace
                  )
                "
                @click="setTime(time)">
                {{ formatTime(time.start.format()) }}
              </secondary-button>
              <outline-button
                v-else
                :key="`${i}-time`"
                class="time-button"
                :data-date-time="
                  formatTime(
                    time.start.format(),
                    novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeNoSpace
                  )
                "
                :class="{ 'time-button-past': time.isPast }"
                @click="setTime(time)">
                {{ formatTime(time.start.format()) }}
              </outline-button>
            </template>
          </template>
          <template v-else>
            <div>No availability</div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="loader full-width pb-8">
          <v-progress-linear
            indeterminate
            :loading="processing"
            height="6"
            class="mt-12"></v-progress-linear>
          <h4 class="text-center mt-4">Loading Availability...</h4>
        </div>
      </template>
    </div>

    <load-type-re-select-dialog
      return-load-type
      v-if="appointment && selectedDock && allowDockSelect"
      @close="shouldShowLoadTypeReselectDialog = false"
      :original-event="appointment"
      :event="appointment"
      :event-warehouse="appointment.dock.warehouse"
      :event-dock="selectedDock"
      :original-event-dock="appointment.dock"
      :show-dialog="shouldShowLoadTypeReselectDialog"
      @update-selected-load-type="updateSelectedLoadType"></load-type-re-select-dialog>
  </div>
</template>

<script>
import { DateTimeFormats } from '@satellite/../nova/core';
import moment from 'moment-timezone';

/**
 * Date Time picker that uses availability from API based on existing appointment or create appointment form data
 * @displayName Date Time Picker
 */
export default {
  name: 'DateTimePicker',
  props: {
    selectedWarehouse: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    /**
     * The Selected Dock
     */
    selectedDocks: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    /**
     * The Selected Load Type
     */
    selectedLoadType: {
      type: Object,
      required: true
    },
    /**
     * The Selected Start Date
     */
    startDate: {
      type: String,
      required: false,
      default: moment().format(DateTimeFormats.DateDashed)
    },
    /**
     * @model
     */
    value: {
      type: Object,
      required: false,
      default: null
    },
    /**
     * Warehouse timezone
     */
    timezone: {
      type: String,
      required: true
    },
    existingStart: {
      type: String,
      required: false
    },
    existingEnd: {
      type: String,
      required: false
    },
    numDays: {
      type: Number,
      required: false,
      default: 3
    },
    appointment: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    mixin: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    isReschedule: {
      type: Boolean,
      required: false,
      default: false
    },
    allowDockSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    docks() {
      return [];
    },
    /**
     * Component should display loader
     * @returns {boolean}
     */
    loading() {
      return Boolean(this.$data.$globalLoading || this.processing);
    },
    dateTimeHeader() {
      let header = '';
      if (this.dateRange.length) {
        const headerFormat = DateTimeFormats.ShortDateFull;
        const start = this.novaCore.getFormattedTime(this.dateRange[0].date, headerFormat);
        const end = this.novaCore.getFormattedTime(
          this.dateRange[this.dateRange.length - 1].date,
          headerFormat
        );
        header = `${start} - ${end}`;
      }
      return header;
    }
  },
  data() {
    return {
      availability: {},
      dateRange: [],
      processing: true,
      startOfDateRange: null,
      selectedTime: null,
      openDates: null,
      selectedDate: '',
      selectedDock: null,
      shouldShowLoadTypeReselectDialog: false,
      newSelectedDock: null
    };
  },
  methods: {
    nevermindLoadTypeDialog() {
      this.shouldShowLoadTypeReselectDialog = false;
      this.selectedDock = this.appointment.dock;
      this.newSelectedDock = null;
    },
    formatTime(time, format) {
      const tz = null;
      return this.novaCore.formatDateTimeWithMilitarySupport(
        time,
        tz,
        format ?? this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(
          this.appointment?.id ? this.appointment.dock.warehouse : this.selectedWarehouse
        ),
        format ?? this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
    },
    initializeProcessAvailabilityVars() {
      this.processing = true;
      // this.availability = {};
      let i = 0;

      // Convert incoming availability data
      const avail = {};

      return {
        avail,
        i
      };
    },
    autoSelectStartTime() {
      if (!this.existingStart && this.value?.start) {
        this.selectedTime = this.isTimeAvailable() ? this.value : {};
      }
    },
    sortSlots() {
      Object.entries(this.availability).map(([key, value]) => {
        this.availability[key] = value.sort(
          (a, b) => a.start.clone().toDate() - b.start.clone().toDate()
        );
      });
    },
    /**
     * Parse availability from API into usable UI format
     * @public
     * @param {Object} availability
     */
    processAvailability(availability) {
      let { avail, i } = this.initializeProcessAvailabilityVars();
      const now = momentjs().tz(this.timezone);
      while (i < availability.length) {
        const item = availability[i];
        item.startTimes.forEach(startTime => {
          const startTimeDate = this.novaCore.renderUtcInTimezone(
            startTime,
            this.timezone,
            DateTimeFormats.MonthDayYear
          );

          const startMoment = this.novaCore.renderUtcInTimezoneMoment(startTime, this.timezone);

          const slotData = {
            start: startMoment,
            docks: [],
            isPast: startMoment < now
          };

          if (!this.novaCore.objPropExists(avail, startTimeDate)) {
            avail[startTimeDate] = [];
          }

          const existingAvailabilityItemIndex = avail[startTimeDate].findIndex(availItem =>
            availItem.start.isSame(slotData.start)
          );

          if (existingAvailabilityItemIndex < 0) {
            slotData.docks.push(item.dock.id);
            avail[startTimeDate].push(slotData);
          } else {
            avail[startTimeDate][existingAvailabilityItemIndex].docks.push(item.dock.id);
          }
        });
        i++;
      }

      this.availability = avail;

      this.autoSelectStartTime();
      this.sortSlots();

      this.processing = false;
    },
    mixpanelTrack() {},
    /**
     * Fetch new availability for previous dates
     * @public
     * @returns {Promise<void>}
     */
    async previousDates() {
      this.startOfDateRange = this.startOfDateRange.subtract(this.numDays, 'days');
      await this.getAvailability();
    },
    /**
     * Fetch new availability for next dates
     * @public
     * @returns {Promise<void>}
     */
    async nextDates() {
      this.startOfDateRange = this.startOfDateRange.add(this.numDays, 'days');
      await this.getAvailability();
    },
    /**
     * Fetch dock availability from API
     * @public
     * @returns {Promise<void>}
     */
    async getAvailability() {
      this.createDateRange();
      let params = {
        warehouseId: this.selectedWarehouse.id,
        includeStartTimes: true,
        start: this.startOfDateRange.clone().startOf('day').format(),
        end: this.startOfDateRange.clone().add(this.numDays, 'days').endOf('day').format()
      };
      if (this.appointment?.id) {
        params.excludeApptId = this.appointment.id;
      }
      const response = await this.$store.dispatch('LoadTypes/getAvailability', {
        id: this.selectedLoadType.id,
        params
      });
      if (response?.data?.data) {
        const selectedDockIds =
          this.selectedDocks?.length > 0
            ? this.selectedDocks.map(dock => dock.id)
            : this.selectedWarehouse.docks.map(dock => dock.id);

        // Filter down the results by the Dock(s) we'd like to show availability for
        const dockAvailabilities = response.data.data.filter(item => {
          return selectedDockIds.includes(item.dock.id);
        });

        this.processAvailability(dockAvailabilities);
      }
    },
    initializeSetOpenDatesVars() {
      // It is important to set it to null,
      // so we don't disable dates in case of failure
      this.openDates = null;
      const docks = this.selectedDocks;
      return { docks };
    },
    async setOpenDates(date) {
      const { docks } = this.initializeSetOpenDatesVars();
      if (date && docks?.length > 0) {
        const start = moment(new Date(date)).tz(this.timezone).startOf('month').toDate();
        const end = moment(new Date(date)).tz(this.timezone).endOf('month').toDate();
        const dockIds = this.selectedDocks.map(d => d.id);

        const response = await axios.post(`dock/compute-open-dates`, {
          start,
          end,
          dockIds
        });

        if (response?.data?.data) {
          this.openDates = response.data.data.openDates;
        }
      }
    },
    /**
     * Create a date range given {numDays} to use for fetching/displaying availability
     * @public
     */
    createDateRange() {
      let dateRange = [];
      let i = 0;
      while (i < this.numDays) {
        dateRange.push({
          day: this.startOfDateRange.clone().add(i, 'days').format(DateTimeFormats.ShortWeekday),
          date: this.startOfDateRange
            .clone()
            .add(i, 'days')
            .format(DateTimeFormats.MonthDayYearSlashed),
          key: this.startOfDateRange.clone().add(i, 'days').format(DateTimeFormats.MonthDayYear)
        });
        i++;
      }

      this.dateRange = dateRange;
    },
    /**
     * Set the selected time to the selectedTime data property
     * @public
     * @param time
     */
    setTime(time) {
      this.selectedTime = time;
    },
    isTimeAvailable() {
      const comparisonFormat = DateTimeFormats.Extended24HrTimeLeadingZeroHourSeconds;
      const selectedTime = this.value.start.clone().format(comparisonFormat);
      const checkDate = this.startOfDateRange.clone().format(DateTimeFormats.MonthDayYear);
      const dateAvailability =
        this.availability[checkDate]?.filter(slot => {
          return slot.start.clone().format(comparisonFormat) === selectedTime;
        }) || [];

      return Boolean(dateAvailability.length);
    },
    setStartOfDateRange(value) {
      this.startOfDateRange = moment.tz(value, this.timezone).startOf('day');
    },
    updateSelectedLoadType(newLoadType) {
      this.$emit('update-selected-dock', this.newSelectedDock);
      this.$emit('update-selected-loadtype', newLoadType);
    }
  },
  async mounted() {
    this.startOfDateRange = moment().tz(this.timezone, false).startOf('day');
    const format = DateTimeFormats.DateDashed;
    this.selectedDate = this.value?.start
      ? this.value.start.clone().tz(this.timezone).format(format)
      : this.startDate || moment().tz(this.timezone).format(format);
    this.setStartOfDateRange(this.selectedDate);
    if (this.allowDockSelect) {
      this.selectedDock = this.appointment.dock;
    }
  },
  watch: {
    async selectedDate(newDate) {
      this.setStartOfDateRange(newDate);
      this.$nextTick(async () => {
        await this.getAvailability();
      });
    },
    async selectedDocks() {
      this.$nextTick(async () => {
        await this.getAvailability();
      });
    },
    allowDockSelect() {
      if (this.allowDockSelect) {
        this.selectedDock = this.appointment.dock;
      }
    },
    selectedDock(selectedDock) {
      if (!selectedDock?.id) {
        return;
      }
      if (
        selectedDock?.loadTypeIds?.length > 0 &&
        !selectedDock.loadTypeIds.includes(this.appointment.loadTypeId)
      ) {
        this.shouldShowLoadTypeReselectDialog = true;
        this.newSelectedDock = selectedDock;
      } else {
        this.$emit('update-selected-dock', selectedDock);
        this.$emit('update-selected-loadtype', this.appointment.loadType);
      }
    },
    selectedTime(value) {
      /**
       * Emits input event with new data
       * @event input
       * @property {Object} input - new selected time object
       */
      this.mixpanelTrack();
      this.$emit('input', value);
    }
  }
};
</script>
