<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Reschedule Appointment"
    :loading="false"
    @close="close"
    @confirm="$refs.rescheduleAppointmentForm.submit()"
    width="900"
    :has-dialog-actions="false"
    max-width="100%">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button
        before-icon="calendar-edit"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        class="mr-2"
        >Reschedule Appointment</primary-button
      >
    </template>
    <template v-slot:body>
      <v-alert :type="alertType" class="mb-0" shaped v-if="alertMessage">{{
        alertMessage
      }}</v-alert>
      <reschedule-appointment-form
        @close="close"
        ref="rescheduleAppointmentForm"
        :appointment="appointment"></reschedule-appointment-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for the reschedule form
 * @displayName Reschedule Appointment Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * Alert message to display in dialog
     */
    alertMessage: {
      type: String,
      required: false
    },
    /**
     * Alert type
     * @values success | error
     */
    alertType: {
      type: String,
      required: false,
      default: 'success'
    },
    appointment: {
      type: Object,
      required: true
    },
    appointmentWarehouse: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  }
};
</script>
