import { differenceWith, isEqual } from 'lodash';
import config from '@/config/config';

export default {
  props: {
    /**
     * Warehouse Object
     */
    header: {
      type: String,
      required: false
    },
    warehouse: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      originalWarehouseData: null,
      editingWarehouse: {},
      warehouseSettings: {},
      notificationConfigKey: null,
      refNumberValidationVersionItems: [
        {
          text: 'Version 2',
          value: 'V2'
        },
        {
          text: 'Version 1 / Google Sheet',
          value: 'V1'
        }
      ],
      richTextInputReady: false
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form && !this.$refs.form.validate()) {
        return;
      }

      let settings = {};
      let data = { ...this.editingWarehouse, ...this.editingAddress };
      // Make sure this setting is sent as an integer
      data.intervalTrimForCarriers = Number(data.intervalTrimForCarriers);
      const updatedAttributes = this.getUpdatedAttributes(this.editingWarehouse, this.warehouse);

      // Remove settings that have no value
      if (this.editingWarehouse.settings) {
        settings = { ...this.editingWarehouse.settings };
        Object.entries(settings).map(([key, value]) => {
          if (!value && value !== 0 && value !== false) {
            delete settings[key];
          }

          if (key === 'muteAppointmentNotifications' && typeof settings[key] === 'boolean') {
            settings[key] = !settings[key];
          }
        });
      }

      try {
        const response = await this.$store.dispatch('Warehouses/updateWarehouse', {
          ...data,
          settings,
          updatedAttributes
        });
        if (response?.data?.data) {
          this.$eventHub.$emit('update-Warehouse', response.data.data);
        }
      } finally {
        this.$emit('close');
      }
    },
    updateDirtyStatus() {
      const editingData = { ...this.editingWarehouse, ...this.editingAddress };
      const originalData = this.originalWarehouseData;

      const diff = differenceWith([editingData], [originalData], isEqual);

      const hasChanged = diff.length > 0;
      this.setDirtyStatus(this.richTextInputReady && hasChanged);
    },
    /**
     * Set warehouse being edited
     */
    setEditingWarehouse() {
      this.editingWarehouse = {
        ...this.warehouse,
        notes: this.warehouse.notes || '',
        instructions: this.warehouse.instructions || '',
        refNumberValidationUrl: this.warehouse.refNumberValidationUrl || null,
        tags: this.warehouse.tags || [],
        ccEmails: this.warehouse.ccEmails || []
      };

      this.editingWarehouse.settings = this.warehouseSettings;

      this.editingAddress = {
        country: this.warehouse.country,
        state: this.warehouse.state,
        city: this.warehouse.city,
        zip: this.warehouse.zip,
        street: this.warehouse.street,
        timezone: this.warehouse.timezone
      };
      this.$nextTick(() => {
        this.originalWarehouseData = { ...this.editingWarehouse };
        this.updateDirtyStatus();
      });
    },
    handleRichTextReady() {
      this.setEditingWarehouse();
      this.richTextInputReady = true;
    },
    getUpdatedAttributes(curObj, baseObj) {
      let updatedAttributes = [];

      for (const attribute in curObj) {
        if (attribute === 'settings') {
          updatedAttributes = [
            ...updatedAttributes.concat(
              this.getUpdatedAttributes(curObj[attribute], baseObj[attribute])
            )
          ];
        } else {
          const fieldHasChanged = !baseObj
            ? true
            : attribute === 'muteAppointmentNotifications'
            ? this.$route.meta.isDirty &&
              JSON.stringify(curObj[attribute]) !== JSON.stringify(!baseObj[attribute])
            : attribute !== 'gateManagementAdditionalInformationText'
            ? JSON.stringify(curObj[attribute]) !== JSON.stringify(baseObj[attribute])
            : false;
          if (fieldHasChanged) {
            updatedAttributes.push(this.novaCore.breakWordsAtCaps(attribute).toLowerCase());
          }
        }
      }
      if (
        JSON.stringify(this.editingAddress) !==
        JSON.stringify({
          country: this.warehouse.country,
          state: this.warehouse.state,
          city: this.warehouse.city,
          zip: this.warehouse.zip,
          street: this.warehouse.street,
          timezone: this.warehouse.timezone
        })
      ) {
        updatedAttributes.push('address');
      }
      return [...new Set(updatedAttributes)];
    },
    async copyWarehouseLink() {
      await this.$copyContent(
        `${config.luna_url}/warehouses/${this.warehouse?.id}`,
        'Warehouse link copied to clipboard'
      );
    }
  },
  watch: {
    warehouse: {
      handler() {
        this.setEditingWarehouse();
      },
      deep: true
    },
    warehouseSettings: {
      handler() {
        this.editingWarehouse.settings = this.warehouseSettings;
      },
      deep: true
    },
    editingWarehouse: {
      handler() {
        // This is needed so that Neutron clears it out when the UI text field
        // is cleared.
        if (!this.editingWarehouse.refNumberValidationUrl) {
          this.editingWarehouse.refNumberValidationUrl = null;
        }
        this.updateDirtyStatus();
      },
      deep: true
    },
    editingAddress: {
      handler() {
        this.updateDirtyStatus();
      },
      deep: true
    },
    '$route.name': function () {
      this.setEditingWarehouse();
    }
  }
};
