<template>
  <v-dialog
    scrollable
    v-model="dialog"
    v-bind="[$attrs, $props]"
    persistent
    :max-width="maxWidth"
    :width="width"
    @keydown.escape="$emit('close')">
    <slot name="custom-overlay"></slot>

    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <!--
          @slot Custom activator for the dialog
          @binding {object} slotProps Object containing {on, attrs}
        -->
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button v-else v-on="slotProps.on" v-bind="slotProps.attrs">
        {{ activatorLabel }}
      </primary-button>
    </template>
    <v-card
      :data-testid="testId"
      :tile="tile"
      class="dialog-box"
      :class="[
        hasDialogActions ? 'has-dialog-actions' : '',
        { 'pa-0': noPadding },
        ...cardClasses
      ]">
      <v-card-title class="pa-0" v-if="$slots.customHeader">
        <slot name="customHeader"></slot>
      </v-card-title>
      <dialog-header
        v-else
        :header="header"
        @close="close"
        :prepend-header-icon="prependHeaderIcon"
        :hideCloseIcon="hideCloseIcon">
        <template v-slot:header-actions>
          <slot name="header-actions"></slot>
        </template>
      </dialog-header>

      <!-- @slot Dialog body content -->
      <v-card-text :class="{ 'px-8 pb-6 pt-2': !noPadding, 'pa-0': noPadding }">
        <template v-if="dialogLoading">
          <v-progress-linear indeterminate height="6" class="mt-12"></v-progress-linear>
          <h4 class="text-center mt-4">{{ loadingMessage }}</h4>
        </template>
        <!-- MUST REMAIN V-SHOW or it will cause components in the body that fetch data to mount in an infinite loop -->
        <div v-show="!dialogLoading">
          <slot name="body"></slot>
        </div>
      </v-card-text>
      <!-- @slot Footer actions -->
      <v-card-actions v-if="$slots['dialog-actions']" class="card-actions">
        <slot name="dialog-actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import buttonMixin from '../../mixins/buttonMixin';
import DialogHeader from './DialogHeader';

/**
 * Base dialog skeleton used for quick builds of new dialogs
 * @displayName Dialog Base
 */
export default {
  components: { DialogHeader },
  mixins: [buttonMixin],
  props: {
    /**
     * Activator button label
     */
    activatorLabel: {
      type: String,
      required: false
    },
    /**
     * Dialog header
     */
    header: {
      type: String,
      required: true
    },
    /**
     * Show loader
     */
    loading: {
      type: Boolean,
      required: false,
      default: null
    },
    /**
     * Loading message for dialogs containing ajax logic
     */
    loadingMessage: {
      type: String,
      required: false,
      default: 'Loading...'
    },
    /**
     * Show the dialog
     */
    showDialog: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Indicates if external activator should be used
     */
    externalActivator: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Max width of dialog window
     */
    maxWidth: {
      type: String,
      required: false,
      default: '600'
    },
    /**
     * Width of dialog window
     */
    width: {
      type: String,
      required: false,
      default: '600'
    },
    /**
     * Callback that runs when dialog is closed
     */
    closeCallback: {
      type: Function,
      required: false,
      default: () => {}
    },
    hasDialogActions: {
      type: Boolean,
      required: false,
      default: false
    },
    prependHeaderIcon: {
      type: String,
      required: false,
      default: ''
    },
    hideCloseIcon: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    cardClasses: {
      type: Array,
      default() {
        return [];
      }
    },
    tile: {
      type: Boolean,
      default: false
    },
    testId: {
      type: String,
      required: false
    }
  },
  computed: {
    dialogLoading() {
      return this.loading !== null ? this.loading : this.$root.$data.$globalLoading;
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    /**
     * Close dialog and run provided callback
     * @public
     */
    close() {
      /**
       * Emits close event
       * @event close
       */
      this.$emit('close');
      this.dialog = false;

      this.closeCallback();
    },
    /**
     * Check if dialog is not on loading state and emits the confirm event
     * @public
     */
    confirm() {
      /**
       * Emits confirm event
       * @event confirm
       */
      if (!this.dialogLoading) {
        this.$emit('confirm');
      }
    }
  },
  mounted() {
    this.dialog = this.showDialog;
  },
  watch: {
    showDialog(showDialog) {
      this.dialog = showDialog;
    },
    dialog() {
      if (this.dialog) {
        this.$emit('open');
      }
    }
  }
};
</script>
