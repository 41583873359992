var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "appointment-details" },
        [
          _c("status-manager-old", {
            attrs: {
              "read-only": _vm.readOnly,
              appointment: _vm.appointment,
              "warehouse-triggers": _vm.warehouseTriggers,
              "should-edit-status": _vm.shouldEditStatus,
            },
          }),
          _c(
            "div",
            { staticClass: "mx-2 mb-8" },
            [
              _c(
                "v-row",
                [
                  _c("v-icon", { staticClass: "text-lg-h5 mr-2" }, [
                    _vm._v("mdi-calendar"),
                  ]),
                  _vm.canReschedule
                    ? _c("v-btn", {
                        staticClass:
                          "text-lg-h6 pa-0 black--text reschedule-date-button",
                        attrs: { text: "" },
                        domProps: {
                          innerHTML: _vm._s(_vm.appointmentDateTimeHeader),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showRescheduleDialog = true
                          },
                        },
                      })
                    : [
                        _c("span", {
                          staticClass: "text-lg-h6 black--text",
                          domProps: {
                            innerHTML: _vm._s(_vm.appointmentDateTimeHeader),
                          },
                        }),
                      ],
                  _vm.reschedulesCount > 0
                    ? [
                        _c("span", { staticClass: "reschedules-count" }, [
                          _vm._v(_vm._s(_vm.reschedulesString)),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm.recurringPattern.weekDays
                ? _c("v-row", [
                    _c("div", { staticClass: "font-size-x-small" }, [
                      _vm._v(" Recurs every "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.recurringPattern.weekDays) +
                            " @ " +
                            _vm._s(_vm.recurringPattern.endTime)
                        ),
                      ]),
                      _vm._v(" until "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.recurringPattern.endDate)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          !_vm.loading
            ? _c("v-simple-table", {
                staticClass: "appointment-details-table",
                attrs: { dense: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead"),
                          _c(
                            "tbody",
                            [
                              _vm._l(
                                _vm.appointmentTableRows,
                                function (row, index) {
                                  return [
                                    !row.isHidden
                                      ? [
                                          _c(
                                            "tr",
                                            { key: index + "-detail" },
                                            [
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                    class: row.tdClass,
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(row.label) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { class: row.tdClass },
                                                  [
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          row.value
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                            ],
                                            2
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                }
                              ),
                              _vm._l(
                                Object.values(_vm.customFields),
                                function (customField) {
                                  return _c("tr", { key: customField.key }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "font-weight-bold",
                                        class: customField.tdClass,
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(customField.label) + " "
                                        ),
                                      ]
                                    ),
                                    _c("td", { class: customField.tdClass }, [
                                      _c("div", {
                                        staticClass: "text-pre-wrap",
                                        domProps: {
                                          innerHTML: _vm._s(customField.value),
                                        },
                                      }),
                                    ]),
                                  ])
                                }
                              ),
                              _c("tr", [
                                _c("td", { staticClass: "font-weight-bold" }, [
                                  _c(
                                    "div",
                                    [
                                      _vm._v(" Appointment Notes "),
                                      _c("help-icon-tooltip", [
                                        _vm._v(
                                          " Notify the Carrier about any appointment observations "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("td", [
                                  _c("div", {
                                    staticClass: "appointment-notes",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.appointment.notes || "----"
                                      ),
                                    },
                                  }),
                                ]),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "font-weight-bold pt-4" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm._v(" Confirmation # "),
                                        _c("help-icon-tooltip", [
                                          _vm._v(
                                            "Unique Appointment Confirmation Number"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("td", { staticClass: "pt-4" }, [
                                  _c(
                                    "div",
                                    [
                                      _c("span", { staticClass: "mr-4" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.appointment.confirmationNumber
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "secondary-button",
                                        {
                                          staticClass: "ml-4",
                                          attrs: { "x-small": "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyValueToClipboard(
                                                _vm.appointment
                                                  .confirmationNumber
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Copy ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "font-weight-bold pt-4" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm._v(" Appointment ID "),
                                        _c("help-icon-tooltip", [
                                          _vm._v(
                                            "Unique Appointment Reference"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("td", { staticClass: "pt-4" }, [
                                  _c(
                                    "div",
                                    [
                                      _c("span", { staticClass: "mr-4" }, [
                                        _vm._v(_vm._s(_vm.appointment.id)),
                                      ]),
                                      _c(
                                        "secondary-button",
                                        {
                                          staticClass: "ml-4",
                                          attrs: {
                                            "x-small": "",
                                            "data-testid":
                                              "appointment-details-copy-appointment-id",
                                          },
                                          on: { click: _vm.copyApptId },
                                        },
                                        [_vm._v(" Copy ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c("tr", { staticClass: "divider" }, [
                                _c("td"),
                                _c("td"),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "pt-5 font-weight-bold" },
                                  [_vm._v("Asset details")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "pt-5",
                                    attrs: {
                                      "data-testid": "asset-event-status",
                                    },
                                  },
                                  [
                                    !_vm.appointment.assetVisit?.id
                                      ? _c("span", [_vm._v("No asset linked")])
                                      : _c(
                                          "v-expansion-panels",
                                          { attrs: { accordion: "" } },
                                          [
                                            _c(
                                              "v-expansion-panel",
                                              [
                                                _c("v-expansion-panel-header", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center font-weight-bold",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "mr-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.lastEventType ??
                                                                "Not Arrived"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "v-expansion-panel-content",
                                                  [
                                                    _vm.appointment.assetVisit
                                                      ? _c(
                                                          "asset-visit-form-data",
                                                          {
                                                            attrs: {
                                                              company:
                                                                _vm.appointment
                                                                  ?.user
                                                                  ?.company,
                                                              "asset-visit":
                                                                _vm.appointment
                                                                  .assetVisit,
                                                              "is-list": "",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", { staticClass: "divider" }, [
                                _c("td"),
                                _c("td"),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "font-weight-bold pt-5" },
                                  [_vm._v("Carrier Contact")]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "pt-5" },
                                  [
                                    _c(
                                      "v-expansion-panels",
                                      { attrs: { accordion: "" } },
                                      [
                                        _c(
                                          "v-expansion-panel",
                                          [
                                            _c("v-expansion-panel-header", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center font-weight-bold",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.companyName)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mr-2 font-weight-light",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "- " +
                                                          _vm._s(
                                                            _vm.appointment.user
                                                              .email
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("favorite-button", {
                                                    attrs: {
                                                      carrier:
                                                        _vm.appointment.user,
                                                      org: _vm.$org,
                                                    },
                                                    on: {
                                                      "toggle-favorite":
                                                        _vm.toggleFavoriteCarrier,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _c("v-expansion-panel-content", [
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [
                                                  _vm._v(
                                                    "Name: " +
                                                      _vm._s(
                                                        _vm.getUserName(
                                                          _vm.appointment.user
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [
                                                  _vm._v(" Email: "),
                                                  _c(
                                                    "copy-content",
                                                    {
                                                      attrs: {
                                                        content:
                                                          _vm.appointment.user
                                                            .email,
                                                        label:
                                                          "Click to copy carrier email",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.appointment.user
                                                              .email
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [
                                                  _vm._v(
                                                    "Phone: " +
                                                      _vm._s(
                                                        _vm.appointment.user
                                                          .phone || "----"
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [
                                                  _vm._v(
                                                    "SCAC: " + _vm._s(_vm.scac)
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", { staticClass: "divider" }, [
                                _c("td"),
                                _c("td"),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "pt-5 font-weight-bold" },
                                  [_vm._v("Warehouse")]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "pt-5" },
                                  [
                                    _c(
                                      "v-expansion-panels",
                                      { attrs: { accordion: "" } },
                                      [
                                        _c(
                                          "v-expansion-panel",
                                          [
                                            _c("v-expansion-panel-header", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.appointment.dock
                                                          .warehouse.name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("v-expansion-panel-content", [
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [
                                                  _vm._v(" Address: "),
                                                  _c(
                                                    "copy-content",
                                                    {
                                                      attrs: {
                                                        content:
                                                          _vm.getWarehouseAddress(
                                                            _vm.appointment.dock
                                                              .warehouse
                                                          ),
                                                        label:
                                                          "Click to copy warehouse address",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getWarehouseAddress(
                                                              _vm.appointment
                                                                .dock.warehouse
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [
                                                  _vm._v(
                                                    "Email: " +
                                                      _vm._s(
                                                        _vm.appointment.dock
                                                          .warehouse.email
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [
                                                  _vm._v(
                                                    "Phone: " +
                                                      _vm._s(
                                                        _vm.appointment.dock
                                                          .warehouse.phone
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", { staticClass: "divider" }, [
                                _c("td", { attrs: { colspan: "2" } }),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "pt-5 font-weight-bold" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm._v(" Appointment Tags "),
                                        _c("help-icon-tooltip", [
                                          _vm._v("Tags for internal Usage"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "pt-5" },
                                  [
                                    _c("tag-manager", {
                                      attrs: {
                                        outlined: "",
                                        "outlined-chips": true,
                                        "should-enable-custom-tags": true,
                                        "small-chips": "",
                                        small: "",
                                        "read-only":
                                          !_vm.$rolePermissions
                                            .canUpdateAppointmentTags ||
                                          _vm.readOnly,
                                        placeholder: "Add tags",
                                        allowTagDelete: !_vm.lockTagEditing,
                                      },
                                      model: {
                                        value: _vm.tags,
                                        callback: function ($$v) {
                                          _vm.tags = $$v
                                        },
                                        expression: "tags",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", { staticClass: "divider" }, [
                                _c("td", { attrs: { colspan: "2" } }),
                              ]),
                              _vm._l(
                                _vm.gateManagementTriggers,
                                function (trigger) {
                                  return _c(
                                    "tr",
                                    { key: trigger.id, staticClass: "divider" },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "font-weight-bold pt-5",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                trigger.flow?.name ||
                                                  _vm.novaCore
                                                    .CustomFormsFeaturesData[
                                                    trigger.feature
                                                  ].title
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "py-5" },
                                        [
                                          _c("custom-forms-data", {
                                            key: trigger.id,
                                            attrs: {
                                              trigger: trigger,
                                              "object-id": _vm.appointment.id,
                                              timezone: _vm.warehouse.timezone,
                                              "military-time-enabled":
                                                _vm.$isMilitaryTimeEnabled(
                                                  _vm.warehouse
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _c("tr", [_c("td")]),
                            ],
                            2
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2572114807
                ),
              })
            : _vm._e(),
          (_vm.canCancel || _vm.isCancelled) && _vm.shouldDisplayCancelButton
            ? [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center my-8" },
                  [
                    !_vm.isCancelled
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "text__error font-weight-bold",
                            attrs: {
                              elevation: "0",
                              color: "white",
                              disabled:
                                _vm.appointment.status ===
                                _vm.novaCore.AppointmentStatus.Cancelled,
                            },
                            on: { click: _vm.confirmCancel },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v(_vm._s(_vm.cancelButtonIcon)),
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.cancelAppointmentLabel) + " "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          !_vm.appointment && !_vm.loading
            ? _c(
                "v-alert",
                { staticClass: "white--text", attrs: { color: "error" } },
                [_vm._v(" Could not load appointment data ")]
              )
            : _vm._e(),
          _vm.loading
            ? [
                _c(
                  "v-card-text",
                  [
                    _vm._v(" Loading Appointment Data... "),
                    _c("v-progress-linear", {
                      attrs: {
                        color: "secondary",
                        indeterminate: "",
                        rounded: "",
                        height: "8",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c("reschedule-appointment-dialog", {
            attrs: {
              "appointment-warehouse": _vm.warehouse,
              "external-activator": true,
              "show-dialog": _vm.showRescheduleDialog,
              appointment: _vm.appointment,
            },
            on: {
              close: function ($event) {
                _vm.showRescheduleDialog = false
              },
            },
          }),
          _c("cancel-appointment-dialog", {
            ref: "cancelAppointment",
            attrs: {
              "external-activator": true,
              "show-dialog": _vm.showConfirmDialog,
              appointment: _vm.appointment,
            },
            on: {
              confirm: function ($event) {
                return _vm.$emit("confirm")
              },
              close: function ($event) {
                _vm.showConfirmDialog = false
              },
            },
          }),
        ],
        2
      ),
      _c("custom-forms-flow-dialog-old", {
        attrs: {
          "external-activator": true,
          warehouse: _vm.warehouse,
          "show-dialog": _vm.hasActiveTrigger,
          "object-id": _vm.appointment.id,
          trigger: _vm.activeTrigger,
        },
        on: {
          close: _vm.cleanUpCustomFormsData,
          update: _vm.updateCustomFormsData,
          create: (value) =>
            _vm.createBulkCustomFormsData(value, _vm.warehouse.id),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }