var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "px-2 py-1" }, [
    _c("div", { staticClass: "header mb-2" }, [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("v-icon", { staticClass: "mr-2 white--text" }, [
            _vm._v("mdi-truck"),
          ]),
          _c("strong", [_vm._v(_vm._s(_vm.companyName))]),
          _vm.event.isRecurringAppointment
            ? [
                _c("v-spacer"),
                _c(
                  "v-icon",
                  { staticClass: "mr-1", attrs: { small: "", color: "white" } },
                  [_vm._v("mdi-repeat")]
                ),
                _c("span", { staticClass: "font-size-x-small uppercase" }, [
                  _vm._v("Recurring"),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("span", { staticClass: "date-label" }, [_vm._v(_vm._s(_vm.date))]),
    ]),
    _c("table", { staticClass: "event-tooltip-table" }, [
      _c("thead"),
      _c(
        "tbody",
        [
          _c("tr", [
            _c("td", { staticClass: "pr-4" }, [_vm._v("Confirmation #:")]),
            _c("td", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.event.confirmationNumber)),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "pr-4" }, [_vm._v("Status:")]),
            _c(
              "td",
              [
                _c(
                  "v-chip",
                  {
                    class: _vm.statusStyles.textColor,
                    attrs: {
                      label: "",
                      "x-small": "",
                      color: _vm.statusStyles.bgColor,
                    },
                  },
                  [
                    _vm.novaCore.isRequested(_vm.event.status)
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "requested-icon",
                            attrs: {
                              "x-small": "",
                              color: "requested darken-3",
                            },
                          },
                          [_vm._v("mdi-clock-alert")]
                        )
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.novaCore.breakWordsAtCaps(_vm.event.status)
                        ) +
                        " "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c("td", { staticClass: "pr-4" }, [_vm._v("Tags:")]),
            _c("td", { staticClass: "font-weight-bold" }, [
              _vm.tags && _vm.tags.length
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.tags, function (tag) {
                        return [
                          tag.color
                            ? _c(
                                "v-chip",
                                {
                                  key: tag.name,
                                  staticClass: "px-2 font-weight-bold mr-1",
                                  attrs: {
                                    "x-small": "",
                                    color: tag.color,
                                    "text-color": tag.textColor,
                                  },
                                },
                                [_vm._v(" " + _vm._s(tag.name) + " ")]
                              )
                            : _c(
                                "v-chip",
                                {
                                  key: tag.name,
                                  staticClass: "px-2 mb-1 generic-tag",
                                  class: { "dark-bg": _vm.getGenericTagClass },
                                  attrs: { "x-small": "", outlined: "" },
                                },
                                [_vm._v(" " + _vm._s(tag.name) + " ")]
                              ),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("span", [_vm._v("----")]),
            ]),
          ]),
          _vm.event.eta
            ? _c("tr", [
                _c("td", { staticClass: "pr-4" }, [_vm._v("ETA:")]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.novaCore.renderUtcInTimezone(
                          _vm.event.eta,
                          _vm.event.dock.warehouse.timezone
                        )
                      ) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.$getSettingValue(
            "referenceNumberIsVisible",
            _vm.event.dock.warehouse.settings
          )
            ? _c("tr", [
                _c("td", { staticClass: "pr-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$getSettingValue(
                          "referenceNumberDisplayName",
                          _vm.event.dock.warehouse.settings
                        )
                      ) +
                      ": "
                  ),
                ]),
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.event.refNumber)),
                ]),
              ])
            : _vm._e(),
          _c("tr", [
            _c("td", { staticClass: "pr-4" }, [_vm._v("Dock:")]),
            _c("td", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.event.readableDockName)),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "pr-4" }, [_vm._v("Load Type:")]),
            _c("td", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.event.loadType.name) +
                  " - " +
                  _vm._s(_vm.event.loadType.direction) +
                  " "
              ),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "pr-4" }, [_vm._v("Duration:")]),
            _c("td", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.getEventDurationString(_vm.event))),
            ]),
          ]),
          _vm._l(_vm.limitedCustomFields, function (field, index) {
            return _c("tr", { key: `custom-field-popup-${index}` }, [
              _c("td", { staticClass: "pr-4" }, [
                _vm._v(_vm._s(field.label) + ":"),
              ]),
              _c("td", { staticClass: "font-weight-bold" }, [
                _c("span", {
                  staticClass: "text-pre-wrap",
                  domProps: { innerHTML: _vm._s(_vm.getFieldValue(field)) },
                }),
              ]),
            ])
          }),
          _vm.customFieldOverflowCount
            ? _c("tr", [
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v("+ " + _vm._s(_vm.customFieldOverflowCount) + " more"),
                ]),
                _c("td"),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }