<template>
  <div>
    <span class="sub-title d-flex align-center justify-center font-weight-bold">
      {{ header }}
    </span>
    <div class="d-flex flex-column align-center justify-center flex-wrap">
      <span class="text-h4 text-center py-2 pt-4 font-weight-bold secondary--text"
        >Check your email account</span
      >
      <p class="pt-4 text-center">
        <span class="font-weight-bold d-block" v-if="!login"
          >You should receive an email from us soon.</span
        >
        Please click the link we sent you to complete the creation of your Opendock account.
      </p>

      <primary-button
        block
        x-large
        class="mt-6 mb-10"
        v-if="allowVerificationResend"
        @click="sendVerificationEmail"
        :loading="loading"
        >Resend Email</primary-button
      >

      <p class="font-size-small text-center">
        <span class="font-weight-bold d-block">Didn't get an email?</span>
        Check your spam folder or contact us:
        <a href="mailto:support@opendock.com">support@opendock.com</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Whether or not to show the "resend verification" action
     */
    allowVerificationResend: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * The address where the verification email was sent
     */
    email: {
      type: String,
      required: true
    },
    header: {
      type: String,
      required: false,
      default: 'Thanks for Registering'
    },
    login: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async sendVerificationEmail() {
      this.loading = true;
      const response = await this.$store
        .dispatch('Auth/sendVerificationEmail', this.email)
        .finally(() => {
          this.loading = false;
        });

      if (response?.status === 200) {
        this.notify(`Verification email sent to ${this.email}!`);
        this.$emit('close');
      }
    }
  }
};
</script>
