var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.org && _vm.org.settings
    ? _c(
        "v-form",
        {
          ref: "form",
          class: { disabled: _vm.readOnly, "px-5": _vm.readOnly },
          attrs: { id: "org-settings-form" },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "10", md: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "mb-10" },
                    [
                      _c("h3", [_vm._v("Overview")]),
                      _c("p", [
                        _c("small", [
                          _vm._v(
                            "Details and identification of your Organization."
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "v-item-group theme--light v-expansion-panels",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "v-expansion-panel",
                              class: { disabled: _vm.readOnly },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "v-expansion-panel-header pa-2",
                                },
                                [
                                  _c("strong", { staticClass: "col-2" }, [
                                    _vm._v("Org Name:"),
                                  ]),
                                  _vm._v(_vm._s(_vm.org.name) + " "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "v-expansion-panel-header__icon",
                                    },
                                    [
                                      !_vm.readOnly
                                        ? _c("audit-log-entity-icon-button", {
                                            attrs: {
                                              item: _vm.org,
                                              timezone: "UTC",
                                              "entity-type":
                                                _vm.novaCore.DatabaseEntities
                                                  .Org,
                                            },
                                          })
                                        : _vm._e(),
                                      !_vm.readOnly
                                        ? _c("icon-tooltip-button", {
                                            attrs: {
                                              tooltip: "Edit Org Name",
                                              size: "large",
                                              disabled: _vm.readOnly,
                                              icon: "mdi-pencil",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.showOrgDialog = true
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "v-expansion-panel",
                              class: { disabled: _vm.readOnly },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "v-expansion-panel-header pa-2",
                                },
                                [
                                  _c(
                                    "strong",
                                    { staticClass: "col-2" },
                                    [
                                      _vm._v(" Org ID "),
                                      _c("help-icon-tooltip", [
                                        _vm._v(" This Org's Unique ID"),
                                      ]),
                                      _vm._v(" : "),
                                    ],
                                    1
                                  ),
                                  _vm._v(_vm._s(_vm.org.id) + " "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "v-expansion-panel-header__icon",
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            size: "large",
                                            color: "secondary",
                                          },
                                          on: { click: _vm.copyOrgId },
                                        },
                                        [_vm._v("mdi-content-copy")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("edit-org-dialog", {
                        attrs: {
                          "external-activator": "",
                          "show-dialog": _vm.showOrgDialog,
                        },
                        on: { close: _vm.updateOrgName },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-10" },
                    [
                      _c("h3", [_vm._v("Settings")]),
                      _c("p", [
                        _c("small", [
                          _vm._v(
                            "Changes will be applied to all Warehouses across your Organization."
                          ),
                        ]),
                      ]),
                      _c("settings-expansion-panels", {
                        attrs: {
                          "read-only": _vm.readOnly,
                          "entity-key": _vm.novaCore.DatabaseEntities.Org,
                          "entity-settings": this.org.settings,
                        },
                        on: { "update-settings": _vm.updateSettings },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepended-panel",
                              fn: function () {
                                return [
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          class: { disabled: _vm.readOnly },
                                          attrs: { ripple: "" },
                                        },
                                        [_vm._v(" Appointment Tags")]
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-expansion-panel-content",
                                        { class: { disabled: _vm.readOnly } },
                                        [
                                          _c("custom-tags-form", {
                                            attrs: {
                                              "read-only": _vm.readOnly,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1986047758
                        ),
                      }),
                    ],
                    1
                  ),
                  _c("action-group", {
                    staticClass: "ml-2 mb-6",
                    attrs: {
                      "confirm-icon": "upload",
                      "confirm-label": "Save Org Settings",
                      "disable-confirm": _vm.$isOrgReadOnly,
                      "confirm-button-tooltip": _vm.$isOrgReadOnly
                        ? _vm.novaCore.getRoleActionError()
                        : "",
                      "hide-cancel": true,
                    },
                    on: { confirm: _vm.submit },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }