<template>
  <div>
    <document-upload-button
      :required="field.required"
      v-model="value"
      :rules="validationRules"
      @uploading="$emit('toggle-confirm-button', true)"
      @uploaded="$emit('toggle-confirm-button', false)">
    </document-upload-button>
    <span>
      Maximum <strong>50MB</strong> in size. Only <strong>ONE image</strong>, <strong>text</strong>,
      <strong>zip</strong> or <strong>spreadsheet</strong> allowed. <br />To upload multiple docs,
      zip first then upload.
    </span>
  </div>
</template>

<script>
import customFieldTypeMixin from '@satellite/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-document',
  mixins: [customFieldTypeMixin]
};
</script>
