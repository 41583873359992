<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Edit Subscribers"
    loading-message="Sharing Appointment..."
    @close="close"
    @confirm="$refs.shareAppointmentForm.submit()"
    width="800px"
    max-width="90%">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button
        before-icon="account-multiple"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        class="mr-2"
        >Edit Subscribers</primary-button
      >
    </template>
    <template slot="body">
      <v-alert :type="alertType" class="mb-0" shaped v-if="alertMessage">{{
        alertMessage
      }}</v-alert>
      <share-appointment-form
        @close="close"
        :appointment="appointment"
        ref="shareAppointmentForm"></share-appointment-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for the share appointment form
 * @displayName Share Appointment Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * Alert message to display in dialog
     */
    alertMessage: {
      type: String,
      required: false
    },
    /**
     * Alert type
     * @values success | error
     */
    alertType: {
      type: String,
      required: false,
      default: 'success'
    },
    appointment: {
      type: Object,
      required: true
    }
  }
};
</script>
