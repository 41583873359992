var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "time-selector" },
    [
      _c("div", { staticClass: "date-header" }, [
        _c(
          "section",
          {
            staticClass: "d-flex align-center justify-center action-container",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-space-between date-nav align-center",
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "nav-btn",
                    attrs: { text: "" },
                    on: { click: _vm.previousDates },
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("mdi-chevron-left"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("date-picker", {
                      attrs: { "icon-mode": "", "open-dates": _vm.openDates },
                      on: { "date-navigation": _vm.setOpenDates },
                      scopedSlots: _vm._u([
                        {
                          key: "icon-mode-text",
                          fn: function () {
                            return undefined
                          },
                          proxy: true,
                        },
                        {
                          key: "icon-trigger",
                          fn: function ({ openDatePicker }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "trigger-btn",
                                      attrs: { text: "" },
                                      on: { click: openDatePicker },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("mdi-calendar")]
                                      ),
                                      _c("span", { staticClass: "caption" }, [
                                        _vm._v(_vm._s(_vm.dateTimeHeader)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selectedDate,
                        callback: function ($$v) {
                          _vm.selectedDate = $$v
                        },
                        expression: "selectedDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "nav-btn",
                    attrs: { text: "" },
                    on: { click: _vm.nextDates },
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("mdi-chevron-right"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.isReschedule && _vm.allowDockSelect
              ? _c("dock-select", {
                  staticClass: "dock-select",
                  attrs: {
                    outlined: "",
                    "display-checkboxes": false,
                    label: "Dock",
                    "hide-icon": "",
                    clearable: false,
                    "multi-select": false,
                    docks: _vm.docks,
                    "data-testid": "reschedule-dialog-dock-select",
                  },
                  model: {
                    value: _vm.selectedDock,
                    callback: function ($$v) {
                      _vm.selectedDock = $$v
                    },
                    expression: "selectedDock",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex date-labels mt-4" },
          _vm._l(_vm.dateRange, function (item) {
            return _c(
              "div",
              {
                key: `slot-${item.date}-header`,
                staticClass: "text-center time-column",
              },
              [
                _c("h3", [
                  _c("span", { staticClass: "d-block" }, [
                    _vm._v(_vm._s(item.day)),
                  ]),
                  _vm._v(" " + _vm._s(item.date) + " "),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "time-grid d-flex mb-4 container" },
        [
          !_vm.processing
            ? _vm._l(_vm.dateRange, function (item) {
                return _c(
                  "div",
                  {
                    key: `slot-${item.date}-times`,
                    staticClass:
                      "text-center time-column d-flex flex-column align-center",
                  },
                  [
                    _vm.availability[item.key]?.length > 0
                      ? [
                          _vm._l(
                            _vm.availability[item.key],
                            function (slot, i) {
                              return [
                                _vm.isEqual(_vm.value?.start, slot.start)
                                  ? _c(
                                      "secondary-button",
                                      {
                                        key: `${i}-time`,
                                        staticClass: "time-button",
                                        attrs: {
                                          height: "29",
                                          "data-date-time": _vm.formatTime(
                                            slot.start.toISO(),
                                            _vm.novaCore.LuxonDateTimeFormats
                                              .MonthDayYearSlashedTimeNoSpace
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setSlot(slot)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatTime(slot.start.toISO())
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "outline-button",
                                      {
                                        key: `${i}-time`,
                                        staticClass: "time-button",
                                        class: {
                                          "time-button-past": slot.isPast,
                                        },
                                        attrs: {
                                          height: "29",
                                          "data-date-time": _vm.formatTime(
                                            slot.start.toISO(),
                                            _vm.novaCore.LuxonDateTimeFormats
                                              .MonthDayYearSlashedTimeNoSpace
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setSlot(slot)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatTime(slot.start.toISO())
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ]
                            }
                          ),
                        ]
                      : [_c("div", [_vm._v("No availability")])],
                  ],
                  2
                )
              })
            : [
                _c(
                  "div",
                  { staticClass: "loader full-width pb-8" },
                  [
                    _c("v-progress-linear", {
                      staticClass: "mt-12",
                      attrs: {
                        indeterminate: "",
                        loading: _vm.processing,
                        height: "6",
                      },
                    }),
                    _c("h4", { staticClass: "text-center mt-4" }, [
                      _vm._v("Loading Availability..."),
                    ]),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.appointment && _vm.selectedDock && _vm.allowDockSelect
        ? _c("load-type-re-select-dialog", {
            attrs: {
              "return-load-type": "",
              "original-event": _vm.appointment,
              event: _vm.appointment,
              "event-warehouse": _vm.appointment.dock.warehouse,
              "event-dock": _vm.selectedDock,
              "original-event-dock": _vm.appointment.dock,
              "show-dialog": _vm.shouldShowLoadTypeReselectDialog,
            },
            on: {
              close: _vm.handleLoadTypeSelectDialogClose,
              cancel: _vm.nevermindLoadTypeDialog,
              "update-selected-load-type": _vm.updateSelectedLoadType,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }