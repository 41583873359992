<script>
import appointmentMixin from '@/components/mixins/appointmentMixin';
import carrierFavoritesMixin from '@/components/mixins/carrierFavoritesMixin';
import recurringAppointmentMixin from '@/components/mixins/recurringAppointmentMixin';
import customFormsMixin from '@satellite/components/mixins/customFormsMixin';
import { CustomFieldType } from '@satellite/../nova/core';

/**
 * @displayName Appointment Details Base
 */
export default {
  mixins: [appointmentMixin, recurringAppointmentMixin, carrierFavoritesMixin, customFormsMixin],
  props: {
    /**
     * Appointment to display details for
     */
    appointment: {
      type: Object,
      required: true
    },
    /**
     * Display status editing options
     */
    shouldEditStatus: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldDisplayCancelButton: {
      type: Boolean,
      required: false,
      default: true
    },
    appointmentWarehouse: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    canReschedule() {
      return (
        this.$rolePermissions.canUpdateAppointment &&
        this.novaCore.isRescheduleAllowed(this.appointment.status) &&
        !this.readOnly
      );
    },
    reschedulesCount() {
      return this.novaCore.getReschedulesCount(this.appointment);
    },
    reschedulesString() {
      return this.novaCore.getReschedulesString(this.reschedulesCount);
    },
    gateManagementTriggers() {
      return (
        this.warehouseTriggers?.filter(
          t =>
            this.novaCore.CustomFormsGateManagementFeatures.includes(t.feature) &&
            t.dataEntityName === this.novaCore.CustomFormDataEntitiesEnum.appointment
        ) || []
      );
    },
    claimSupportTriggers() {
      return (
        this.warehouseTriggers?.filter(t =>
          this.novaCore.CustomFormsClaimFeatures.includes(t.feature)
        ) || []
      );
    }
  },
  data() {
    return {
      CustomFieldType,
      showRescheduleDialog: false,
      loading: true,
      tags: this.appointment.tags,
      lockTagEditing: false,
      warehouseTriggers: [],
      assetVisitFormData: [],
      appointmentTableRows: {
        dockName: {
          label: 'Dock Name',
          value: `${this.appointment.readableDockName ?? this.appointment.dock?.name}${
            this.appointment.dock.isActive === false ? ' (Inactive)' : ''
          }`,
          tdClass: 'pb-4',
          isHidden: false
        },
        loadType: {
          label: 'Load Type',
          value: '',
          tdClass: 'pb-4',
          isHidden: false
        },
        refNumber: {
          label: 'Reference Number',
          value: this.appointment.refNumber || '----',
          tdClass: 'pb-4',
          isHidden: false
        }
      }
    };
  },
  methods: {
    featureData(feature) {
      return this.novaCore.CustomFormsFeaturesData[feature] || {};
    },
    trackApptDetailsMixpanelEvent() {
      const entryPoint = this.mixpanel.getEntryPoint(this, [
        {
          entryPoint: 'Carrier Insights',
          component: 'carrier-metrics-table'
        },
        {
          entryPoint: 'Mobile List View',
          component: 'appointments-list-mobile'
        },
        {
          entryPoint: 'Appointments List',
          component: 'appointments-list'
        },
        {
          entryPoint: 'Self Check-in List',
          component: 'check-in-notification-panel'
        },
        {
          entryPoint: 'Appointments Grid',
          component: 'appointments-page'
        },
        {
          entryPoint: 'Search Appointments',
          component: 'appointments-search-results'
        }
      ]);
      this.mixpanel.track(this.mixpanel.events.MODULE.APPOINTMENT.APPOINTMENT_DETAILS, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id,
        'Warehouse Name': this.appointmentWarehouse.name,
        'Warehouse ID': this.appointmentWarehouse.id,
        'Appointment ID': this.appointment.id,
        'Entry Point': entryPoint
      });
    },
    initializeRowValues() {
      const refNumSettings = this.$refNumSettings(this.appointmentWarehouse);
      this.appointmentTableRows.loadType.value = this.$loadTypeName(this.appointment.loadType);
      this.appointmentTableRows.refNumber.label = refNumSettings.displayName;
      this.appointmentTableRows.refNumber.isHidden = !refNumSettings.isVisible;
      this.appointmentTableRows.refNumber.value = this.appointment.refNumber;
      this.appointmentTableRows.dockName.value = this.appointment.dock.name;
    },
    async updateAppointmentTags() {
      this.lockTagEditing = true;
      await axios
        .patch(`appointment/${this.appointment.id}`, {
          tags: this.tags
        })
        .then(() => {
          this.$store.dispatch('Appointments/trackMixpanelEvent', {
            appointment: this.appointment || {},
            change: 'Tags'
          });
        })
        .finally(() => (this.lockTagEditing = false));
    },
    async setWarehouseTriggers() {
      const response = await axios.get('custom-forms/trigger', {
        params: {
          s: {
            objectId: this.appointmentWarehouse.id,
            entityName: 'warehouse'
          },
          limit: 100
        }
      });
      this.warehouseTriggers = response?.data?.data;
    },
    async getAssetVisitFormData() {
      if (this.appointment.assetVisit) {
        try {
          const response = await axios.get('custom-forms/form-data', {
            params: {
              s: { objectId: this.appointment.assetVisit.id },
              limit: 1000
            }
          });
          return response?.data?.data || [];
        } catch (_) {
          return [];
        }
      }
    }
  },
  async mounted() {
    this.initializeRowValues();
    this.setCustomFieldsRows();
    if (this.$selectedWarehouseTriggers) {
      this.warehouseTriggers = this.$selectedWarehouseTriggers;
    } else {
      await this.setWarehouseTriggers();
    }
    this.assetVisitFormData = this.getAssetVisitFormData();
    this.loading = false;
    this.$eventHub.$on(`update-Trigger`, this.setWarehouseTriggers);
    this.$eventHub.$on(`create-FormField`, this.setWarehouseTriggers);
  },
  beforeDestroy() {
    this.$router.push({ query: {} });
    Object.keys(this.sockets.actions).map(action => {
      this.$eventHub.$off(`${action}-Trigger`, this.setWarehouseTriggers);
      this.$eventHub.$off(`${action}-FormField`, this.setWarehouseTriggers);
    });
  },
  watch: {
    tags() {
      if (JSON.stringify(this.tags) !== JSON.stringify(this.appointment.tags)) {
        this.updateAppointmentTags();
      }
    },
    appointment(appointment) {
      this.initializeRowValues();
      this.setCustomFieldsRows();
      this.tags = appointment.tags;
    }
  }
};
</script>

<style lang="scss">
.appointment-notes > p:not(:only-child) {
  margin-bottom: 1px !important;
}
</style>
