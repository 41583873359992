<template>
  <vue-tel-input-vuetify
    ref="telInput"
    v-bind="[$props, $attrs]"
    :value="value"
    :hide-details="hideDetails ? 'auto' : false"
    v-on:input="onInput"
    v-on:country-changed="validateInput"
    :defaultCountry="defaultCountry"
    :rules="validationRules"
    mode="international"
    :style="style"
    :preferred-countries="preferredCountries">
    <template #label v-if="required">
      {{ label }}
      <span class="red--text">*</span>
    </template>
    <template v-slot:label v-if="tooltipText">
      <div class="with-tooltip">
        {{ label }}
        <help-icon-tooltip iconStyleClass="grey darken-2">{{ tooltipText }}</help-icon-tooltip>
      </div>
    </template>
  </vue-tel-input-vuetify>
</template>

<script>
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue';

export default {
  components: {
    VueTelInputVuetify
  },
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    validator: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: 'Phone Number'
    },
    defaultCountry: {
      type: String,
      required: false,
      default: 'US'
    },
    marginTop: {
      type: String,
      required: false
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      validationRules: []
    };
  },
  computed: {
    preferredCountries() {
      return [
        'US',
        'CA',
        'AU',
        'BE',
        'BR',
        'DE',
        'ES',
        'GB',
        'HU',
        'IE',
        'IT',
        'KR',
        'MT',
        'MX',
        'NL',
        'NZ',
        'PH',
        'PL',
        'PR',
        'PT',
        'SE',
        'SG',
        'TR',
        'UK'
      ];
    },
    activeCountryIso2() {
      return this.$refs.telInput?.activeCountry?.iso2 ?? this.novaCore.DEFAULT_COUNTRY_CODE;
    },
    style() {
      if (this.marginTop) {
        return {
          'margin-top': this.marginTop
        };
      }
      return {};
    }
  },
  methods: {
    validateInput() {
      this.validationRules = [];

      if (this.required) {
        this.validationRules.push(...this.validator.rules.required(this.label));
      }
      this.validationRules.push(
        this.validator.rules.phone[0](this.$refs.telInput.value, this.activeCountryIso2)
      );
    },
    onInput(value) {
      this.$emit('input', value);
      this.validateInput();
    }
  },
  watch: {
    defaultCountry(country) {
      if (!this.value) {
        this.$refs.telInput?.choose({ iso2: country });
      }
    }
  }
};
</script>
