var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "full-height d-flex flex-column justify-center align-center",
    },
    [
      _c("img", {
        staticClass: "welcome-image",
        attrs: { alt: "welcome to Opendock Nova", src: _vm.welcomeImg },
      }),
      _c("h3", { staticClass: "mt-4 mb-2" }, [_vm._v(_vm._s(_vm.greeting))]),
      _vm._m(0),
      _c("create-org-dialog"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-center font-size-small" }, [
      _vm._v(" In order to fully use Opendock, you first need to "),
      _c("strong", [_vm._v("create your Organization")]),
      _vm._v("."),
      _c("br"),
      _vm._v(" Don't worry, our "),
      _c("strong", [_vm._v("Getting Started Checklist")]),
      _vm._v(" will guide you through it. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }