import { isNumber } from 'class-validator';
import { DateTime } from 'luxon';

// TODO: Migrate any of these that make sense to ExtendedAppointment.js file

/**
 * Mixin used for appointments
 * @mixin
 * @displayName Appointment Mixin
 */
export default {
  computed: {
    useMilitaryTime() {
      return this.$isMilitaryTimeEnabled(this.appointment?.dock?.warehouse);
    },
    canCancel() {
      let canCancel = false;
      if (this.appointment?.status) {
        canCancel = this.novaCore.isCancelAllowed(this.appointment.status);
      }

      return canCancel;
    },
    isCancelled() {
      return this.isStatusMatch(this.novaCore.AppointmentStatus.Cancelled);
    },
    isRequested() {
      return this.isStatusMatch(this.novaCore.AppointmentStatus.Requested);
    },
    isCompleted() {
      return this.isStatusMatch(this.novaCore.AppointmentStatus.Completed);
    },
    isScheduled() {
      return this.isStatusMatch(this.novaCore.AppointmentStatus.Scheduled);
    },
    isNoShow() {
      return this.isStatusMatch(this.novaCore.AppointmentStatus.NoShow);
    },
    cancelButtonIcon() {
      return this.isCancelled ? 'mdi-alert' : 'mdi-delete-outline';
    },
    cancelAppointmentLabel() {
      let label = 'Cancel Appointment';
      if (this.appointment?.statusTimeline) {
        let cancelledTime =
          this.appointment.statusTimeline[this.novaCore.AppointmentStatus.Cancelled];

        if (cancelledTime) {
          let cancelMoment = momentjs.tz(cancelledTime, this.appointment.dock.warehouse.timezone);
          label = `This appointment was cancelled on ${cancelMoment
            .clone()
            .format('MM/DD/YY')} @ ${cancelMoment.clone().format('h:mma')}`;
        }
      }

      return label;
    },
    isPastAppointment() {
      let currentMoment = momentjs().tz(this.appointment?.dock?.warehouse?.timezone);
      let appointmentMoment = momentjs(this.appointment.start).tz(
        this.appointment?.dock?.warehouse?.timezone
      );
      return appointmentMoment.isBefore(currentMoment);
    },
    user() {
      return this.appointment.user;
    },
    company() {
      return this.user.company;
    },
    companyName() {
      return this.company?.name;
    },
    scac() {
      return this.appointment.user.company?.scac || '----';
    },
    formattedAppointmentDate() {
      const tz = this.appointment.dock.warehouse.timezone;
      return this.novaCore.formatDateTimeWithMilitarySupport(
        this.appointment.start,
        tz,
        this.novaCore.LuxonDateTimeFormats.LongDateShortMonth,
        this.useMilitaryTime,
        this.novaCore.LuxonDateTimeFormats.LongDateShortMonth
      );
    },
    formattedAppointmentDateTime() {
      const interval = this.getAppointmentInterval(this.appointment);
      return `${this.formattedAppointmentDate} • ${interval}`;
    },
    appointmentDateTimeHeader() {
      const interval = this.getAppointmentInterval(this.appointment);
      return `${this.formattedAppointmentDate}<span class='mx-2 d-inline-block'>&bull;</span>${interval}`;
    },
    refNumLabel() {
      return this.$getSettingValue(
        'referenceNumberDisplayName',
        this.appointment?.dock?.warehouse?.settings || null
      );
    },
    currentTimestamp() {
      return this.novaCore.formatDateTimeWithMilitarySupport(
        DateTime.now().toISO(),
        this.appointment.dock.warehouse.timezone,
        this.novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
        this.useMilitaryTime,
        this.novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
      );
    },
    warehouse() {
      return this.dock?.warehouse;
    },
    warehouseAddress() {
      return `${this.warehouse.street}, ${this.warehouse.city}, ${this.warehouse.state} ${this.warehouse.zip}`;
    },
    appointmentNotes() {
      return this.appointment.notes?.length > 0 ? this.appointment.notes : 'N/A';
    },
    appointmentTags() {
      return this.appointment.tags?.length > 0 ? this.appointment.tags.join(', ') : 'N/A';
    },
    dock() {
      return this.appointment.dock;
    },
    loadType() {
      return this.appointment.loadType;
    },
    statusTimeline() {
      return this.appointment.statusTimeline;
    },
    arrivalTime() {
      return this.statusTimeline[this.novaCore.AppointmentStatus.Arrived];
    },
    formattedArrivalTime() {
      return this.arrivalTime
        ? this.novaCore.formatDateTimeWithMilitarySupport(
            this.arrivalTime,
            this.appointment.dock.warehouse.timezone,
            this.novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
            this.useMilitaryTime,
            this.novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
          )
        : 'Waiting for carrier to arrive';
    },
    completedTime() {
      return this.statusTimeline[this.novaCore.AppointmentStatus.Completed];
    },
    formattedCompletedTime() {
      return this.completedTime
        ? this.novaCore.formatDateTimeWithMilitarySupport(
            this.completedTime,
            this.appointment.dock.warehouse.timezone,
            this.novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
            this.useMilitaryTime,
            this.novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
          )
        : 'Not completed yet';
    },
    noShow() {
      return this.appointment.status === this.novaCore.AppointmentStatus.NoShow;
    },
    timezone() {
      return this.warehouse.timezone;
    },
    arrivalTimeDiff() {
      let readableDuration = '';
      if (this.arrivalTime) {
        readableDuration = this.util.getReadableDuration(
          this.appointment.start,
          this.arrivalTime,
          this.timezone,
          ' late'
        );
      }

      return readableDuration;
    },
    completedTimeDiff() {
      let readableDuration = '';
      if (this.completedTime) {
        readableDuration = this.util.getReadableDuration(
          this.arrivalTime,
          this.completedTime,
          this.timezone,
          ' dwell'
        );
      }

      return readableDuration;
    },
    canCopy() {
      return Boolean(navigator?.clipboard);
    }
  },
  data() {
    return {
      showConfirmDialog: false,
      showDetailsDialog: false,
      customFields: {}
    };
  },
  methods: {
    isStatusMatch(status) {
      return this.appointment?.status === status;
    },
    /** @deprecated Please, refrain from using Moment in favor of Chronon's Luxon-powered formatDateTime */
    formatDateTime(dateTime, format, applyTimezone = false) {
      if (!applyTimezone) {
        return this.novaCore.getFormattedTime(dateTime, format);
      }
      return momentjs(dateTime)
        .tz(this.appointment.dock.warehouse.timezone)
        .format(format)
        .toUpperCase();
    },
    getAbbreviatedTimezoneName(appointment) {
      return this.novaCore.formatDateTimeWithMilitarySupport(
        appointment.start,
        appointment.dock.warehouse.timezone,
        this.novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset,
        false,
        this.novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset
      );
    },
    getAppointmentInterval(appointment, applyTimezone = true) {
      const tz = applyTimezone ? appointment.dock.warehouse.timezone : null;

      const start = this.novaCore.formatDateTimeWithMilitarySupport(
        appointment.start,
        tz,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.useMilitaryTime,
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
      const end = this.novaCore.formatDateTimeWithMilitarySupport(
        appointment.end,
        tz,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.useMilitaryTime,
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );

      let intervalString = `${start} - ${end}`;
      if (applyTimezone) {
        const timezone = this.getAbbreviatedTimezoneName(appointment);
        intervalString += ` (${timezone})`;
      }
      return this.novaCore.transformForUserFriendlyTimezone(
        intervalString,
        this.appointment.dock.warehouse.timezone
      );
    },
    async confirmCancel() {
      this.showConfirmDialog = true;
      await this.$refs.cancelAppointment.open();
    },
    async handleApptIdURLQuery() {
      const routeAppointmentId = this.$route.query.appointmentId;
      if (routeAppointmentId && routeAppointmentId !== !this.$selectedEvent?.id) {
        await this.fetchAndOpenAppt(routeAppointmentId);
      }
    },
    async fetchAndOpenAppt(apptId) {
      const response = await this.$store.dispatch('Calendar/getEvent', apptId);
      this.$store.commit('Calendar/setSelectedEvent', response);
      this.showDetailsDialog = true;
    },
    getCustomFieldValue(field) {
      if (field?.value || field.value === false || isNumber(field.value)) {
        return this.novaCore.getCustomFieldFormattedValue(field, {
          [this.novaCore.CustomFieldType.Document]: { generateLink: true },
          [this.novaCore.CustomFieldType.MultiDocument]: { generateLink: true },
          [this.novaCore.CustomFieldType.Timestamp]: {
            timezone: this.appointment?.dock?.warehouse?.timezone,
            formatAsMilitary: this.useMilitaryTime
          }
        });
      }
    },
    copyApptId() {
      if (this.canCopy) {
        navigator.clipboard.writeText(this.appointment.id);
        this.notify('Copied to Clipboard!');
        this.mixpanel.track(this.mixpanel.events.ACTION.COPIED_APPOINTMENT_ID, {
          'Org Name': this.$org.name,
          'Org ID': this.$org.id,
          'Appointment ID': this.appointment.id
        });
      }
    },
    getWarehouseAddress(warehouse) {
      return `${warehouse.street}, ${warehouse.city}, ${warehouse.state} ${warehouse.zip}`;
    },
    getUserName(user) {
      return `${user.firstName} ${user.lastName}`;
    },
    setCustomFieldsRows() {
      this.customFields = {};
      if (this.appointment.customFields && Array.isArray(this.appointment.customFields)) {
        this.appointment.customFields.map((field, index) => {
          const fieldName = this.novaCore.getDefaultCustomFieldName(field);
          const fieldTemplate = this.appointment.dock.warehouse.customApptFieldsTemplate?.find(
            template => fieldName === this.novaCore.getDefaultCustomFieldName(template)
          );

          if (field.value || field.value === false || isNumber(field.value)) {
            this.customFields[fieldName] = {
              label: field.label,
              value: this.getCustomFieldValue(field),
              name: fieldName,
              tdClass: 'pb-4',
              key: `custom-field-${index}`,
              hiddenFromCarrier: Boolean(fieldTemplate?.hiddenFromCarrier),
              requiredForCarrier: Boolean(fieldTemplate?.requiredForCarrier),
              requiredForWarehouse: Boolean(fieldTemplate?.requiredForWarehouse),
              type: field.type
            };
          }
        });
      }
    },
    formatPhoneNumber(value) {
      return this.novaCore.formatPhoneNumber(value);
    },
    showCustomField(customField) {
      if (!customField) {
        return false;
      }

      const isDocument = [
        this.novaCore.CustomFieldType.Document,
        this.novaCore.CustomFieldType.MultiDocument
      ].includes(customField.type);

      if (isDocument) {
        return false;
      }

      return (
        Boolean(customField.value) || customField.value === false || isNumber(customField.value)
      );
    },
    getRowVal(val) {
      if (val === '' || val === undefined || val === null) {
        return '<span>----</span>';
      }
      return val;
    },
    getAppointmentDateTimeHeader(appointment) {
      if (appointment?.id) {
        const interval = this.getAppointmentInterval(appointment, false);
        return `${this.formattedAppointmentDate}<span class='mx-2 d-inline-block'>&bull;</span>${interval}`;
      }
    },
    getFullAppointmentTimezone(appointment) {
      if (appointment?.id) {
        return DateTime.fromISO(appointment.start)
          .setZone(appointment.dock.warehouse.timezone)
          .zoneName.replace('_', ' ');
      }
    }
  }
};
