<template>
  <custom-field-input-base-old
    single-line
    :field="field"
    v-model="value"
    type="number"></custom-field-input-base-old>
</template>

<script>
import customFieldTypeMixinOld from '@satellite/modules/custom_fields/mixins/customFieldTypeMixinOld';

export default {
  name: 'custom-field-number-old',
  mixins: [customFieldTypeMixinOld],

  data() {
    return {
      value: String(this.field.value) // Cast to string to prevent "0" as default in field
    };
  },
  watch: {
    value() {
      if (this.value === '') {
        this.$emit('input', '');
        return;
      }
      this.$emit('input', Number(this.value));
    }
  }
};
</script>
