<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    :header="header"
    :loading="false"
    @close="close"
    @confirm="$refs.setEtaForm.submit()"
    width="600"
    :has-dialog-actions="false"
    max-width="100%">
    <template v-slot:body>
      <v-alert :type="alertType" class="mb-0" shaped v-if="alertMessage"
        >{{ alertMessage }}
      </v-alert>

      <set-eta-form
        @condition-updated="updateCondition"
        @close="close"
        ref="setEtaForm"
        :party-to-alert="partyToAlert"
        :appointment="appointment"></set-eta-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for the reschedule form
 * @displayName Reschedule Appointment Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * Alert message to display in dialog
     */
    alertMessage: {
      type: String,
      required: false
    },
    /**
     * Alert type
     * @values success | error
     */
    alertType: {
      type: String,
      required: false,
      default: 'success'
    },
    appointment: {
      type: Object,
      required: true
    },
    etaCondition: {
      type: String,
      required: true
    },
    partyToAlert: {
      type: String,
      required: false,
      default: 'Warehouse',
      validator(value) {
        // The value must match one of these strings
        return ['Warehouse', 'Carrier'].includes(value);
      }
    }
  },
  computed: {
    header() {
      const etaCondition =
        this.etaCondition === this.novaCore.EtaCondition.OnTime ? 'on time' : this.etaCondition;
      return `Send alert: ${this.novaCore.getEtaVerb(etaCondition)} ${etaCondition.toLowerCase()}`;
    }
  },
  methods: {
    updateCondition(newCondition) {
      this.$emit('condition-updated', newCondition);
    }
  }
};
</script>
