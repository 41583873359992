var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-4 full-width d-flex flex-row align-center" },
    [
      _vm.label
        ? _c(
            "h3",
            {
              staticClass: "mr-4",
              class: _vm.disabled ? "text--disabled" : "",
            },
            [
              _vm._v(" " + _vm._s(_vm.label) + ": "),
              _vm.tooltipText
                ? _c("help-icon-tooltip", [
                    _vm._v(" " + _vm._s(_vm.tooltipText) + " "),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-autocomplete",
        _vm._b(
          {
            ref: "carrierSelect",
            staticClass: "pt-0",
            attrs: {
              required: _vm.required,
              rules: _vm.rules,
              "prepend-icon": !_vm.hideIcon ? "mdi-truck" : "",
              "hide-details": "auto",
              "search-input": _vm.filters.searchStr,
              value: _vm.value,
              items: _vm.groupedCarriers,
              "item-value": "id",
              "no-filter": "",
              label: _vm.value ? null : _vm.placeholder,
              loading: _vm.isLoading,
              "return-object": "",
              multiple: _vm.multiple,
              disabled: _vm.disabled,
            },
            on: {
              input: _vm.updateValue,
              "update:searchInput": function ($event) {
                return _vm.$set(_vm.filters, "searchStr", $event)
              },
              "update:search-input": function ($event) {
                return _vm.$set(_vm.filters, "searchStr", $event)
              },
            },
            scopedSlots: _vm._u(
              [
                _vm.showWarning
                  ? {
                      key: "prepend-item",
                      fn: function () {
                        return [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mx-1 mb-0",
                              attrs: { color: "chalkorange" },
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "mb-1" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "", color: "primary" },
                                    },
                                    [_vm._v("mdi-information")]
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      "Appointment notifications will be sent out to your carrier if enabled."
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("p", { staticClass: "mt-1 mb-0 ml-7" }, [
                                _vm._v(
                                  " To schedule a test appointment, please select an email ending in “@example.com”. "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                {
                  key: "selection",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.getSelectedCarrierText(item, 40)) + " "
                      ),
                    ]
                  },
                },
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      [
                        _c(
                          "div",
                          { staticClass: "full-width" },
                          [
                            _c(
                              "v-list-item",
                              {
                                staticClass: "px-0",
                                attrs: {
                                  "input-value": _vm.isCarrierFavorite(item),
                                },
                              },
                              [
                                _c("v-list-item-content", [
                                  _c("div", { staticClass: "item-wrapper" }, [
                                    _c(
                                      "div",
                                      { staticClass: "name-and-info" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            class: {
                                              "carrier-name":
                                                _vm.isCarrierFavorite(item),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getCarrierName(item)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "company-info" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-1",
                                                attrs: { size: "14px" },
                                              },
                                              [_vm._v("mdi-truck-outline")]
                                            ),
                                            _c("strong", [
                                              _vm._v(_vm._s(item.company.name)),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.company.scac || ""
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "email-and-subscribers" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "carrier-email" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.email) + " "
                                            ),
                                          ]
                                        ),
                                        _vm.getCarrierEmailCCs(item).length > 0
                                          ? _c(
                                              "span",
                                              { staticClass: "subscribers" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "14px" } },
                                                  [
                                                    _vm._v(
                                                      "mdi-email-fast-outline"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " +" +
                                                    _vm._s(
                                                      _vm.getCarrierEmailCCs(
                                                        item
                                                      ).length
                                                    ) +
                                                    " subscribers "
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "favorite" },
                                      [
                                        _vm.isCarrierFavorite(item)
                                          ? _c(
                                              "v-icon",
                                              { attrs: { color: "primary" } },
                                              [_vm._v("mdi-star")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ]
                  },
                },
                {
                  key: "no-data",
                  fn: function () {
                    return [
                      _c(
                        "v-list-item",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.filters.searchStr && !_vm.carriers.length
                                    ? "No matching carrier contacts found..."
                                    : _vm.placeholder
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                !_vm.isLoading && _vm.showCarrierCreateButton
                  ? {
                      key: "append-item",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c("create-carrier-dialog", {
                                    attrs: {
                                      "entry-point":
                                        "Carrier Contacts Select List",
                                    },
                                    on: { save: _vm.handleNewCarrier },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (slotProps) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  " Didn't find your carrier contact? "
                                                ),
                                                _c(
                                                  "a",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { href: "#" } },
                                                      "a",
                                                      slotProps.attrs,
                                                      false
                                                    ),
                                                    slotProps.on
                                                  ),
                                                  [_vm._v("Create new")]
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1474406214
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                _vm.required
                  ? {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(" " + _vm._s(_vm.placeholder) + " "),
                          _c("span", { staticClass: "red--text" }, [
                            _c("strong", [_vm._v("*")]),
                          ]),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          "v-autocomplete",
          [_vm.$attrs, _vm.$props],
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }