<template>
  <div class="full-height" v-if="appointmentWithWarehouse?.warehouse?.id">
    <section class="sidebar-content">
      <div class="sidebar">
        <v-tabs v-model="tab" vertical :centered="false" hide-slider>
          <v-tab key="status">Status & Details</v-tab>
          <v-tab key="claims_support" :disabled="claimSupportTriggers.length === 0">
            Claims Support
          </v-tab>
          <v-tab key="gate_management">Gate Management</v-tab>
          <v-tab key="drivers_chat">Drivers Chat</v-tab>
          <v-tab key="audit_log">Audit Log</v-tab>
        </v-tabs>
      </div>
      <div class="content" v-if="appointmentWithWarehouse?.id">
        <v-tabs-items v-model="tab">
          <v-tab-item key="details" :transition="false">
            <appointment-details-status
              v-if="tab === 0"
              v-bind="$props"
              :appointment="appointmentWithWarehouse" />
          </v-tab-item>
          <v-tab-item key="claims_support" :transition="false">
            <tab-content-centered>
              <template #content>
                <appointment-details-claims
                  v-if="tab === 1"
                  v-bind="$props"
                  :appointment="appointmentWithWarehouse" />
              </template>
            </tab-content-centered>
          </v-tab-item>
          <v-tab-item key="gate_management" :transition="false"></v-tab-item>
          <v-tab-item key="drivers_chat" :transition="false"></v-tab-item>
          <v-tab-item key="audit_log" :transition="false" @click="trackApptDetailsMixpanelEvent">
            <tab-content-centered class="audit-log-tab-wrapper">
              <template #content>
                <appointment-details-audit-log v-if="tab === 4" v-bind="$props" />
              </template>
            </tab-content-centered>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </section>
  </div>
</template>

<script>
import AppointmentDetailsBase from '@/modules/appointments/components/details/AppointmentDetailsBase.vue';
import { computed, ref, watch } from 'vue';
import warehouseService from '@satellite/services/warehouse-service';

/**
 * @displayName Appointment Details
 */
export default {
  extends: AppointmentDetailsBase,
  setup(props) {
    const tab = ref(0);
    const warehouse = ref(null);
    const appointmentWithWarehouse = computed(() => {
      return { ...props.appointment, warehouse: warehouse.value };
    });

    watch(
      () => props.appointment?.id,
      async () => {
        warehouse.value = {
          ...props.appointmentWarehouse,
          ...(await warehouseService.getWarehouseById(props.appointment.dock.warehouseId, {}))
        };
      },
      { immediate: true }
    );

    return {
      tab,
      appointmentWithWarehouse
    };
  },
  destroyed() {
    this.$eventHub.$off('action-field-submitted', this.handleActionFieldTriggered);
  },
  beforeMount() {
    this.$eventHub.$on('action-field-submitted', this.handleActionFieldTriggered);
    this.trackApptDetailsMixpanelEvent();
  }
};
</script>

<style scoped lang="scss">
.sidebar-content {
  display: flex;
  min-height: 100%;

  > .content {
    padding: 24px;
    flex: 1;
    background-color: $color-background-transparency-inverted;
  }
}

.sidebar {
  border-right: 1px solid $color-line-divider;
}

.v-tabs {
  position: sticky;
  top: 0;
}

.v-tab {
  font-size: 12px;
  height: auto;
  padding: 16px 12px;
  border-bottom: 2px solid $color-line-divider;
  font-weight: 600;

  &--active {
    color: $color-primary-100;
    border-bottom: 2px solid $color-primary-60;
  }
}

.v-window-item {
  background-color: rgba(146, 146, 146, 0.12);
}

::v-deep .audit-log-tab-wrapper {
  &.content {
    background-color: #fff;
    padding: 24px;
  }
}
</style>
