<template>
  <div :class="{ 'd-flex': horizontal }">
    <v-checkbox
      class="mt-2 mr-4"
      v-for="day in weekdays"
      :key="`${day}-checkbox`"
      color="primary"
      :hide-details="true"
      :disabled="day === disabledDay"
      dense
      v-model="checkedDays"
      :value="day">
      <template v-slot:label>
        <span class="font-size-small">{{ getLabel(day) }}</span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    /**
     * If provided, this will disable the checkbox for specific weekday
     */
    disabledDay: {
      type: String,
      required: false
    },
    horizontal: {
      type: Boolean,
      required: false
    },
    shortNames: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    /**
     * array of lowercase weekdays ["sunday", "monday"...]
     */
    weekdays() {
      return momentjs.weekdays().map(weekday => weekday.toLowerCase());
    }
  },
  data() {
    return {
      checkedDays: []
    };
  },
  methods: {
    getLabel(day) {
      const weekDay = this.novaCore.upperFirst(day);
      return this.shortNames ? weekDay.substring(0, 3) : weekDay;
    }
  },
  mounted() {
    this.checkedDays = this.value;
  },
  watch: {
    checkedDays(checkedDays) {
      this.$emit('input', checkedDays);
    }
  }
};
</script>
