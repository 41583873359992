<template>
  <div>
    <transition name="fade">
      <v-dialog v-model="loading" v-show="loading" persistent width="300">
        <v-card color="primary pt-2" dark>
          <v-card-text>
            {{ loadingMessage }}
            <v-progress-linear indeterminate color="white" class="mb-0 mt-4"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </transition>
    <div v-show="!loading" style="padding-bottom: 20px">
      <!-- @slot Content to display when not loading -->
      <slot name="default"></slot>
    </div>
    <div v-if="!loading">
      <slot name="form-actions"></slot>
    </div>
  </div>
</template>

<script>
/**
 * Higher order component loader
 * @displayName HOC Loader
 */
export default {
  name: 'hoc-dialog-loader',
  props: {
    /**
     * Display loader
     */
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    /**
     * Loading message
     */
    loadingMessage: {
      type: String,
      required: false,
      default: 'Loading'
    }
  },
  data() {
    return {
      // Always set dialog to true because it needs to be present on the page to handle events without errors
      showDialog: true
    };
  }
};
</script>
