var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pb-0 pt-5" },
            [
              _c(
                "v-row",
                { attrs: { "data-testid": "carrier-reporting-filters" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("text-field", {
                        attrs: {
                          "append-icon": "mdi-magnify",
                          "hide-details": "",
                          height: "26px",
                          dense: "",
                          label: "Search Carriers",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("warehouse-multi-select", {
                        ref: "reportingWarehouseSelect",
                        attrs: {
                          "is-flex": false,
                          "hide-icon": "",
                          "auto-select-all":
                            !_vm.getLastSelectedWarehouseIds()?.length,
                          dense: "",
                          placeholder: "Warehouses",
                          joins: ["docks||id,name"],
                          "select-fields": ["id,name,facilityNumber"],
                          "auto-select-by-ids":
                            _vm.getLastSelectedWarehouseIds(),
                          label: "Warehouses",
                          "show-store-number": "",
                        },
                        on: {
                          input: (ws) =>
                            _vm.persistLastSelectedData(
                              "warehouseIds",
                              ws?.map((w) => w.id)
                            ),
                        },
                        model: {
                          value: _vm.filters.selectedWarehouses,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "selectedWarehouses", $$v)
                          },
                          expression: "filters.selectedWarehouses",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("date-range", {
                        ref: "apptReportDateRange",
                        attrs: {
                          height: "26px",
                          dense: true,
                          clearable: "",
                          label: "Appointment Date Range",
                          "initial-range": [],
                        },
                        model: {
                          value: _vm.filters.appointmentDateRange,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "appointmentDateRange", $$v)
                          },
                          expression: "filters.appointmentDateRange",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "primary-button",
                    {
                      staticClass: "mt-1",
                      attrs: {
                        loading: _vm.buildingExcelData,
                        "before-icon": "microsoft-excel",
                      },
                      on: { click: _vm.exportExcel },
                    },
                    [_vm._v("Export to Excel")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-center mt-5" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "d-inline-flex",
                  attrs: {
                    outlined: "",
                    type: "info",
                    dense: "",
                    border: "left",
                  },
                },
                [
                  _vm._v(" The carrier insights page only shows data for "),
                  _c("strong", [_vm._v("completed")]),
                  _vm._v(
                    " appointments, it may not reflect the total appointments for each carrier. "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.carriers,
              search: _vm.search,
              "data-testid": "carrier-metrics-table",
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              loading: _vm.$data.$globalLoading,
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.onTimePct",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatDataPoint(item.onTimePct, "%")) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.latePct",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.formatDataPoint(item.latePct, "%")) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.cancelPct",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatDataPoint(item.cancelPct, "%")) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.noShowPct",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatDataPoint(item.noShowPct, "%")) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.rescheduledPct",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatDataPoint(item.rescheduledPct, "%")) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.avgCancelLeadTimeInMinutes",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.avgCancelLeadTimeInMinutes
                            ? _vm.novaCore.formatMinutesToHuman(
                                item.avgCancelLeadTimeInMinutes
                              )
                            : "----"
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex",
                        attrs: { "data-testid": "carrier-table-actions-cell" },
                      },
                      [
                        _c("carrier-appointments-list-dialog", {
                          attrs: {
                            "icon-class": "mr-2",
                            carrier: item,
                            "tooltip-text":
                              "View appointments for this carrier",
                            "appointment-ids": item.appointmentIds,
                          },
                        }),
                        _c("pie-chart-dialog", {
                          attrs: {
                            data: _vm.getPieData(item),
                            colors: [
                              "#00a66c",
                              "#775DD0",
                              "#FEB019",
                              "#fe4560",
                            ],
                            "dialog-title": item.name || "<NO NAME>",
                            labels: ["On Time", "Late", "Canceled", "No Show"],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "body.append",
                fn: function () {
                  return [
                    _c(
                      "tr",
                      { staticClass: "font-weight-bold primary white--text" },
                      [
                        _c("td", [_vm._v("All Carriers")]),
                        _c("td", [_vm._v("----")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.allCarrierData.totalAppointments)),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDataPoint(
                                _vm.allCarrierData.onTimePct,
                                "%"
                              )
                            )
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDataPoint(
                                _vm.allCarrierData.latePct,
                                "%"
                              )
                            )
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDataPoint(
                                _vm.allCarrierData.cancelPct,
                                "%"
                              )
                            )
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDataPoint(
                                _vm.allCarrierData.noShowPct,
                                "%"
                              )
                            )
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDataPoint(
                                _vm.allCarrierData.rescheduledPct,
                                "%"
                              )
                            )
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.allCarrierData.avgCancelLeadTimeInMinutes
                                  ? _vm.novaCore.formatMinutesToHuman(
                                      _vm.allCarrierData
                                        .avgCancelLeadTimeInMinutes
                                    )
                                  : "----"
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "pr-5" },
                          [
                            _c("pie-chart-dialog", {
                              attrs: {
                                "icon-color": "white",
                                "icon-class": "float-right",
                                data: _vm.getPieData(_vm.allCarrierData),
                                colors: [
                                  "#00a66c",
                                  "#775DD0",
                                  "#FEB019",
                                  "#fe4560",
                                ],
                                "dialog-title": "All Carriers",
                                labels: [
                                  "On Time",
                                  "Late",
                                  "Canceled",
                                  "No Show",
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }