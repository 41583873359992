<template>
  <custom-field-input-base
    :outlined="outlined"
    v-bind="$props"
    :field="field"
    v-model="value"
    type="text"></custom-field-input-base>
</template>

<script>
import customFieldTypeMixin from '@satellite/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-string',
  mixins: [customFieldTypeMixin]
};
</script>
