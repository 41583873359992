var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: _vm.header,
          scrollable: false,
          "has-dialog-actions": "",
          "loading-message": "Creating Load Type...",
          "max-width": "900px",
          width: "900px",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.createLoadTypeForm.submit()
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            disabled: !_vm.isCreationButtonDisabled,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _vm.$scopedSlots.activator
                                      ? _vm._t(
                                          "activator",
                                          null,
                                          null,
                                          slotProps
                                        )
                                      : _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [
                                            _c(
                                              "primary-button",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      disabled:
                                                        _vm.isCreationButtonDisabled,
                                                      "before-icon":
                                                        _vm.beforeIcon,
                                                      large: "",
                                                    },
                                                  },
                                                  "primary-button",
                                                  slotProps.attrs,
                                                  false
                                                ),
                                                slotProps.on
                                              ),
                                              [_vm._v(" Create Load Type ")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.getDisabledCreationMessage)),
                          ]),
                        ]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("load-type-form", {
                    ref: "createLoadTypeForm",
                    attrs: {
                      "omit-advanced-settings": true,
                      "skip-dirty-validation": true,
                      compact: true,
                      warehouse: _vm.warehouse,
                    },
                    on: { close: _vm.close },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }