<template>
  <form-base :header="header" :hide-required-legend="true">
    <template #form>
      <div class="full-width" v-if="loading">
        <v-progress-linear
          indeterminate
          :loading="loading"
          height="6"
          class="mt-12"></v-progress-linear>
        <h4 class="text-center mt-4">Rescheduling Appointment...</h4>
      </div>
      <v-form ref="form" v-else>
        <date-time-picker
          v-if="appointmentWarehouse?.id"
          v-model="selectedTime"
          :appointment="appointment"
          :selected-docks="Array.isArray(selectedDock) ? selectedDock : [selectedDock]"
          :selected-load-type="selectedLoadType"
          :start-date="startDate"
          :existing-start="appointment.start"
          :existing-end="appointment.end"
          :selected-warehouse="appointmentWarehouse"
          :timezone="appointment.dock.warehouse.timezone"
          is-reschedule
          :allow-dock-select="allowDockSelect"
          @update-selected-dock="updateSelectedDock"
          @update-selected-loadtype="updateSelectedLoadType"
          @availability-error="handleAvailabilityError"></date-time-picker>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        :hide-cancel="loading"
        :hide-confirm="loading"
        :disable-confirm="!selectedTime"
        @cancel="cancel"
        confirm-label="Save"
        @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
export default {
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    },
    /**
     * @model
     */
    appointment: {
      type: Object,
      required: true
    }
  },
  computed: {
    startDate() {
      return this.novaCore.getFormattedTime(this.appointment.start, this.dateFormat);
    }
  },
  data() {
    return {
      selectedDate: this.startDate,
      selectedTime: null,
      dateFormat: 'YYYY-MM-DD',
      loading: false,
      appointmentWarehouse: null,
      selectedDock: null,
      selectedLoadType: null,
      allowDockSelect: false
    };
  },
  created() {
    this.selectedDock = this.appointment.dock;
    this.selectedLoadType = this.appointment.loadType;
  },
  async beforeMount() {
    await this.setAppointmentWarehouse();
    this.setParentDockData();
  },
  mounted() {
    this.selectedTime = {
      start: momentjs(this.appointment.utcStart ?? this.appointment.start),
      docks: [this.appointment.dockId]
    };
  },
  methods: {
    setParentDockData() {
      if (this.appointment.dock.capacityParentId) {
        this.appointment.dockId = this.appointment.dock.capacityParentId;
        this.appointment.dock.id = this.appointment.dock.capacityParentId;
      }
    },
    async setAppointmentWarehouse() {
      if (!this.appointment?.dock?.warehouse?.id) {
        throw new Error('Appointment is missing dock/warehouse object');
      }

      if (!this.$selectedWarehouse && this.$warehouse?.id === this.appointment.dock.warehouse.id) {
        this.appointmentWarehouse = this.$warehouse;
      } else if (
        Boolean(this.$selectedWarehouse?.id) &&
        this.$selectedWarehouse.id === this.appointment.dock.warehouse.id
      ) {
        this.appointmentWarehouse = this.$selectedWarehouse;
      } else {
        this.appointmentWarehouse = await this.$store.dispatch(
          'Warehouses/getWarehouseById',
          this.appointment.dock.warehouse.id
        );
      }
    },
    cancel() {
      this.$emit('close');
    },
    findAvailableDockId() {
      // Return the same dock, if available
      return (
        this.selectedTime.docks.find(dockId => this.appointment.dockId === dockId) ||
        this.selectedTime.docks[0]
      );
    },
    async submit() {
      this.loading = true;
      const shouldSuppressNotification = this.novaCore.isRequested(this.appointment);
      await axios
        .patch(
          `appointment/${this.appointment.id}`,
          {
            start: this.selectedTime.start.clone().utc().format(),
            dockId: this.findAvailableDockId(),
            loadTypeId: this.selectedLoadType.id
          },
          {
            suppressNotification: shouldSuppressNotification
          }
        )
        .then(response => {
          if (response?.data?.data) {
            this.mixpanel.track(this.mixpanel.events.MODULE.APPOINTMENT.RESCHEDULED, {
              'Org Name': this.$org?.name,
              'Org ID': this.$org?.id || this.appointment.orgId,
              'Appointment ID': this.appointment.id,
              'Appointment Start': this.selectedTime.start.clone().utc().format(),
              'Entry Point': 'Details Modal',
              'Appointment ETA': this.appointment.eta,
              'Warehouse ID': this.appointment.dock.warehouse.id,
              'Warehouse Name': this.appointment.dock.warehouse.name
            });

            if (this.novaCore.isRequested(this.appointment)) {
              this.notify('Request Updated', 'success');
            }

            this.$eventHub.$emit('appointment-updated');
            this.$emit('close');
          }
        })
        .catch(err => {
          if (shouldSuppressNotification) {
            const message = err.response?.data?.message ?? err.message;
            this.notify(message, 'error');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async handleAvailabilityError() {
      /**
       * Back to step 2 after 750ms
       * The intention of the delay is just a UX improvement to avoid a fast transition between steps
       */
      setTimeout(() => {
        this.cancel();
      }, 750);
    },
    updateSelectedDock(newDock) {
      if (newDock.id) {
        this.selectedDock = newDock;
      }
    },
    updateSelectedLoadType(newLoadType) {
      this.selectedLoadType = newLoadType;
    }
  }
};
</script>
