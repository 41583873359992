<template>
  <div class="custom-field-timestamp">
    <div class="component-item">
      <date-picker
        v-model="customDate"
        placeholder="Select date"
        compact-display
        :display-field-label="false"
        :display-field-icon="false"
        :required="field.required"></date-picker>
    </div>
    <div class="component-item">
      <time-entry
        :date="dateTime"
        :warehouse="warehouse"
        v-model="customTime"
        :fontSizeLarge="false"
        :required="field.required"
        :compact="isMobile()"
        inline></time-entry>
    </div>
  </div>
</template>

<script>
import customFieldTypeMixinOld from '@satellite/modules/custom_fields/mixins/customFieldTypeMixinOld';
import { DateTimeFormats } from '@satellite/../nova/core';
import { isMobile } from '@satellite/plugins/util';

export default {
  name: 'custom-field-timestamp-old',
  mixins: [customFieldTypeMixinOld],
  props: {
    warehouse: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customTime: {
        amPm: '',
        time: ''
      },
      customDate: ''
    };
  },
  computed: {
    dateTime() {
      const newDateTimeString = [this.customDate, this.customTime.time, this.customTime.amPm].join(
        ' '
      );
      const newDateTime = momentjs.tz(
        newDateTimeString,
        `${DateTimeFormats.DateDashed} ${DateTimeFormats.Extended12HrTimeLeadingZeroHour} a`,
        this.warehouse.timezone
      );
      return newDateTime?.isValid() ? newDateTime : null;
    }
  },
  methods: {
    isMobile,
    emitValueUpdate(value) {
      if (this.field.required && !value) {
        return null;
      }
      this.$emit('input', this.dateTime);
    }
  },
  beforeMount() {
    if (this.value) {
      const currentDate = momentjs.tz(this.value, this.warehouse.timezone);

      if (currentDate?.isValid()) {
        this.customDate = currentDate.format(this.novaCore.DateTimeFormats.DateDashed);
        this.customTime.time = currentDate.format(
          this.novaCore.DateTimeFormats.Extended12HrTimeLeadingZeroHour
        );
        this.customTime.amPm = currentDate.format(this.novaCore.DateTimeFormats.AmPm);
      }
    }
  },
  watch: {
    value() {
      return false;
    },
    customDate(value) {
      this.emitValueUpdate(value);
    },
    customTime(value) {
      this.emitValueUpdate(value);
    }
  }
};
</script>
<style lang="scss">
.custom-field-timestamp {
  display: flex;
  gap: 1rem;
}
.is-mobile {
  .custom-field-timestamp {
    display: block;
    .component-item {
      margin-bottom: 5px !important;
      .v-input,
      .v-input-control {
        margin: 0 !important;
        padding-top: 5px !important;
        align-items: flex-start !important;
      }
    }
  }
}
</style>
