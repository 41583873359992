var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Update User Info",
          scrollable: false,
          loading: false,
          "loading-message": "Updating User...",
        },
        on: { close: _vm.close, confirm: _vm.submit },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _c(
                            "primary-button",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mr-2",
                                  attrs: { "before-icon": "upload", large: "" },
                                },
                                "primary-button",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            ),
                            [_vm._v("Update User")]
                          ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.user.id !== _vm.$me.id
            ? _c("edit-user-form", {
                ref: "editUserForm",
                attrs: { user: _vm.user },
                on: { close: _vm.close },
              })
            : _c("my-account-form", {
                ref: "myAccountForm",
                on: { close: _vm.close },
              }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }