<template>
  <dialog-base
    scrollable
    v-bind="$props"
    :key="renderKey"
    header="Link to appointment"
    width="820"
    max-width="100%"
    @close="close">
    <template #body>
      <div>
        Choose an existing appointment to link this arrival to. The appointment must be in the
        Requested or Scheduled statuses.
      </div>
      <em>
        Search by confirmation number, reference number, company name, Dock name, Load Type name, or
        status
      </em>

      <v-container class="rounded-box">
        <v-row align="center" class="mb-2 header-row">
          <v-col cols="5">
            <v-text-field
              class="box-text-field prepend-icon"
              placeholder="Search appointments"
              hide-details
              persistent-placeholder
              v-model="searchStr">
              <template #append>
                <v-icon small>mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <span class="text--color-text-secondary">
              <strong>{{ totalAppointments }}</strong>
              {{ pluralize(totalAppointments ?? 0, 'appointment') }}
            </span>
          </v-col>
        </v-row>
        <v-data-table
          @click:row="handleRowClick"
          :items="appointments"
          :headers="headers"
          fixed-header
          class="mt-4"
          height="50vh"
          :footer-props="{ 'items-per-page-options': [], 'items-per-page-text': '' }">
          <template #item.start="{ item }">{{ getAppointmentStart(item.start) }}</template>
          <template #item.status="{ item }">
            <v-chip
              small
              :color="item.status.toLowerCase()"
              class="is-uppercase font-weight-bold"
              :class="{
                'text--requested-darken3': isRequested(item),
                'text--color-neutral-0': isScheduled(item)
              }">
              {{ item.status }}
            </v-chip>
          </template>
        </v-data-table>
      </v-container>

      <v-dialog
        width="820"
        max-width="100%"
        v-model="shouldShowConfirmLinkDialog"
        v-if="appointmentToLink">
        <v-card>
          <v-card-title class="dialog-title">
            <span class="headline">Confirm link?</span>
            <v-btn icon @click="closeConfirmLinkDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <span>
              The arrival will be marked as "Planned". The asset and the appointment will be marked
              as "Arrived".
            </span>
            <div class="rounded-box my-6 pa-4">
              <span class="font-size-medium font-weight-bold">Appointment details</span>
              <div class="py-4 value-grid">
                <div class="block-value">
                  <span>Conf #</span>
                  <span>{{ appointmentToLink.confirmationNumber }}</span>
                </div>
                <div class="block-value">
                  <span>Start Date</span>
                  <span>{{ appointmentStartDate }}</span>
                </div>
                <div class="block-value">
                  <span>Start Time</span>
                  <span>{{ appointmentStartTime }}</span>
                </div>
                <div class="block-value">
                  <span>Carrier Company</span>
                  <span>{{ appointmentToLink.user.company.name }}</span>
                </div>
                <div class="block-value">
                  <span>Dock</span>
                  <span>{{ appointmentToLink.dock.name }}</span>
                </div>
                <div class="block-value">
                  <span>Direction</span>
                  <span>{{ appointmentToLink.loadType.direction }}</span>
                </div>
              </div>
            </div>

            <v-expansion-panels accordion>
              <asset-visit-item :warehouse="warehouse" :visit="assetToLink">
                <template #title>
                  <span class="font-size-medium font-weight-bold">Asset details</span>
                </template>
              </asset-visit-item>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions class="py-4">
            <outline-button before-icon="arrow-left" @click="closeConfirmLinkDialog">
              Back to appointments list
            </outline-button>
            <v-spacer></v-spacer>
            <primary-button @click="linkUnplannedCheckinToAppointment">Confirm link</primary-button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </dialog-base>
</template>
<script>
import {
  useRenderKey,
  useDialogBase,
  useRouter,
  useDebouncedInput,
  dialogBaseProps
} from '@/composables';
import { DateTime } from 'luxon';
import appointmentService from '@satellite/services/appointment-service';
import checkinService from '@satellite/services/checkin-service';
import {
  AppointmentStatus,
  formatDateTimeWithMilitarySupport,
  LuxonDateTimeFormats,
  isRequested,
  isScheduled,
  AppointmentType,
  pluralize,
  searchStringToQuery
} from '@satellite/../nova/core';
import { ref, watch, computed } from 'vue';
import { debounce } from 'lodash';

export default {
  props: {
    ...dialogBaseProps,
    warehouse: {
      type: Object,
      required: true
    },
    assetToLink: {
      type: Object,
      required: true
    },
    isMilitaryTimeEnabled: {
      type: Boolean,
      required: true
    },
    referenceNumberSettings: {
      type: Object,
      required: true
    }
  },
  emits: ['linked', 'close', 'confirm'],
  setup(props, context) {
    const router = useRouter();
    const { renderKey } = useRenderKey();

    const onClose = () => {
      context.emit('close');
    };

    const onDismiss = () => context.emit('close');

    const onConfirm = () => context.emit('confirm');

    const { close } = useDialogBase({
      onClose,
      onDismiss,
      onConfirm
    });

    const shouldShowConfirmLinkDialog = ref(false);

    const { value: searchStr } = useDebouncedInput({
      initialValue: '',
      onChange: () => {
        fetchAppointments();
      }
    });

    const appointmentToLink = ref(null);

    const appointments = ref([]);
    const totalAppointments = ref(0);

    const appointmentStartDate = computed(() =>
      DateTime.fromISO(appointmentToLink.value?.start, { zone: props.warehouse.timezone }).toFormat(
        LuxonDateTimeFormats.LongDateShortMonth
      )
    );

    const appointmentStartTime = computed(() =>
      formatDateTimeWithMilitarySupport(
        appointmentToLink.value?.start,
        props.warehouse.timezone,
        LuxonDateTimeFormats.Extended12HrTimeAMPMWithAbbreviatedNamedOffset,
        props.isMilitaryTimeEnabled,
        LuxonDateTimeFormats.Extended24HrTimeWithAbbreviatedNamedOffset
      )
    );

    // Appointments table
    const headers = [
      {
        text: 'Conf #',
        align: 'start',
        value: 'confirmationNumber',
        sortable: false
      },
      {
        text: props.referenceNumberSettings?.displayName || 'Reference Number',
        value: 'refNumber',
        sortable: false
      },
      { text: 'Start Time', width: 148, value: 'start', sortable: false },
      { text: 'Carrier', value: 'user.company.name', sortable: false },
      { text: 'Dock', value: 'dock.name', sortable: false },
      { text: 'Load Type', value: 'loadType.name', sortable: false },
      { text: 'Status', value: 'status', sortable: false }
    ];

    function getAppointmentStart(startUTC) {
      return formatDateTimeWithMilitarySupport(
        startUTC,
        props.warehouse.timezone,
        LuxonDateTimeFormats.LongDateTimeShortDayShortMonthWithAbbreviatedNamedOffset,
        props.isMilitaryTimeEnabled,
        LuxonDateTimeFormats.LongDateTime24ShortDayShortMonthWithAbbreviatedNamedOffset
      );
    }
    // End of appointments table

    function handleRowClick(item) {
      appointmentToLink.value = item;
      shouldShowConfirmLinkDialog.value = true;
    }

    function closeConfirmLinkDialog() {
      appointmentToLink.value = null;
      shouldShowConfirmLinkDialog.value = false;
    }

    async function fetchAppointments() {
      const now = DateTime.now();
      const query = {
        s: {
          $and: [
            { 'dock.warehouse.id': props.warehouse?.id },
            { status: { $in: [AppointmentStatus.Requested, AppointmentStatus.Scheduled] } },
            {
              start: {
                $between: [now.minus({ days: 1 }).toISO(), now.plus({ days: 1 }).toISO()]
              }
            },
            { type: AppointmentType.Standard },
            { 'assetVisit.id': { $eq: null } }
          ]
        }
      };

      if (searchStr.value) {
        query.s.$and.push({
          $or: searchStringToQuery(searchStr.value, {
            searchableFields: [
              'confirmationNumber',
              'refNumber',
              'user.company.name',
              'dock.name',
              'loadType.name',
              'status'
            ],
            searchFieldsToSplit: []
          })
        });
      }

      const res = await appointmentService.getAppointments(query, {
        includeMetaData: true,
        joins: [
          'dock||name,id',
          'dock.warehouse||id',
          'loadType||name,direction',
          'user||id',
          'user.company||name',
          'assetVisit||id'
        ],
        fields: ['confirmationNumber', 'refNumber', 'start', 'status']
      });

      totalAppointments.value = res?.data?.total;
      appointments.value = res?.data?.data;
    }

    async function linkUnplannedCheckinToAppointment() {
      await checkinService.linkUnplannedCheckinToAppointment({
        appointmentId: appointmentToLink.value.id,
        companyId: appointmentToLink.value.user.company.id,
        assetVisitId: props.assetToLink.id,
        orgId: props.warehouse.orgId,
        isPlanned: true
      });
      await router.replace(`/appointments?appointmentId=${appointmentToLink.value.id}`);
      context.emit('linked', {
        appointmentId: appointmentToLink.value.id,
        id: props.assetToLink.id
      });
      close();
    }

    const debounceSearchStr = debounce(async () => {
      await fetchAppointments();
    }, 350);

    watch(
      () => props.showDialog,
      async value => {
        if (value) {
          await fetchAppointments();
        }
      },
      { immediate: true }
    );

    watch(searchStr, debounceSearchStr);

    return {
      appointmentStartDate,
      appointmentStartTime,
      appointmentToLink,
      appointments,
      close,
      closeConfirmLinkDialog,
      getAppointmentStart,
      handleRowClick,
      headers,
      isRequested,
      isScheduled,
      linkUnplannedCheckinToAppointment,
      renderKey,
      searchStr,
      shouldShowConfirmLinkDialog,
      totalAppointments,
      pluralize
    };
  }
};
</script>

<style scoped lang="scss">
:deep .v-data-table {
}

.container {
  padding-bottom: 0;
  margin-top: 1rem;
}

:deep .v-data-footer {
  background-color: $color-neutral-0;
  position: sticky;
  bottom: -20;
  margin-left: -12px;
  width: calc(100% + 24px);
  justify-content: flex-end;
  padding: 12px 0;
}

:deep td {
  &:hover {
    cursor: pointer;
  }
}

.header-row {
  border-bottom: 1px solid $color-line-border;
  padding: 6px;
}

.block-value {
  display: flex;
  flex-direction: column;

  > span:nth-child(1) {
    font-weight: bold;
  }
}

.value-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
</style>
