<template>
  <div class="text-center">
    <v-stepper
      v-model="currentStep"
      :alt-labels="true"
      :flat="true"
      class="status-stepper"
      :class="{ 'has-time-display': hasTimeDisplay }">
      <v-stepper-header v-if="!compact" :class="`${appointment.status.toLowerCase()}-status`">
        <template v-for="(status, index) in statuses">
          <v-stepper-step
            @click.native="handleStepClick(status)"
            v-show="status.show"
            :step="status.stepNumber"
            :class="getStepClasses(status)"
            :complete-icon="getCompletedIcon(status)"
            :complete="status.stepNumber <= currentStep || currentStep === 0"
            :color="appointment.status.toLowerCase()"
            :alt-labels="true"
            :key="`status-${index}`">
            <div class="text-center">
              <div class="font-weight-medium status-name">
                {{ novaCore.breakWordsAtCaps(status.name) }}
              </div>

              <template v-if="displayTime(status)">
                <div class="time-display-container">
                  <div
                    class="font-size-x-small full-width text-no-wrap"
                    v-if="appointment.statusTimeline[status.name]">
                    {{
                      formatDateTime(
                        appointment.statusTimeline[status.name],
                        novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPMCompact,
                        novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
                      )
                    }}
                  </div>
                  <div
                    class="font-size-x-small font-weight-bold text-capitalize"
                    v-html="timeDiffInfo(status.name)"></div>

                  <template v-if="hasCheckinStatus">
                    <div
                      class="status-info"
                      v-if="status.name === arrivedStatus && appointment.isCheckedInByCarrier">
                      <span>Checked in by carrier</span>
                    </div>
                    <div
                      class="status-info"
                      v-if="status.name === arrivedStatus && isUnplannedAppointment">
                      <span>Unplanned</span>
                    </div>

                    <div
                      class="status-info"
                      v-if="status.name === completedStatus && isAssetDeparted">
                      <span>Checked Out</span>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </v-stepper-step>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <template v-for="(status, index) in statuses">
          <v-stepper-content
            :step="status.stepNumber"
            class="pa-0"
            transition="fab-transition"
            :key="`status-${index}`">
            <v-card class="card-content py-5" elevation="6" outlined v-if="!compact">
              <v-card-text>
                <v-container class="status-button-container">
                  <v-btn-toggle class="button-group">
                    <v-btn
                      height="36"
                      :loading="btnUndo_loading"
                      text
                      v-if="status.canUndo"
                      @click="btnUndo_onClick()">
                      <v-icon class="mr-2 button-icon">mdi-arrow-u-left-top</v-icon>
                      Undo Status
                    </v-btn>

                    <v-btn
                      height="36"
                      :loading="btnNonHappy_loading"
                      text
                      v-if="getNextNonHappyPathStatus(status) && !status.isEndState"
                      @click="btnNonHappy_onClick(status)">
                      {{ getButtonLabel(statusMap[getNextNonHappyPathStatus(status)]) }}
                    </v-btn>

                    <v-btn
                      height="36"
                      :loading="btnHappy_loading"
                      class="status-proceed-button color-secondary-60 text--color-text-inverted"
                      @click="btnHappy_onClick(status)"
                      v-if="shouldShowNextHPButton(status)">
                      {{ getButtonLabel(statusMap[getNextHappyPathStatus(status)]) }}
                      <v-icon class="ml-2 button-icon text--color-text-inverted">
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>

                    <v-btn
                      height="36"
                      outlined
                      data-testid="appointment-details-dialog-trigger-button"
                      v-if="checkForTriggerOnStatus(status.name)"
                      @click="activeTrigger = checkForTriggerOnStatus(status.name)">
                      <v-icon class="mr-2 button-icon">mdi-text-box-edit-outline</v-icon>
                      {{ checkForTriggerOnStatus(status.name).flow.name }}
                    </v-btn>
                  </v-btn-toggle>
                </v-container>
              </v-card-text>
            </v-card>
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>

    <div
      class="pa-4 time-edit-panel"
      :style="statusTimelineEditStyles"
      ref="timeEditPanel"
      :class="[
        { [`edit-${statusToEdit?.toLowerCase()}`]: statusToEdit, 'is-visible': statusToEdit }
      ]">
      <header class="text--color-text-secondary text-center">
        {{ statusTimelineEditHeader }}
      </header>

      <status-timeline-edit-form
        :appointment="appointment"
        :key="`${statusToEdit}-editor`"
        :status-to-edit="statusToEdit"
        @close="statusToEdit = null"></status-timeline-edit-form>
    </div>

    <custom-forms-flow-dialog
      :external-activator="true"
      :warehouse="warehouse"
      :show-dialog="hasActiveTrigger"
      :object-id="appointment.id"
      :trigger="activeTrigger"
      :status-change="pendingStatusChange"
      @close="cleanUpCustomFormsData"
      @update="updateCustomFormsData"
      @create="submitStatusWithCustomFormsData"></custom-forms-flow-dialog>

    <confirm
      :should-show="showUnlinkDialog"
      v-if="showUnlinkDialog"
      is-manual-mode
      persistent
      icon="mdi-alert-circle-outline"
      color="white"
      @result="handleUnlinkResult"
      title="Undo Arrived Status"
      :width="550"
      allow-close
      :dark-theme="false"
      :loading="unlinkLoading"
      button-false-text="unlink check-in"
      @close="showUnlinkDialog = false">
      <template #message>
        <div>This appointment has been self-checked in by the driver.</div>
        <div class="mt-4 mb-6">
          Do you want to
          <strong>unlink this arrival to allow a new check-in,</strong>
          or
          <strong>keep it linked</strong>
          to this appointment?
        </div>
      </template>

      <template #custom-actions="{ choose }">
        <v-spacer />
        <outline-button
          class="my-3"
          @click="choose(true)"
          :loading="unlinkLoading"
          :disabled="unlinkLoading">
          Unlink Check-in
        </outline-button>
        <primary-button class="my-3" @click="choose(false)" :disabled="unlinkLoading">
          Keep Check-in
        </primary-button>
      </template>
    </confirm>
  </div>
</template>

<script>
import etaMixin from '@satellite/components/mixins/etaMixin';
import statusManagerMixin from '@/components/mixins/statusManagerMixin';
import { AssetVisitEventType, AppointmentStatus } from '@satellite/../nova/core';
import { isNull } from 'lodash';

const BTN_HAPPY = 'BTN_HAPPY';
const BTN_NON_HAPPY = 'BTN_NON_HAPPY';
const BTN_UNDO = 'BTN_UNDO';
const defaultCoords = { bottom: 15 }; // Bottom in the context is the element we want to attach the box to the bottom of

/**
 * @displayName Status Manager
 */
export default {
  name: 'StatusManager',
  mixins: [etaMixin, statusManagerMixin],
  props: {
    mixpanelEntryPoint: {
      type: String,
      required: false
    },
    warehouseTriggers: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      lastBtnClicked: null,
      showUnlinkDialog: false,
      unlinkLoading: false,
      coords: {
        [AppointmentStatus.Arrived]: defaultCoords,
        [AppointmentStatus.InProgress]: defaultCoords,
        [AppointmentStatus.Completed]: defaultCoords
      },
      confirmUnlink: null
    };
  },
  computed: {
    statusTimelineEditStyles() {
      const coords = this.coords[this.statusToEdit] ?? defaultCoords;
      const unit = this.statusToEdit ? 'px' : '%';
      const styles = {
        top: (this.coords[this.statusToEdit] ? coords.bottom + 20 : coords.bottom) + unit,
        left: (this.coords[this.statusToEdit] ? coords.left - 5 : null) + unit
      };
      if (this.statusToEdit === AppointmentStatus.Completed) {
        styles.left = coords?.left - 145 + 'px';
      }
      return styles;
    },
    statusTimelineEditHeader() {
      switch (this.statusToEdit) {
        case AppointmentStatus.Arrived:
          return 'When did the carrier arrive?';
        case AppointmentStatus.InProgress:
          return 'When did the loading/unloading start?';
        case AppointmentStatus.Completed:
          return 'When was the appointment completed?';
        default:
          return '';
      }
    },
    hasTimeDisplay() {
      return (
        !isNull(this.appointment.statusTimeline[AppointmentStatus.Arrived]) ||
        !isNull(this.appointment.statusTimeline[AppointmentStatus.InProgress]) ||
        !isNull(this.appointment.statusTimeline[AppointmentStatus.Completed])
      );
    },
    btnHappy_loading: function () {
      return this.loading && this.lastBtnClicked === BTN_HAPPY;
    },
    btnNonHappy_loading: function () {
      return this.loading && this.lastBtnClicked === BTN_NON_HAPPY;
    },
    btnUndo_loading: function () {
      return this.loading && this.lastBtnClicked === BTN_UNDO;
    },
    warehouse() {
      // Used by statusManagerMixin
      return this.appointment?.dock?.warehouse;
    },
    isUnplannedAppointment() {
      return this.appointment?.assetVisit?.isPlanned === false;
    },
    isCarrierCheckin() {
      return this.appointment?.isCheckedInByCarrier;
    },
    isAssetDeparted() {
      return this.appointment?.assetVisit?.assetVisitEvents.some(
        event => event.eventType === AssetVisitEventType.Departed
      );
    },
    hasCheckinStatus() {
      return (
        (this.appointment.status === AppointmentStatus.Arrived ||
          this.appointment.status === AppointmentStatus.InProgress ||
          this.appointment.status === AppointmentStatus.Completed) &&
        (this.isCarrierCheckin || this.isUnplannedAppointment || this.isAssetDeparted)
      );
    }
  },
  methods: {
    async unlinkCheckinFromAppointment() {
      this.unlinkLoading = true;
      try {
        const response = await axios.post('/checkin/unlink-checkin-from-appointment', {
          assetVisitId: this.appointment.assetVisit.id,
          appointmentId: this.appointment.id
        });
        response.data.action = 'update';
        response.data.data = {};
        this.notify('Asset visit has been unlinked from the appointment');
        await this.$store.dispatch('Appointments/triggerSocketEvent', {
          response,
          appointment: this.appointment
        });
      } finally {
        this.unlinkLoading = false;
      }
    },
    async handleUnlinkResult(result) {
      if (result) {
        try {
          await this.unlinkCheckinFromAppointment();
          this.lastBtnClicked = BTN_UNDO;
          this.showUnlinkDialog = false;
        } catch (e) {
          console.log('unlinking issue ', e);
        }
      } else {
        await this.undoLatestStatus();
        this.lastBtnClicked = BTN_UNDO;
        this.showUnlinkDialog = false;
      }
    },
    getStepClasses(status) {
      return {
        'is-requested':
          this.novaCore.isRequested(this.appointment) && status.name === this.requestedStatus,
        'is-inprogress':
          this.novaCore.isInProgress(this.appointment) &&
          status.name === AppointmentStatus.InProgress,
        'is-end-state': status.isEndState,
        'is-editable': this.canEdit(status)
      };
    },
    btnHappy_onClick(status) {
      if (this.loading) {
        return;
      }
      this.updateStatus(this.getNextHappyPathStatus(status));
      this.lastBtnClicked = BTN_HAPPY;
    },
    btnNonHappy_onClick(status) {
      if (this.loading) {
        return;
      }
      this.updateStatus(this.getNextNonHappyPathStatus(status));
      this.lastBtnClicked = BTN_NON_HAPPY;
    },
    async btnUndo_onClick() {
      if (this.loading) {
        return;
      }
      if (
        this.appointment.status === AppointmentStatus.Arrived &&
        this.appointment.assetVisit?.id &&
        this.appointment.isCheckedInByCarrier
      ) {
        this.showUnlinkDialog = true;
      } else {
        this.undoLatestStatus();
        this.lastBtnClicked = BTN_UNDO;
      }
    },
    handleStepClick(status) {
      if (this.canEdit(status)) {
        if (this.statusToEdit && this.statusToEdit === status.name) {
          this.closeEditor();
        } else {
          this.openEditor(status.name);
        }
      }
    },
    addClickAwayEventListener() {
      document.addEventListener('click', this.handleClickAway);
    },
    removeClickAwayEventListener() {
      document.removeEventListener('click', this.handleClickAway);
    },
    handleClickAway(event) {
      const panel = this.$refs.timeEditPanel;
      if (panel && !panel.contains(event.target)) {
        this.statusToEdit = null;
      }
    }
  },
  mounted() {
    this.addClickAwayEventListener();
  },
  destroyed() {
    this.removeClickAwayEventListener();
  },
  watch: {
    statusToEdit(newStatus) {
      if (newStatus) {
        const labels = document.querySelectorAll('.v-stepper__label');
        labels.forEach(label => {
          if (
            Array.from(label.children).some(child =>
              child.textContent.includes(this.novaCore.breakWordsAtCaps(newStatus))
            )
          ) {
            const labelCoords = label.getBoundingClientRect();
            this.coords[newStatus] = labelCoords;
          }
        });
      } else {
        this.coords = {
          [AppointmentStatus.Arrived]: defaultCoords,
          [AppointmentStatus.InProgress]: defaultCoords,
          [AppointmentStatus.Completed]: defaultCoords
        };
      }
    }
  }
};
</script>

<style scoped lang="scss">
.status-stepper {
  border: 1px solid $color-line-border;
  border-radius: 5px;
}

.status-stepper:not(.mobile-stepper) {
  .time-edit-button {
    z-index: 5;
  }
  .v-stepper {
    &__header {
      box-shadow: none;
      margin: auto;
      justify-content: center;
      padding-top: 20px;

      &.requested-status,
      &.scheduled-status {
        .v-stepper__step {
          &--complete {
            &:after {
              background-color: $color-scheduled;
            }
          }
        }
      }

      &.arrived-status {
        .v-stepper__step {
          &--complete {
            &:after {
              background-color: $color-arrived;
            }
          }
        }
      }

      &.inprogress-status {
        .v-stepper__step {
          &--complete {
            &:after {
              background-color: $color-in-progress;
            }
          }
        }
      }

      &.completed-status {
        .v-stepper__step {
          &--complete {
            &:after {
              background-color: $color-completed;
            }
          }
        }
      }

      &.noshow-status {
        .v-stepper__step {
          &--complete {
            &:after {
              background-color: $color-no-show;
            }
          }
        }
      }
    }
    &__step {
      position: relative;
      &:not(:first-child) {
        &:before {
          content: ' ';
          position: absolute;
          width: 86px;
          height: 2px;
          background-color: $color-line-divider;
          transform: translate(-63px, 11px);
        }
      }
    }
  }

  &:not(.compact) {
    .v-stepper__items {
      border: none;
      background-color: transparent !important;
    }

    .v-stepper__content {
      transition: all 1s cubic-bezier(0.68, -0.35, 0.265, 1.25) !important;
    }

    .v-stepper__step {
      flex-basis: 126px !important;

      &.dense {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }

    .v-stepper__step {
      width: 126px;
      padding: 4px 12px !important;
    }

    .v-stepper__step__step,
    .v-stepper__step .v-stepper__label {
      transition: opacity 600ms ease-in-out, color 600ms ease-in-out, background 600ms ease-in-out !important;
    }
  }
}

.v-stepper__step {
  &:after {
    content: ' ';
    position: absolute;
    height: 2px;
    z-index: 1;
    transition: width 1s ease, transform 1s ease, opacity 1s ease;
    transform: translateY(11px);
    opacity: 0;
  }
}

.v-stepper__step--complete {
  &:has(+ .v-stepper__step--complete) {
    &:not(.v-stepper__step--active) {
      &:after {
        opacity: 1;
        width: 86px;
        transform: translate(65px, 11px);
      }
    }
  }
  &:not(.is-end-state) {
    &:after {
      opacity: 1;
      width: 43px;
      transform: translate(100%, 11px);
    }
  }
}

.status-stepper {
  transition: height 1s ease;

  .v-stepper__header {
    min-height: 70px;
    max-height: 70px;
    transition: min-height 1s ease, max-height 1s ease;
  }

  &.has-time-display {
    .v-stepper__header {
      min-height: 104px;
      max-height: 135px;
    }
  }
  .v-stepper {
    &__step--complete {
      i {
        font-size: 16px !important;
      }
    }
  }
  .is-requested {
    .v-stepper__step__step {
      background-color: $color-requested !important;
      border: 1px dashed var(--v-requested-darken3) !important;
    }
    i {
      color: var(--v-scheduled-base) !important;
    }
  }
  .v-stepper__step__step.inprogress {
    i {
      color: $black !important;
      font-size: 20px !important;
    }
  }
  &:not(.compact) {
    .card-content {
      border: none !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

::v-deep .v-stepper__step__step {
  margin-bottom: 4px !important;
  z-index: 2;
}

.button-icon {
  font-size: 16px;
  padding-top: 1px;
}

.status-button-container {
  padding: 20px 0 24px;
}

.button-group .v-btn {
  opacity: 1;
  min-width: 211px !important;
  border-color: #656565 !important;

  &.v-btn--active:before {
    display: none;
  }
  &.v-btn--active {
    &:hover {
      &:before {
        display: block;
        opacity: 0.08;
      }
    }
  }
}

.time-display-container {
  line-height: 16.8px;
}

.v-stepper__step.is-editable {
  &:hover {
    cursor: pointer;
    background-color: $color-background-transparency-inverted;
  }
}

.status-info {
  font-size: 10px;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: $color-neutral-20;
  border-radius: 10px;
  padding: 4px 8px;
  margin-top: 4px;
}

.time-edit-panel {
  position: fixed;
  z-index: 1;
  background-color: white;
  width: 400px;
  box-shadow: 0px 0px 16px rgb(0, 0, 0, 0.2);
  border-radius: 4px;
  transform: translateX(-33%);
  visibility: hidden;
  opacity: 0;
  &.is-visible {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s ease, top 0.1s ease-in, opacity 0.2s ease-in-out;
  }
  &:before {
    content: ' ';
    position: absolute;
    top: -15px;
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 16px solid $white;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
  &:after {
    content: ' ';
    position: absolute;
    top: -18px;
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 18px solid rgb(0, 0, 0, 0.2);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    filter: blur(16px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  &.edit-completed {
    &:after,
    &:before {
      transform: none;
    }
    &:after {
      left: auto;
      right: 49px;
    }
    &:before {
      left: auto;
      right: 50px;
    }
  }

  header {
    font-size: 16px;
  }
}

.v-stepper__label {
  position: relative;
}

::v-deep .time-edit-panel .actions {
  border-top: 1px solid $color-line-divider;
}

@media only screen and (max-width: 1100px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}

@media (max-width: 850px) {
  .v-stepper {
    &:not(.mobile-stepper) {
      .v-divider {
        margin: 22px -67px 0 !important;
      }
      .v-stepper__step:not(.compact) {
        flex-basis: 0 !important;
        flex-grow: 1;
        padding: 10px !important;
      }
    }

    &.status-stepper {
      .v-stepper__label {
        font-size: 0.8rem;
      }
      .caption {
        font-size: 0.7rem !important;
      }
    }
  }
}
</style>
