var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "form", staticClass: "pb-5", attrs: { id: "dock-dialog-form" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6", xs: "12" } },
                [
                  _c("text-field", {
                    staticClass: "mb-0",
                    attrs: {
                      readonly: !_vm.$rolePermissions.canCreateDock,
                      required: true,
                      type: "text",
                      "hide-details": "auto",
                      "prepend-icon": "mdi-warehouse",
                      rules: _vm.$validator.rules.required("Dock Name"),
                      label: "Dock Name",
                    },
                    model: {
                      value: _vm.internalDock.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.internalDock, "name", $$v)
                      },
                      expression: "internalDock.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6", xs: "12" } },
                [
                  _c("text-field", {
                    staticClass: "mb-0",
                    attrs: {
                      readonly: !_vm.$rolePermissions.canCreateDock,
                      dense: "",
                      height: "20px",
                      type: "text",
                      "hide-details": "auto",
                      "prepend-icon": "mdi-garage",
                      label: "Dock Door Number",
                    },
                    model: {
                      value: _vm.internalDock.doorNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.internalDock, "doorNumber", $$v)
                      },
                      expression: "internalDock.doorNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-7" },
            [
              _c(
                "v-col",
                [
                  _c("load-type-select", {
                    attrs: {
                      "select-all": "",
                      loading: _vm.loading,
                      disabled: _vm.loading,
                      readonly: !_vm.$rolePermissions.canCreateDock,
                      "return-object": false,
                      "visible-selection-count": 3,
                      clearable: _vm.$rolePermissions.canCreateDock,
                      required: true,
                      appendCreateLoadTypeOnList: true,
                      selectedWarehouse: _vm.warehouse,
                      rules: _vm.$validator.rules.selectAtLeast(1, "Load Type"),
                      "multi-select": true,
                    },
                    model: {
                      value: _vm.internalDock.loadTypeIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.internalDock, "loadTypeIds", $$v)
                      },
                      expression: "internalDock.loadTypeIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.$rolePermissions.canCreateDock
                    ? _c("v-textarea", {
                        attrs: {
                          "prepend-icon": "mdi-message-bulleted",
                          rows: "2",
                          outlined: "",
                          label: "Instructions",
                        },
                        model: {
                          value: _vm.internalDock.instructions,
                          callback: function ($$v) {
                            _vm.$set(_vm.internalDock, "instructions", $$v)
                          },
                          expression: "internalDock.instructions",
                        },
                      })
                    : _c("div", { staticClass: "v-input v-text-field mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "v-text-field__slot full-width" },
                          [
                            _c(
                              "label",
                              { staticClass: "v-label font-size-x-small" },
                              [_vm._v("Instructions")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text--disabled border-width-1 bordered rounded pa-4 full-width",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.internalDock.instructions) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "mb-2" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-end" },
                  [
                    _c("email-list-field", {
                      staticClass: "full-width mb-4",
                      attrs: {
                        "read-only": !_vm.$rolePermissions.canCreateDock,
                        label: "Default Email Subscribers",
                        "prepend-icon": "mdi-email-outline",
                        tooltipText:
                          "Default Email Subscribers for appointments at this dock.",
                        "small-chips": "",
                        small: "",
                      },
                      model: {
                        value: _vm.internalDock.ccEmails,
                        callback: function ($$v) {
                          _vm.$set(_vm.internalDock, "ccEmails", $$v)
                        },
                        expression: "internalDock.ccEmails",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          false
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("tag-manager", {
                        attrs: {
                          "read-only": !_vm.$rolePermissions.canCreateDock,
                          "hide-details": "",
                          "small-chips": "",
                          small: "",
                          "prepend-icon": "mdi-tag",
                          label: "Dock Tags",
                        },
                        model: {
                          value: _vm.internalDock.tags,
                          callback: function ($$v) {
                            _vm.$set(_vm.internalDock, "tags", $$v)
                          },
                          expression: "internalDock.tags",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h2", { staticClass: "mt-12 mb-2 black--text" }, [
                  _vm._v("Scheduling Portal Availability"),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("carrier-scheduling-toggle", {
                    attrs: {
                      entity: "Dock",
                      disabled: !_vm.$rolePermissions.canCreateDock,
                    },
                    model: {
                      value: _vm.internalDock.allowCarrierScheduling,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.internalDock,
                          "allowCarrierScheduling",
                          $$v
                        )
                      },
                      expression: "internalDock.allowCarrierScheduling",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.internalDock.allowCarrierScheduling
            ? [
                _c("h2", { staticClass: "mt-7" }, [
                  _vm._v("Lead Times - Prevent Carriers From:"),
                ]),
                _c(
                  "v-row",
                  { staticClass: "mt-1" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "disable-label-click" },
                      [
                        _c("v-switch", {
                          staticClass: "mt-1",
                          attrs: {
                            disabled: !_vm.$rolePermissions.canCreateDock,
                            color: "secondary",
                            "hide-details": "auto",
                            dense: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pl-3",
                                        class: {
                                          "text--disabled":
                                            !_vm.enableMinLeadTime,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " Scheduling an appointment within "
                                        ),
                                        _c("text-field", {
                                          ref: "leadtimehours",
                                          staticClass:
                                            "inline-text-field no-spinner text-center",
                                          attrs: {
                                            disabled: !_vm.enableMinLeadTime,
                                            type: "number",
                                          },
                                          model: {
                                            value:
                                              _vm.internalDock
                                                .minCarrierLeadTime_hr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.internalDock,
                                                "minCarrierLeadTime_hr",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "internalDock.minCarrierLeadTime_hr",
                                          },
                                        }),
                                        _c("strong", [_vm._v("hours")]),
                                        _vm._v(" of its start time. "),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1947093426
                          ),
                          model: {
                            value: _vm.enableMinLeadTime,
                            callback: function ($$v) {
                              _vm.enableMinLeadTime = $$v
                            },
                            expression: "enableMinLeadTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mt-0" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "disable-label-click" },
                      [
                        _c("v-switch", {
                          staticClass: "mt-1",
                          attrs: {
                            disabled: !_vm.$rolePermissions.canCreateDock,
                            color: "secondary",
                            "hide-details": "auto",
                            dense: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pl-3",
                                        class: {
                                          "text--disabled":
                                            !_vm.enableMinLeadTimeUpdates,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " Modifying an appointment within "
                                        ),
                                        _c("text-field", {
                                          ref: "leadtimehoursforupdates",
                                          staticClass:
                                            "inline-text-field no-spinner text-center",
                                          attrs: {
                                            disabled:
                                              !_vm.enableMinLeadTimeUpdates,
                                            type: "number",
                                          },
                                          model: {
                                            value:
                                              _vm.internalDock
                                                .minCarrierLeadTimeForUpdates_hr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.internalDock,
                                                "minCarrierLeadTimeForUpdates_hr",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "internalDock.minCarrierLeadTimeForUpdates_hr",
                                          },
                                        }),
                                        _c("strong", [_vm._v("hours")]),
                                        _vm._v(" of its start time. "),
                                        _c(
                                          "span",
                                          { staticClass: "mt-5 d-inline-flex" },
                                          [
                                            _c("help-icon-tooltip", [
                                              _vm._v(
                                                " If disabled, this will default to the above value (" +
                                                  _vm._s(
                                                    _vm.internalDock
                                                      .minCarrierLeadTime_hr
                                                  ) +
                                                  " hours) "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            939535948
                          ),
                          model: {
                            value: _vm.enableMinLeadTimeUpdates,
                            callback: function ($$v) {
                              _vm.enableMinLeadTimeUpdates = $$v
                            },
                            expression: "enableMinLeadTimeUpdates",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mt-0" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "disable-label-click" },
                      [
                        _c("v-switch", {
                          staticClass: "mt-1",
                          attrs: {
                            disabled: !_vm.$rolePermissions.canCreateDock,
                            color: "secondary",
                            dense: "",
                            "hide-details": "auto",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pl-3",
                                        class: {
                                          "text--disabled":
                                            !_vm.enableMaxLeadTime,
                                        },
                                      },
                                      [
                                        _vm._v(" Scheduling more than "),
                                        _c("text-field", {
                                          ref: "maxleadtime",
                                          staticClass:
                                            "inline-text-field no-spinner text-center",
                                          attrs: {
                                            disabled: !_vm.enableMaxLeadTime,
                                            type: "number",
                                          },
                                          model: {
                                            value: _vm.maxLeadTimeInDays,
                                            callback: function ($$v) {
                                              _vm.maxLeadTimeInDays = $$v
                                            },
                                            expression: "maxLeadTimeInDays",
                                          },
                                        }),
                                        _c("strong", [_vm._v("days")]),
                                        _vm._v(" in the future. "),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1595774130
                          ),
                          model: {
                            value: _vm.enableMaxLeadTime,
                            callback: function ($$v) {
                              _vm.enableMaxLeadTime = $$v
                            },
                            expression: "enableMaxLeadTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h2", { staticClass: "mt-5 py-0 black--text" }, [
              _vm._v("Experimental Feature"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0 mb-4",
              attrs: { id: "overbooking-container" },
            },
            [
              _c("v-switch", {
                attrs: {
                  disabled: !_vm.$rolePermissions.canCreateDock,
                  color: "secondary",
                  "hide-details": "auto",
                  dense: "",
                },
                scopedSlots: _vm._u(
                  [
                    _vm.internalDock.capacityNumber > 1 &&
                    _vm.internalDock.allowOverBooking
                      ? {
                          key: "prepend",
                          fn: function () {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { right: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  staticClass: "circular-pulse",
                                                  attrs: { color: "red" },
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " mdi-alert-octagon-outline "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    275959097
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " Overbooking should not be enabled on a dock that has a Parallel Capacity of 2 or more. "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "ml-3",
                              class: {
                                "text--disabled":
                                  !_vm.internalDock.allowOverBooking,
                              },
                            },
                            [
                              _vm._v(" Allow Overbooking "),
                              _c("br"),
                              _c("strong", [_vm._v("Warning:")]),
                              _vm._v(
                                " this allows unlimited appointment overlap for the Warehouse and the Carriers. To revert this, you must make sure there are no overlapping appointments. "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.internalDock.allowOverBooking,
                  callback: function ($$v) {
                    _vm.$set(_vm.internalDock, "allowOverBooking", $$v)
                  },
                  expression: "internalDock.allowOverBooking",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h2", { staticClass: "mt-2 py-0 black--text" }, [
              _vm._v("Dock Schedule"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-btn-toggle",
            {
              staticClass: "secondary-button-group pl-3 mt-4",
              attrs: { mandatory: "", dense: "" },
              model: {
                value: _vm.view,
                callback: function ($$v) {
                  _vm.view = $$v
                },
                expression: "view",
              },
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "div",
                            _vm._g({ staticClass: "d-inline" }, on),
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "rounded-tr-0 rounded-br-0",
                                  attrs: {
                                    elevation: "0",
                                    small: "",
                                    value: _vm.viewTypes.schedule,
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-calendar")]
                                  ),
                                  _vm._v(" Weekly Schedule "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("View the weekly schedule")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "div",
                            _vm._g({ staticClass: "d-inline" }, on),
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    elevation: "0",
                                    small: "",
                                    value: _vm.viewTypes.closedDates,
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-calendar-remove")]
                                  ),
                                  _vm._v(" Closed Dates "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("View the closed dates")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.warehouse.id
        ? [
            _c(
              "v-row",
              {
                class: {
                  "visually-remove": _vm.view === _vm.viewTypes.closedDates,
                },
              },
              [
                _c(
                  "v-col",
                  { staticClass: "mt-2" },
                  [
                    _vm.$rolePermissions.canCreateDock
                      ? [
                          _c("time-select-grid", {
                            ref: "timeSelect",
                            attrs: {
                              "settings-entity": _vm.warehouse,
                              "enabled-intervals": _vm.warehouse.schedule,
                              "toggle-to-edit": false,
                              "local-storage-key": _vm.internalDock.id,
                              compact: "",
                              value: _vm.internalDock.schedule,
                              docks: _vm.warehouse.docks,
                            },
                            on: {
                              "update-interval": (val) => (this.interval = val),
                              "set-schedule-default": _vm.setScheduleToDefault,
                              "set-schedule-closed": _vm.clearDockSchedule,
                              "copy-warehouse-schedule":
                                _vm.copyWarehouseScheduleToDock,
                              "copy-dock-schedule": _vm.copyDockScheduleToDock,
                            },
                          }),
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticClass: "mt-3 mb-4 text-h6 black--text mt-3",
                            },
                            [_vm._v("Hours of Operation")]
                          ),
                          _vm.internalDock.schedule && _vm.warehouse.timezone
                            ? _c("hours-of-operation-list", {
                                attrs: {
                                  schedule: _vm.internalDock.schedule,
                                  timezone: _vm.warehouse.timezone,
                                },
                              })
                            : _vm._e(),
                        ],
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "v-row",
              {
                class: {
                  "visually-remove": _vm.view === _vm.viewTypes.schedule,
                },
              },
              [
                _c(
                  "v-col",
                  { staticClass: "mt-3" },
                  [
                    _c("days-off", {
                      attrs: {
                        schedule: _vm.internalDock.schedule,
                        warehouse: _vm.warehouse,
                      },
                      on: {
                        "update-closed-intervals": _vm.updateClosedIntervals,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.$rolePermissions.canCreateDock
        ? _c("action-group", {
            attrs: {
              loading: _vm.loading,
              "confirm-icon": "upload",
              "confirm-label": "Save Dock",
              "cancel-label": "Nevermind",
            },
            on: {
              cancel: function ($event) {
                return _vm.$emit("close")
              },
              confirm: _vm.submit,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }