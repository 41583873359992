<template>
  <v-autocomplete
    height="20px"
    :class="{ required: field.required }"
    :value="field.value"
    :rules="validationRules"
    :items="field.dropDownValues"
    v-bind="[$props, $attrs]"
    @input="value => $emit('input', value || '')"
    dense
    clearable
    hide-details="auto"
    :placeholder="field.label"
    :persistent-placeholder="persistentPlaceholder ? Boolean(field.placeholder) : false"
    open-on-clear>
    <template #label v-if="displayLabel">Choose an option</template>
  </v-autocomplete>
</template>

<script>
import customFieldTypeMixin from '@satellite/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-dropdown',
  mixins: [customFieldTypeMixin]
};
</script>
