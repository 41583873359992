var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container--fluid mt-4 mx-4" },
    [
      !_vm.showAppointmentFields && !_vm.selectedFeatureTriggerId
        ? [
            _c("h3", { staticClass: "mt-6" }, [_vm._v("Appointment Creation")]),
            _c("p", [
              _vm._v("Manage the form required for appointment creation"),
            ]),
            _c(
              "v-row",
              { staticClass: "my-6" },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "8" } },
                  [
                    _c(
                      "v-expansion-panels",
                      { attrs: { accordion: "" } },
                      [
                        _c(
                          "v-expansion-panel",
                          {
                            attrs: {
                              readonly: "",
                              "data-testid": "custom-fields",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showAppointmentFields = true
                              },
                            },
                          },
                          [
                            _c(
                              "v-expansion-panel-header",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "actions",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                "data-testid":
                                                  "appointment-fields",
                                              },
                                            },
                                            [
                                              _vm._v(" Manage this form "),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("mdi-arrow-right")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2676108894
                                ),
                              },
                              [_c("h4", [_vm._v("Appointment fields")])]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("h3", { staticClass: "mt-6" }, [_vm._v("Gate Management")]),
            _c("p", [
              _vm._v("Manage the forms required for arrivals and departures"),
            ]),
            _c(
              "v-row",
              { staticClass: "my-6" },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "8" } },
                  [
                    _c(
                      "v-expansion-panels",
                      _vm._l(
                        _vm.gateManagementFeatures,
                        function ([_, feature]) {
                          return _c(
                            "v-expansion-panel",
                            { key: feature },
                            [
                              _c("v-expansion-panel-header", [
                                _c(
                                  "h4",
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.featureData(feature).title ||
                                            "Unknown"
                                        ) +
                                        " "
                                    ),
                                    feature !==
                                    _vm.novaCore.CustomFormsFeatures.CheckOut
                                      ? _c(
                                          "v-chip",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "primary",
                                              "x-small": "",
                                            },
                                          },
                                          [_vm._v(" NEW ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("v-divider"),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c("custom-forms-feature", {
                                    attrs: {
                                      warehouse: _vm.warehouse,
                                      feature: feature,
                                      triggers: _vm.featureTriggers(feature),
                                    },
                                    on: { manage: _vm.manageFeatureTrigger },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("h3", { staticClass: "mt-6" }, [_vm._v("Claims Support")]),
            _c(
              "p",
              [
                _vm._v(
                  " Manage forms required for damage and other claim disputes "
                ),
                _c(
                  "v-row",
                  { staticClass: "my-6" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "8" } },
                      [
                        _c(
                          "v-expansion-panels",
                          _vm._l(
                            _vm.claimSupportFeatures,
                            function ([_, feature]) {
                              return _c(
                                "v-expansion-panel",
                                { key: feature },
                                [
                                  _c("v-expansion-panel-header", [
                                    _c("h4", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.featureData(feature).title ||
                                              "Unknown"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c("v-divider"),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c("custom-forms-feature", {
                                        attrs: {
                                          warehouse: _vm.warehouse,
                                          feature: feature,
                                          triggers:
                                            _vm.featureTriggers(feature),
                                        },
                                        on: {
                                          manage: _vm.manageFeatureTrigger,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.showAppointmentFields
        ? [
            _c("appointment-fields", {
              attrs: { warehouse: _vm.warehouse },
              on: { back: () => (_vm.showAppointmentFields = false) },
            }),
          ]
        : _vm._e(),
      _vm.selectedFeatureTrigger?.id
        ? [
            _c("custom-forms-trigger", {
              attrs: {
                warehouse: _vm.warehouse,
                trigger: _vm.selectedFeatureTrigger,
              },
              on: {
                "data-updated": _vm.dataUpdated,
                back: () => (_vm.selectedFeatureTriggerId = null),
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }