<template>
  <div class="chat-container">
    <header class="chat-header px-6 py-4">
      <div
        class="header-left"
        v-if="assetVisit.id && novaCore.hasWarehouseCheckinEnabled(warehouse)">
        Chat with
        <strong :data-testid="makeTestId('phone-number')">
          <v-icon class="ml-1" color="black" small>mdi-cellphone</v-icon>
          {{ assetVisit.phone }}
        </strong>
        <v-btn
          v-if="!isPhoneEditMode"
          :data-testid="makeTestId('edit-phone-btn')"
          text
          small
          @click="enablePhoneEditMode"
          class="edit-phone-btn px-1 pb-1">
          Edit
        </v-btn>
        <div class="d-flex justify-end mt-3" v-if="isPhoneEditMode">
          <phone-number-field
            ref="changePhoneNumberFieldRef"
            id="change-phone-number-field"
            :data-testid="makeTestId('change-driver-phone-field')"
            v-model="newAssetVisitPhone"
            :validator="$validator"
            dense
            outlined
            hide-details
            placeholder="Driver's Phone"
            :label="null"></phone-number-field>
          <outline-button class="ml-2" @click="isPhoneEditMode = false">Nevermind</outline-button>
          <primary-button
            class="ml-2"
            :data-testid="makeTestId('save-driver-phone-btn')"
            :disabled="!newAssetVisitPhone"
            @click="saveNewAssetVisitPhone">
            Save
          </primary-button>
        </div>
      </div>
      <div
        class="header-right"
        v-if="assetVisit.id && novaCore.hasWarehouseCheckinEnabled(warehouse)">
        <div v-if="isThreadExpired">
          <strong class="font-size-xx-small">
            This chat expired
            {{ makeChatTimestamp(messageThread.expiresAt, warehouse) }}
          </strong>
        </div>
        <div
          v-else
          :data-testid="makeTestId('toggle-chat-open-status')"
          @click="handleChatStatusClick"
          class="cursor-pointer">
          <v-icon x-small class="mr-2" color="black">
            mdi-{{ messageThread.isOpen ? 'message-off-outline' : 'message-outline' }}
          </v-icon>
          <strong class="font-size-xx-small">
            {{ messageThread.isOpen ? 'End this chat' : 'Resume this chat' }}
          </strong>
        </div>
      </div>
      <div v-else-if="!novaCore.hasWarehouseCheckinEnabled(warehouse)">
        <div class="font-size-x-small text--color-neutral-80">
          <v-icon class="mr-2">mdi-information-outline</v-icon>
          The Check-In feature must be enabled for this warehouse to send SMS messages.
        </div>
      </div>
      <div v-else>
        <div class="font-size-x-small text--color-neutral-80">
          <v-icon class="mr-2">mdi-information-outline</v-icon>
          Wait for the driver's check-in to enable the chat.
        </div>
      </div>
    </header>
    <div
      class="chat-stage pa-5"
      ref="chatStageRef"
      @scroll="setStagePositionValues"
      :data-testid="makeTestId('chat-stage')">
      <div class="text-center font-size-xx-small text--color-neutral-80" v-if="assetVisit.id">
        The driver will receive and respond to your messages via SMS at the phone number provided
        during check-in.
      </div>
      <div v-for="(message, idx) in messageThread.messages" :key="message.id">
        <drivers-chat-message
          :message="message"
          :idx="idx"
          :new-message-count="newMessageCount"
          :message-thread="messageThread"
          :assetVisit="assetVisit"></drivers-chat-message>
        <div
          v-for="event in makeNextEventItems(message)"
          :key="event.createDateTime"
          class="text-center font-size-xx-small text--color-neutral-80 mt-3 event-message">
          <strong>{{ makeChatTimestamp(event.createDateTime, warehouse) }}</strong>
          <br />
          {{ event.content }}
        </div>
      </div>
      <div
        v-if="isThreadExpired"
        class="text-center font-size-xx-small text--color-neutral-80 mt-3 event-message">
        <strong class="d-block">
          {{ makeChatTimestamp(messageThread.expiresAt, warehouse) }}
        </strong>
        This chat expired. You can no longer send messages.
      </div>
    </div>
    <footer v-if="messageThread.isOpen" class="chat-footer px-6 py-5">
      <v-btn
        v-if="newMessageCount && !isChatNearBottom"
        :data-testid="makeTestId('scroll-to-new-messages-btn')"
        text
        small
        class="new-messages-scroll-btn"
        @click="scrollToLatestMsg()">
        New Messages
        <v-icon small>mdi-arrow-down</v-icon>
      </v-btn>
      <div id="new-message-field">
        <v-form ref="newMessageFormRef" class="pt-0">
          <v-textarea
            v-model="newMessageInputValue"
            row-height="24"
            rows="1"
            auto-grow
            validate-on-blur
            outlined
            no-resize
            :data-testid="makeTestId('new-message-field')"
            class="mt-0"
            :counter="maxMessageLength"
            :rules="newMessageRulesRef"
            :disabled="!assetVisit || isThreadExpired"
            @keydown.enter.exact.prevent="handleMessageSubmit"
            dense
            :placeholder="isThreadExpired ? 'Message thread expired' : 'Message the driver...'">
            <template #prepend-inner>
              <v-img
                ref="attachedFileThumbRef"
                :data-testid="makeTestId('attached-file-preview')"
                v-if="attachedFile && attachedImageThumbSrc"
                id="attached-file"
                max-height="60"
                aspect-ratio="1"
                max-width="60"
                class="mt-1"
                :src="attachedImageThumbSrc"
                width="60">
                <v-icon
                  class="remove-attachment-icon"
                  @click="removeAttachedFile"
                  :data-testid="makeTestId('remove-file-icon')">
                  mdi-close-circle
                </v-icon>
              </v-img>
              <div
                v-else-if="attachedFile"
                class="attached-file-box pa-2 font-size-xx-small mt-1"
                :data-testid="makeTestId('attached-file-preview')">
                <div>
                  <generic-text-icon
                    :text="attachedFile.name.split('.').pop()"
                    class="py-2 px-1 mr-1"></generic-text-icon>
                </div>
                <div>
                  <div class="font-weight-black file-box-name mb-2">
                    {{ novaCore.truncateString(attachedFile.name, 16) }}
                  </div>
                  <div class="file-box-size">{{ Math.round(attachedFile.size / 1000) }}kb</div>
                </div>
                <v-icon
                  class="remove-attachment-icon"
                  @click="removeAttachedFile"
                  :data-testid="makeTestId('remove-file-icon')">
                  mdi-close-circle
                </v-icon>
              </div>
            </template>
            <template #append>
              <v-icon :data-testid="makeTestId('attach-file-icon')" @click="fileInputRef.click()">
                mdi-paperclip
              </v-icon>
            </template>
          </v-textarea>
        </v-form>
        <input
          type="file"
          ref="fileInputRef"
          class="d-none"
          :data-testid="makeTestId('file-input')"
          :accept="allowedFileTypes.join(',')"
          @change="handleFileChange" />
      </div>
      <v-btn
        fab
        text
        small
        :data-testid="makeTestId('send-message-btn')"
        :disabled="!newMessageInputValue && !attachedFile"
        :loading="sendingMessage"
        id="message-send-btn"
        class="ml-4"
        @click="handleMessageSubmit">
        <v-icon small>mdi-send</v-icon>
      </v-btn>
    </footer>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, watch, onBeforeUnmount } from 'vue';
import { useAssetVisit, useEventHub, useMessageThread, useNovaCore, useStore } from '@/composables';

export default {
  props: {
    assetVisit: {
      type: Object,
      required: true
    },
    messageThread: {
      type: Object,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const assetVisit = ref(props.assetVisit);
    const {
      isIncomingMessage,
      postLastReadMessage,
      isLastReadMessage,
      getIncomingMessages,
      sendMessage,
      makeChatTimestamp,
      isOutgoingMessage,
      toggleThreadOpenStatus
    } = useMessageThread(assetVisit.value);
    const { updateAssetVisit } = useAssetVisit(assetVisit.value);
    const newMessageInputValue = ref('');
    const store = useStore();
    const novaCore = useNovaCore();
    const eventHub = useEventHub();
    const newMessageCount = ref(0);
    const chatStageRef = ref(null);
    const isChatNearBottom = ref(false);
    const isPhoneEditMode = ref(false);
    const newAssetVisitPhone = ref(null);
    const changePhoneNumberFieldRef = ref(null);
    const fileInputRef = ref(null);
    const attachedFile = ref(null);
    const attachedFileThumbRef = ref(null);
    const attachedImageThumbSrc = ref(null);
    const allowedFileTypes = Object.values(novaCore.AllowedMessageThreadMediaMimeTypes);
    const sendingMessage = ref(false);
    const newMessageRulesRef = ref([
      v => v.length <= maxMessageLength || `Max ${maxMessageLength} characters`
    ]);
    const newMessageFormRef = ref(null);
    const maxMessageLength = novaCore.GlobalLimits.MAX_SMS_LENGTH.value;
    const isThreadExpired = ref(novaCore.isMessageThreadExpired(props.messageThread));
    const messagesWithEvents = ref([]);
    const formResizeObserver = new ResizeObserver(() => {
      if (isChatNearBottom.value) {
        scrollToLatestMsg('auto');
      }
    });

    onMounted(async () => {
      if (props.messageThread.messages?.length) {
        makeMessagesWithEvents();
        await scrollToLatestMsg('auto');
        await setLastReadMessage();
      }
    });

    onBeforeUnmount(() => {
      formResizeObserver.disconnect();
    });

    const setLastReadMessage = async () => {
      if (!props.messageThread?.id) {
        return;
      }
      const incomingMessages = getIncomingMessages(props.messageThread.messages);
      if (!incomingMessages?.length) {
        return;
      }
      const lastMessage = incomingMessages[incomingMessages.length - 1];
      if (
        !isLastReadMessage(lastMessage, props.messageThread, props.messageThread.lastReadMessageId)
      ) {
        postLastReadMessage(props.messageThread, lastMessage.id);
      }
    };

    const scrollToLatestMsg = async (behavior = 'smooth') => {
      const chatStage = chatStageRef.value;
      if (!chatStage) {
        return;
      }
      await nextTick();
      chatStage.scroll({
        top: chatStage.scrollHeight,
        behavior: behavior
      });
    };

    const enablePhoneEditMode = async () => {
      isPhoneEditMode.value = true;
      await nextTick();
      if (changePhoneNumberFieldRef.value?.$refs?.telInput) {
        changePhoneNumberFieldRef.value.$refs.telInput.focus();
      }
    };

    const setStagePositionValues = () => {
      const scrollOffset =
        chatStageRef.value.scrollTop -
        (chatStageRef.value.scrollHeight - chatStageRef.value.offsetHeight);
      isChatNearBottom.value = scrollOffset > -500;
    };

    const makeMessagesWithEvents = () => {
      const nonReactiveMessages = JSON.parse(JSON.stringify(props.messageThread.messages));
      const nonReactiveEvents = JSON.parse(JSON.stringify(props.messageThread.events));
      const combinedMessages = [...nonReactiveMessages, ...nonReactiveEvents];
      messagesWithEvents.value = combinedMessages.sort(
        (a, b) => new Date(a.createDateTime) - new Date(b.createDateTime)
      );
    };

    watch(
      () => props.messageThread,
      async (newMessageThread, oldMessageThread) => {
        isThreadExpired.value = novaCore.isMessageThreadExpired(props.messageThread);
        const oldThreadExists = oldMessageThread?.id;
        const hasNewEvents =
          oldThreadExists && newMessageThread.events?.length > oldMessageThread.events?.length;
        const hasNewMessages =
          oldThreadExists && newMessageThread.messages?.length > oldMessageThread.messages?.length;
        if (!oldThreadExists || hasNewEvents || hasNewMessages) {
          makeMessagesWithEvents();
        }
        if (!oldThreadExists) {
          await setLastReadMessage();
          await scrollToLatestMsg('auto');
        }
        if (oldThreadExists && hasNewMessages) {
          sendingMessage.value = false;
          await nextTick();
          setStagePositionValues();
          const latestMessage =
            props.messageThread.messages[props.messageThread.messages.length - 1];
          if (isIncomingMessage(latestMessage)) {
            newMessageCount.value++;
          }
          if (isOutgoingMessage(latestMessage)) {
            await scrollToLatestMsg();
          }
        }
        if (isChatNearBottom.value) {
          await scrollToLatestMsg();
        }
      },
      { deep: true }
    );

    const handleMessageSubmit = async () => {
      if (!newMessageFormRef.value.validate()) {
        return;
      }
      const prevMsgContent = newMessageInputValue.value;
      sendingMessage.value = true;
      const formData = new FormData();
      if (newMessageInputValue.value) {
        formData.append('content', newMessageInputValue.value);
      }
      formData.append('file', attachedFile.value);
      if (!newMessageInputValue.value && !attachedFile.value) {
        return;
      }
      newMessageInputValue.value = '';
      sendMessage(props.messageThread, formData)
        .catch(() => {
          newMessageInputValue.value = prevMsgContent;
        })
        .finally(() => {
          sendingMessage.value = false;
        });
      await setLastReadMessage();
      removeAttachedFile();
      newMessageCount.value = 0;
    };

    const removeAttachedFile = () => {
      attachedFile.value = null;
      attachedImageThumbSrc.value = null;
      fileInputRef.value.value = '';
      fileInputRef.value.files = null;
    };

    const handleFileChange = async event => {
      const files = event.target.files;
      if (files.length > 0) {
        attachedFile.value = files[0];
        await nextTick();
        if (attachedFile.value.type.includes('image')) {
          attachedImageThumbSrc.value = URL.createObjectURL(files[0]);
        }
      }
    };

    const saveNewAssetVisitPhone = async () => {
      await updateAssetVisit(assetVisit.value.id, {
        phone: newAssetVisitPhone.value
      });
      assetVisit.value.phone = newAssetVisitPhone.value;
      isPhoneEditMode.value = false;
      eventHub.$emit('refresh-MessageThread');
    };

    const handleChatStatusClick = async () => {
      const text = props.messageThread.isOpen
        ? "This chat will become read-only, and you'll no longer be able to send or receive messages."
        : 'This chat will be reactivated, allowing you to send and receive messages again.';
      const isConfirmed = await store.$app.$confirm(text, { color: 'warning' });
      if (isConfirmed) {
        await toggleThreadOpenStatus(props.messageThread);
      }
    };

    const makeNextEventItems = (item, events = []) => {
      const itemIdx = messagesWithEvents.value.findIndex(
        i => i.createDateTime === item.createDateTime && i.content === item.content
      );
      if (itemIdx === -1 || itemIdx + 1 >= messagesWithEvents.value.length) {
        return events.length > 0 ? events : [];
      }

      const nextItem = messagesWithEvents.value[itemIdx + 1];
      if (nextItem && !nextItem.fromNumber && !events.includes(nextItem)) {
        events.push(nextItem);
        return makeNextEventItems(nextItem, events);
      } else {
        return events.length > 0 ? events : [];
      }
    };

    const makeTestId = (testId, prefix = 'drivers-chat') => {
      return `${prefix}-${testId}`;
    };

    watch(newMessageFormRef, () => {
      formResizeObserver.observe(newMessageFormRef.value.$el);
    });

    return {
      newMessageInputValue,
      handleMessageSubmit,
      newMessageCount,
      chatStageRef,
      isChatNearBottom,
      scrollToLatestMsg,
      setStagePositionValues,
      isPhoneEditMode,
      newAssetVisitPhone,
      enablePhoneEditMode,
      changePhoneNumberFieldRef,
      saveNewAssetVisitPhone,
      handleFileChange,
      fileInputRef,
      attachedFile,
      attachedFileThumbRef,
      attachedImageThumbSrc,
      removeAttachedFile,
      allowedFileTypes,
      toggleThreadOpenStatus,
      sendingMessage,
      newMessageRulesRef,
      newMessageFormRef,
      maxMessageLength,
      isThreadExpired,
      makeChatTimestamp,
      novaCore,
      messagesWithEvents,
      makeNextEventItems,
      makeTestId,
      handleChatStatusClick
    };
  }
};
</script>

<style scoped lang="scss">
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.chat-header {
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  background-color: $white;
  border-bottom: 1px solid $color-neutral-20;

  .edit-phone-btn {
    color: $color-text-link;
    text-transform: none;
    min-width: auto;
  }
}

.chat-stage {
  flex: 1 1 auto;
  overflow-y: auto;
  align-content: end;
  background-color: $color-neutral-10;
}

.chat-footer {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  position: relative;
  align-items: flex-end;
  background-color: #ffffff;
  border-top: 1px solid $color-neutral-20;
}

#message-send-btn {
  background-color: $color-neutral-0;
  color: $color-neutral-90;

  &:disabled {
    background-color: $color-neutral-20;
  }
}

::v-deep #new-message-field {
  flex: 1;

  .v-input__slot {
    flex-direction: column;
  }

  #attached-file {
    border-radius: 12px;
    overflow: visible;

    .v-image__image {
      border-radius: 8px;
    }
  }

  .v-input__append-inner {
    position: absolute;
    right: 10px;
    bottom: 8px;
  }

  textarea {
    padding-right: 35px;
  }

  .v-text-field__details {
    position: absolute;
    right: 0;
    bottom: -20px;
  }
}

.remove-attachment-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  color: black;
  z-index: 1;
}

.attached-file-box {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 12px;
  background-color: $color-neutral-20;
  max-height: 60px;
  min-width: 60px;
  max-width: 144px;
  color: $color-neutral-90;
}

.new-messages-scroll-btn {
  position: absolute;
  left: 50%;
  top: -15px;
  transform: translateX(-50%);
  z-index: 1;
  background-color: $color-primary-60;
}

::v-deep #change-phone-number-field {
  max-width: 185px;
  max-height: 36px;
  border: solid 1px rgba(146, 146, 146, 0.7);
  border-radius: 4px;

  > .v-input {
    border-radius: 0 4px 4px 0;

    fieldset {
      border: none;
    }

    input {
      padding-top: 11px;
    }
  }

  .v-text-field__details {
    display: none !important;
  }

  .country-code {
    width: auto;
    position: relative;
    max-width: 30px;

    fieldset {
      border: none;
    }

    .v-input {
      width: 100%;
      min-width: 100%;
      position: relative;
      border-radius: 4px 0 0 4px;
    }

    .v-input__slot {
      margin-bottom: 0;
    }
  }
}
</style>
