var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.showDockForm && !_vm.showChildDockForm
        ? _c(
            "v-card",
            { class: { disabled: _vm.readOnly } },
            [
              _c(
                "v-card-title",
                { staticClass: "mb-5" },
                [
                  _c(
                    "v-row",
                    { staticClass: "search-field", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-4", attrs: { md: "6" } },
                        [
                          _c("text-field", {
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: "Search",
                              "single-line": "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.searchDockStr,
                              callback: function ($$v) {
                                _vm.searchDockStr = $$v
                              },
                              expression: "searchDockStr",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-switch", {
                        attrs: { inset: "", label: "Show inactive docks" },
                        model: {
                          value: _vm.showInactiveDocks,
                          callback: function ($$v) {
                            _vm.showInactiveDocks = $$v
                          },
                          expression: "showInactiveDocks",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        disabled:
                          _vm.$rolePermissions.canCreateDock && !_vm.readOnly,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "div",
                                  _vm._g(_vm._b({}, "div", attrs, false), on),
                                  [
                                    _c(
                                      "primary-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          disabled:
                                            !_vm.$rolePermissions
                                              .canCreateDock || _vm.readOnly,
                                          "before-icon": "plus",
                                          large: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleDockForm()
                                          },
                                        },
                                      },
                                      [_vm._v(" Create Dock ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2906579631
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.novaCore.getRoleActionError())),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    staticClass: "grouped-list-items",
                    class: { disabled: _vm.readOnly },
                    attrs: {
                      headers: _vm.headers,
                      id: "dock-table",
                      items: _vm.parentDocks,
                      loading: _vm.$data.$globalLoading,
                      "footer-props": _vm.footerProps,
                      "sort-by": _vm.sortBy,
                      "custom-sort": _vm.sort,
                      "sort-desc": _vm.sortDesc,
                      expanded: _vm.expanded,
                      options: _vm.options,
                    },
                    on: {
                      "update:sortBy": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header.sort",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "d-inline-block mr-1" },
                                    [_vm._v("Sort")]
                                  ),
                                  _c("help-icon-tooltip", [
                                    _vm._v(
                                      " The order of how this data displays across the platform. "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "header.capacityNumber",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "d-inline-block mr-1" },
                                    [_vm._v("Parallel Capacity")]
                                  ),
                                  _c("help-icon-tooltip", [
                                    _vm._v(
                                      " Number of concurrent appointments that can be booked at this Dock at the same time interval. "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " E.g. if this number is 3, you can book up to 3 appointments from 9am to 10am (assuming the Dock's schedule is open at that time). "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "item.name",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  item.capacityChildren.length > 0
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "mr-2" },
                                          [
                                            _c("icon-tooltip-button", {
                                              attrs: {
                                                size: "24",
                                                tooltip: "Show Capacity Docks",
                                                icon: _vm.expanded.includes(
                                                  item
                                                )
                                                  ? "chevron-up"
                                                  : "chevron-down",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleRowExpansion(
                                                    item
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  _c(
                                    "copy-content",
                                    {
                                      attrs: {
                                        disabled: !item.isActive,
                                        content: item.name,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  ),
                                ],
                                2
                              ),
                            ]
                          },
                        },
                        _vm.$rolePermissions.canUpdateWarehouse
                          ? {
                              key: "item.sort",
                              fn: function ({ item }) {
                                return [
                                  !_vm.loading
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "handle",
                                          attrs: {
                                            disabled: _vm.readOnly,
                                            color: "black",
                                          },
                                        },
                                        [_vm._v(" mdi-arrow-split-horizontal ")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            }
                          : null,
                        {
                          key: "item.doorNumber",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " + _vm._s(item.doorNumber || "----") + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.loadTypeIds",
                          fn: function ({ item }) {
                            return [
                              _c("load-type-chip-list", {
                                attrs: {
                                  "read-only": _vm.readOnly || !item.isActive,
                                  "load-type-id-array": item.loadTypeIds,
                                },
                              }),
                            ]
                          },
                        },
                        {
                          key: "item.allowCarrierScheduling",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !item.isActive
                                      ? "-"
                                      : !item.allowCarrierScheduling
                                      ? "Yes"
                                      : "No"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.capacityNumber",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("span", { staticClass: "self-stretch" }, [
                                    _vm._v(_vm._s(item.capacityNumber)),
                                  ]),
                                  !_vm.readOnly
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "secondary--text font-weight-bold ml-3",
                                          attrs: {
                                            disabled:
                                              _vm.readOnly || !item.isActive,
                                            text: "",
                                            small: "",
                                            loading:
                                              _vm.addingCapacity[item.id],
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.createCapacityDock(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Add ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.actions",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "row-actions" },
                                [
                                  !_vm.$rolePermissions.canCreateDock &&
                                  !_vm.isCapacityChild(item) &&
                                  item.isActive
                                    ? _c("icon-tooltip-button", {
                                        attrs: {
                                          disabled:
                                            _vm.$rolePermissions
                                              .canCreateDock ||
                                            _vm.isCapacityChild(item) ||
                                            _vm.readOnly,
                                          "icon-class": "mr-2",
                                          size: "large",
                                          tooltip: _vm.readOnly
                                            ? _vm.novaCore.getRoleActionError(
                                                "view this dock"
                                              )
                                            : "View Dock",
                                          icon: "eye",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleDockForm(item)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.$rolePermissions.canCreateDock &&
                                  !_vm.isCapacityChild(item) &&
                                  item.isActive
                                    ? _c("icon-tooltip-button", {
                                        attrs: {
                                          disabled:
                                            !_vm.$rolePermissions
                                              .canCreateDock ||
                                            _vm.isCapacityChild(item) ||
                                            _vm.readOnly,
                                          id: item.id,
                                          size: "large",
                                          "icon-class": "mr-2",
                                          color: "secondary",
                                          tooltip: _vm.readOnly
                                            ? _vm.novaCore.getRoleActionError(
                                                "edit this dock"
                                              )
                                            : "Edit Dock",
                                          icon: "pencil",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleDockForm(item)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _c("audit-log-entity-icon-button", {
                                    attrs: {
                                      item: item,
                                      timezone: _vm.warehouse.timezone,
                                      "entity-type":
                                        _vm.novaCore.DatabaseEntities.Dock,
                                    },
                                  }),
                                  item.isActive
                                    ? _c("create-appointment-dialog", {
                                        attrs: {
                                          "read-only":
                                            !_vm.$rolePermissions
                                              .canCreateDock || _vm.readOnly,
                                          context: {
                                            selectedWarehouse: _vm.warehouse,
                                            selectedDock: item,
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (slotProps) {
                                                return [
                                                  _c(
                                                    "icon-tooltip-button",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.readOnly,
                                                            tooltip:
                                                              _vm.readOnly
                                                                ? _vm.novaCore.getRoleActionError(
                                                                    "schedule an appointment at this dock"
                                                                  )
                                                                : "Schedule an appointment at this dock",
                                                            size: "large",
                                                            "icon-class":
                                                              "mr-2",
                                                            color: "primary",
                                                            icon: "calendar",
                                                          },
                                                        },
                                                        "icon-tooltip-button",
                                                        slotProps.attrs,
                                                        false
                                                      ),
                                                      slotProps.on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                  item.isActive
                                    ? _c("clone-dock-dialog", {
                                        attrs: {
                                          "original-dock": item,
                                          "clone-sort-order":
                                            _vm.nextItemSortOrder,
                                        },
                                        on: { saved: _vm.getData },
                                        nativeOn: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            $event.stopPropagation()
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (slotProps) {
                                                return [
                                                  _c(
                                                    "icon-tooltip-button",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm
                                                                .$rolePermissions
                                                                .canCreateDock ||
                                                              _vm.isCapacityChild(
                                                                item
                                                              ) ||
                                                              _vm.readOnly,
                                                            tooltip:
                                                              "Clone Dock",
                                                            color: "secondary",
                                                            icon: "mdi-content-copy",
                                                            "icon-class":
                                                              "mr-2",
                                                            size: "large",
                                                          },
                                                          on: {
                                                            saved:
                                                              _vm.handleDockSave,
                                                          },
                                                        },
                                                        "icon-tooltip-button",
                                                        slotProps.attrs,
                                                        false
                                                      ),
                                                      slotProps.on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm.$rolePermissions.canDeleteDock
                                    ? _c(
                                        "v-menu",
                                        {
                                          ref: `contextMenu-${item.id}`,
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              size: "24",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " mdi-dots-horizontal "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            { attrs: { dense: "" } },
                                            [
                                              _vm.novaCore.isNormalDock(item)
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass: "px-4",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.$rolePermissions
                                                            .canCreateDock ||
                                                          _vm.isCapacityChild(
                                                            item
                                                          ) ||
                                                          _vm.readOnly,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showDeleteDockConfirmation(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-icon",
                                                        { staticClass: "mr-4" },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "red",
                                                                size: "20px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-delete-outline"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "red--text text--lighten-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Delete dock "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.novaCore.isNormalDock(item)
                                                ? _c("v-divider", {
                                                    staticClass: "my-2",
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "v-subheader",
                                                {
                                                  staticClass:
                                                    "px-4 d-flex flex-column text-left align-start",
                                                },
                                                [
                                                  _c("div", [
                                                    _c("strong", [
                                                      _vm._v("DOCK STATUS"),
                                                    ]),
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      "(Toggle off to deactivate)"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass: "px-4",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleDockActiveStatus(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    { staticClass: "mr-4" },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: item.isActive
                                                              ? "orange"
                                                              : "gray",
                                                            size: "20px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.isActive
                                                                  ? "mdi-toggle-switch"
                                                                  : "mdi-toggle-switch-off-outline"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.isActive
                                                            ? "Active"
                                                            : "Inactive"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        _vm.headers && _vm.headers.length
                          ? {
                              key: "expanded-item",
                              fn: function ({ headers, item }) {
                                return [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "child-capacity-dock",
                                      attrs: { colspan: headers.length },
                                    },
                                    [
                                      _c("v-data-table", {
                                        staticClass: "nested-table",
                                        attrs: {
                                          headers: _vm.capacityDockHeaders,
                                          items: _vm.novaCore
                                            .sortCapacityChildren(item)
                                            .filter(
                                              (dock) => dock.id !== item.id
                                            ),
                                          "disable-pagination": "",
                                          "hide-default-header": "",
                                          "hide-default-footer": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item.name",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center",
                                                          class: {
                                                            "text--dark-grey":
                                                              _vm.getAppointmentCount(
                                                                item.id
                                                              ) === 0,
                                                            "black--text":
                                                              _vm.getAppointmentCount(
                                                                item.id
                                                              ) > 0,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-file-tree"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm.getAppointmentCount(
                                                            item.id
                                                          ) > 0
                                                            ? _c(
                                                                "strong",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getAppointmentCount(
                                                                          item.id
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                },
                                                                [_vm._v("No")]
                                                              ),
                                                          _vm.getAppointmentCount(
                                                            item.id
                                                          ) > 1
                                                            ? _c(
                                                                "strong",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " appointments "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "appointment"
                                                                  ),
                                                                ]
                                                              ),
                                                          _vm._v(
                                                            " using this Parallel Capacity "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.actions",
                                              fn: function ({ item }) {
                                                return [
                                                  _vm.$rolePermissions
                                                    .canUpdateDock &&
                                                  !_vm.readOnly
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center justify-end",
                                                        },
                                                        [
                                                          item.isActive &&
                                                          _vm.$rolePermissions
                                                            .canCreateDock
                                                            ? _c(
                                                                "icon-tooltip-button",
                                                                {
                                                                  staticClass:
                                                                    "mr-2 d-inline-block",
                                                                  attrs: {
                                                                    id: item.id,
                                                                    disabled:
                                                                      !_vm
                                                                        .$rolePermissions
                                                                        .canDeleteDock ||
                                                                      _vm.readOnly,
                                                                    tooltip:
                                                                      _vm.readOnly
                                                                        ? _vm.novaCore.getRoleActionError()
                                                                        : "Edit Capacity Dock",
                                                                    icon: "mdi-pencil",
                                                                    size: "large",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.toggleChildDockForm(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          item.isActive &&
                                                          _vm.getAppointmentCount(
                                                            item.id
                                                          ) > 0
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "secondary--text d-inline-block",
                                                                  attrs: {
                                                                    text: "",
                                                                    small: "",
                                                                    outlined:
                                                                      "",
                                                                    disabled:
                                                                      !_vm
                                                                        .$rolePermissions
                                                                        .canCreateDock ||
                                                                      !_vm.isCapacityChild(
                                                                        item
                                                                      ) ||
                                                                      _vm.readOnly,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showUnlinkCapacityDockDialog(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-link-off"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "d-inline-block ml-2 text-capitalize",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Unlink"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : item.isActive
                                                            ? _c(
                                                                "icon-tooltip-button",
                                                                {
                                                                  staticClass:
                                                                    "mr-2 d-inline-block",
                                                                  attrs: {
                                                                    color:
                                                                      "error",
                                                                    id: item.id,
                                                                    disabled:
                                                                      !_vm
                                                                        .$rolePermissions
                                                                        .canDeleteDock ||
                                                                      !_vm.isCapacityChild(
                                                                        item
                                                                      ) ||
                                                                      _vm.readOnly,
                                                                    tooltip:
                                                                      _vm.readOnly
                                                                        ? _vm.novaCore.getRoleActionError()
                                                                        : "Delete Capacity Dock",
                                                                    icon: "mdi-delete-outline",
                                                                    size: "large",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteCapacityDock(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm.showDockForm
                    ? [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.editingDock?.name
                                ? "Update Dock"
                                : "Create Dock"
                            ) +
                            " "
                        ),
                      ]
                    : [_vm._v("Update Capacity Dock")],
                  _c("v-spacer"),
                  _vm.showDockForm
                    ? _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.toggleDockForm()
                            },
                          },
                        },
                        [_vm._v("mdi-close")]
                      )
                    : _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.toggleChildDockForm()
                            },
                          },
                        },
                        [_vm._v("mdi-close")]
                      ),
                ],
                2
              ),
              _c(
                "v-card-text",
                [
                  _vm.showDockForm
                    ? _c("dock-form", {
                        attrs: {
                          dock: _vm.editingDock,
                          "sort-order": _vm.nextItemSortOrder,
                          "warehouse-id": _vm.warehouseId,
                        },
                        on: {
                          close: _vm.toggleDockForm,
                          saved: _vm.handleDockSave,
                        },
                      })
                    : _c("capacity-dock-form", {
                        attrs: {
                          dock: _vm.editingDock,
                          "sort-order": _vm.nextItemSortOrder,
                          "warehouse-id": _vm.warehouseId,
                        },
                        on: {
                          close: _vm.toggleChildDockForm,
                          saved: _vm.handleChildDockSave,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.showUnlinkDialog,
            callback: function ($$v) {
              _vm.showUnlinkDialog = $$v
            },
            expression: "showUnlinkDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialog-box" },
            [
              _c("dialog-header", {
                attrs: {
                  "prepend-header-icon": "mdi-alert",
                  header: "Unlink Parallel Capacity?",
                },
                on: { close: _vm.closeUnlinkDialog },
              }),
              _c(
                "v-card-text",
                { staticClass: "px-8 pb-6 pt-2" },
                [
                  _vm.dialogLoading
                    ? [
                        _c("v-progress-linear", {
                          staticClass: "mt-12",
                          attrs: { indeterminate: "", height: "6" },
                        }),
                        _c("h4", { staticClass: "text-center mt-4" }, [
                          _vm._v("Unlinking Capacity Dock ..."),
                        ]),
                      ]
                    : _c(
                        "div",
                        [
                          _c("p", [
                            _vm._v(
                              " Unlinking will create a new dock and move all existing parallel appointments over to it. This ensures that no appointments are lost or deleted. "
                            ),
                          ]),
                          _vm.dockToUnlink
                            ? _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        ripple: false,
                                        color: "dark-grey",
                                        label: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "black--text font-size-x-small",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getAppointmentCount(
                                                  _vm.dockToUnlink.id
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" appointments "),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mx-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-arrow-right-thin")]
                                  ),
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        ripple: false,
                                        color: "dark-grey",
                                        label: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "black--text font-size-x-small",
                                        },
                                        [_vm._v("New dock")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-form",
                            { ref: "newUnlinkedDockName" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: "New dock name",
                                  label: "New dock name",
                                  required: "",
                                  rules:
                                    _vm.$validator.rules.required(
                                      "New Dock Name"
                                    ),
                                },
                                model: {
                                  value: _vm.newUnlinkedDockName,
                                  callback: function ($$v) {
                                    _vm.newUnlinkedDockName = $$v
                                  },
                                  expression: "newUnlinkedDockName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                2
              ),
              !_vm.dialogLoading
                ? _c(
                    "v-card-actions",
                    [
                      _c("action-group", {
                        on: {
                          cancel: _vm.closeUnlinkDialog,
                          confirm: _vm.updateCapacityDock,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.deletingDock
        ? _c("confirm", {
            attrs: {
              "should-show": _vm.showDeleteConfirm,
              "is-manual-mode": "",
              persistent: "",
              icon: "mdi-delete-forever",
              "delete-confirmation": "",
              "show-delete-warning": !_vm.deleteMessageHtml,
              title: "Delete this Dock?",
              width: 650,
              loading: _vm.loading,
              "confirmation-input-text": _vm.deleteConfirmationInputText,
              "entity-name": _vm.deletingDock.name,
              "button-true-text": "YES, DELETE",
              "button-true-color": "error",
              color: "error",
            },
            on: { result: _vm.handleDeleteResult },
            scopedSlots: _vm._u(
              [
                {
                  key: "message",
                  fn: function () {
                    return [
                      _vm.loading
                        ? _c("v-skeleton-loader", {
                            attrs: { type: "list-item-three-line" },
                          })
                        : _vm.deleteMessageHtml
                        ? _c(
                            "div",
                            [
                              _c("h2", { staticClass: "text-center my-3" }, [
                                _vm._v("Appointments will also be deleted!"),
                              ]),
                              _c(
                                "v-alert",
                                {
                                  staticClass: "my-8",
                                  attrs: {
                                    text: "",
                                    prominent: "",
                                    border: "left",
                                    color: "red",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "red" },
                                    },
                                    [_vm._v("mdi-alert")]
                                  ),
                                  _c("span", {
                                    staticClass: "text--primary",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.deleteMessageHtml),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              361151521
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }