<template>
  <dialog-base
    :key="renderKey"
    :loading="false"
    v-bind="$props"
    :header="dialogTitle"
    loading-message="Deleting Recurring Appointments ..."
    @close="close"
    max-width="100%"
    has-dialog-actions
    :value="showDialog"
    width="500px">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button v-else v-on="slotProps.on" v-bind="slotProps.attrs">
        Delete Recurrence
      </primary-button>
    </template>
    <template v-slot:body>
      <delete-recurring-series-form
        :parent-appointment="recurringParentAppointment"
        :appointment="appointment"
        @close="close"></delete-recurring-series-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import recurringAppointmentMixin from '@/components/mixins/recurringAppointmentMixin';

export default {
  mixins: [dialogMixin, recurringAppointmentMixin],
  props: {
    appointment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogTitle: 'Delete Recurrence?'
    };
  },
  methods: {
    updateDialogTitle(title) {
      this.dialogTitle = title;
    }
  }
};
</script>
