<template>
  <form-base
    :hide-required-legend="true"
    :is-loading="isCancellingRef"
    loading-message="Cancelling appointment ...">
    <template #form>
      <v-form class="pt-1 px-2" ref="form">
        <v-textarea
          dense
          outlined
          :autofocus="hasReasonRef"
          class="mt-4"
          no-resize
          name="cancel_reason"
          label="Enter a reason for cancellation"
          :validate-on-blur="false"
          :rules="[
            ...$validator.rules.minLength(10),
            ...$validator.rules.required('Reason for Cancellation')
          ]"
          v-model="cancelReasonRef"></v-textarea>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        confirm-label="Yes, Cancel"
        @cancel="$emit('nevermind')"
        @confirm="cancelAppointment"></action-group>
    </template>
  </form-base>
</template>

<script>
import { ref } from 'vue';
import { useEventHub, useStore } from '@/composables';

export default {
  props: {
    appointment: {
      type: Object,
      required: true
    },
    mixpanelEntryPoint: {
      type: String,
      required: false
    }
  },
  emits: ['confirm'],
  setup(props, context) {
    const store = useStore();
    const eventHub = useEventHub();
    const hasReasonRef = ref(false);
    const cancelReasonRef = ref('');
    const isCancellingRef = ref(false);
    const form = ref(null);

    const cancelAppointment = async () => {
      if (form.value.validate()) {
        try {
          isCancellingRef.value = true;
          await store.dispatch('Appointments/cancelAppointment', {
            id: props.appointment.id,
            reason: cancelReasonRef.value
          });
          await store.dispatch('Appointments/trackMixpanelEvent', {
            entryPoint: props.mixpanelEntryPoint,
            appointment: props.appointment,
            change: 'Cancelled'
          });
          eventHub.$emit('appointment-updated');
          context.emit('confirm');
        } finally {
          isCancellingRef.value = false;
        }
      }
    };

    return {
      hasReasonRef,
      cancelReasonRef,
      isCancellingRef,
      form,
      cancelAppointment
    };
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .v-textarea .v-input__slot {
    height: 80px !important;
  }
  .actions {
    border-top: 1px solid $color-line-divider;
  }
  .form-wrapper {
    padding-bottom: 24px !important;
  }
}
</style>
