var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Edit Organization",
          "loading-message": "Updating Organization...",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.editOrgForm.submit()
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "secondary-button",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: { "before-icon": "login", large: "" },
                            },
                            "secondary-button",
                            slotProps.attrs,
                            false
                          ),
                          slotProps.on
                        ),
                        [_vm._v(" Edit Org ")]
                      ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [_c("edit-org-form", { ref: "editOrgForm", on: { close: _vm.close } })],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }