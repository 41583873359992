<template>
  <div>
    <v-card elevation="5">
      <v-card-title> Please log in to gain access to Opendock. </v-card-title>
      <v-card-subtitle class="mt-1">
        Registration is quick and easy, so feel free to sign up!
      </v-card-subtitle>

      <v-card-actions class="px-4 pb-4">
        <login-dialog
          :alert-message="verificationMode ? alertMessage : null"
          @close="handleLoginClose"
          :alert-type="alertType"
          :show-dialog="verificationMode || $route.query.showLogin === 'true'">
        </login-dialog>
        <registration-dialog></registration-dialog>
        <password-reset-dialog
          :show-dialog="!!resetEmail && !!resetToken"
          :reset-token="resetToken"
          :email="resetEmail">
        </password-reset-dialog>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/**
 * Auth Page
 * @displayName Auth Page
 */
export default {
  props: {
    /**
     * Verification Token
     */
    verificationToken: {
      type: String,
      required: false
    },
    /**
     * Reset Email
     */
    resetEmail: {
      type: String,
      required: false
    },
    /**
     * Reset Token
     */
    resetToken: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      dialogs: {
        login: false,
        register: false
      },
      verificationMode: false,
      alertMessage: null,
      alertType: 'success'
    };
  },
  methods: {
    /**
     * Handles the login close if verification token or showLogin param is true
     * @public
     */
    handleLoginClose() {
      if (this.verificationToken || this.$route.query.showLogin === 'true') {
        this.verificationMode = false;
        this.alertMessage = null;
        this.$router.replace('/login');
      }
    }
  },
  mounted() {
    if (this.verificationToken) {
      this.verificationMode = true;
      this.$store
        .dispatch('Auth/verifyEmail', this.verificationToken)
        .then(() => {
          this.alertMessage = 'Your email has been verified, you can login now.';
        })
        .catch(e => {
          this.alertMessage = e.response.data.message;
          this.alertType = 'error';
        });
    }
  }
};
</script>
