<template>
  <div>
    <v-autocomplete
      height="20px"
      :class="{ required: field.required }"
      :value="field.value"
      :rules="validationRules"
      :items="field.dropDownValues"
      v-bind="[$props, $attrs]"
      @input="value => $emit('input', value || '')"
      dense
      clearable
      open-on-clear>
      <template #label>Choose an option</template>
    </v-autocomplete>
  </div>
</template>

<script>
import customFieldTypeMixinOld from '@satellite/modules/custom_fields/mixins/customFieldTypeMixinOld';

export default {
  name: 'custom-field-dropdown-old',
  mixins: [customFieldTypeMixinOld]
};
</script>
