var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    {
      staticClass: "asset-item-panel",
      attrs: { "data-testid": `asset-visit-panel-${_vm.visit.id}` },
    },
    [
      _c("v-expansion-panel-header", [
        _c("div", { staticClass: "asset-item-header" }, [
          _c(
            "div",
            { staticClass: "asset-item-title font-weight-bold mb-2" },
            [_vm._t("title", null, { visit: _vm.visit })],
            2
          ),
          _c("span", { staticClass: "font-weight-regular" }, [
            _c(
              "span",
              {
                attrs: {
                  "data-asset-company-id":
                    _vm.visit.company?.id ?? _vm.visit.companyHint,
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.visit.company?.name ?? _vm.visit.companyHint) +
                    " - "
                ),
              ]
            ),
            _vm._v(
              " arrived at " +
                _vm._s(_vm.getArrivalTime(_vm.visit.createDateTime)) +
                " "
            ),
          ]),
        ]),
      ]),
      _c(
        "v-expansion-panel-content",
        [
          _c("asset-visit-form-data", {
            class: { "mb-8": _vm.showActions },
            attrs: {
              "asset-visit": _vm.visit,
              company: _vm.visit.company || { name: _vm.visit.companyHint },
            },
          }),
          _vm.visit.appointmentId
            ? [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "appointments",
                        query: { appointmentId: _vm.visit.appointmentId },
                      },
                    },
                  },
                  [_vm._v(" View appointment ")]
                ),
                _c(
                  "outline-button",
                  {
                    staticClass: "ml-4 error--text",
                    attrs: {
                      "x-small": "",
                      "data-testid": `asset-visit-panel-unlink-button-${_vm.visit.id}`,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleUnlinkConfirmDialog(
                          _vm.visit.id,
                          _vm.visit.appointmentId
                        )
                      },
                    },
                  },
                  [_vm._v(" Unlink From Appointment ")]
                ),
              ]
            : _vm._e(),
          _vm.showActions
            ? _c(
                "div",
                { staticClass: "asset-actions" },
                [
                  _c("create-appointment-dialog", {
                    attrs: {
                      "disable-activator": _vm.lockActions,
                      "button-label": "Create appointment",
                      "asset-visit": _vm.visit,
                      context: { selectedWarehouse: _vm.warehouse },
                    },
                    on: {
                      scheduled: (appointment) =>
                        _vm.handleNewAppointment(appointment, _vm.visit.id),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function (slotProps) {
                            return [
                              _c(
                                "primary-button",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        "data-testid": `asset-visit-panel-create-appointment-button-${_vm.visit.id}`,
                                        disabled: slotProps.isButtonDisabled,
                                      },
                                    },
                                    "primary-button",
                                    [slotProps.attrs],
                                    false
                                  ),
                                  slotProps.on
                                ),
                                [_vm._v(" Create appointment ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2790894583
                    ),
                  }),
                  _c(
                    "outline-button",
                    {
                      attrs: {
                        "data-testid": `asset-visit-panel-link-button-${_vm.visit.id}`,
                        disabled: _vm.lockActions,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            "show-appointment-list-dialog",
                            _vm.visit.id
                          )
                        },
                      },
                    },
                    [_vm._v(" Link To Appointment ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-testid": `asset-visit-panel-reject-button-${_vm.visit.id}`,
                        loading: _vm.rejectingLoader,
                        plain: "",
                        text: "",
                        disabled: _vm.lockActions,
                      },
                      on: {
                        click: function ($event) {
                          _vm.showRejectConfirm = true
                        },
                      },
                    },
                    [_vm._v(" Reject Load ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showRejectConfirm
            ? _c("confirm", {
                attrs: {
                  "should-show": _vm.showRejectConfirm,
                  "is-manual-mode": "",
                  persistent: "",
                  title: "Reject load?",
                  width: 650,
                  "button-true-color": "primary",
                  color: "warning",
                },
                on: { result: _vm.handleRejectResult },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "message",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "my-7",
                                  attrs: {
                                    text: "",
                                    prominent: "",
                                    border: "left",
                                    color: "warning",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "#955800" },
                                    },
                                    [_vm._v("mdi-alert")]
                                  ),
                                  _c("span", { staticClass: "text--primary" }, [
                                    _vm._v("This action cannot be undone"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "true-action",
                      fn: function ({ choose }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.rejectingLoader,
                              },
                              on: {
                                click: function ($event) {
                                  return choose(true)
                                },
                              },
                            },
                            [_vm._v(" Reject load ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  716809739
                ),
              })
            : _vm._e(),
          _vm.showUnlinkConfirm
            ? _c("confirm", {
                attrs: {
                  "should-show": _vm.showUnlinkConfirm,
                  "is-manual-mode": "",
                  persistent: "",
                  title: "Unlink from appointment?",
                  width: 650,
                  "button-true-color": "primary",
                  color: "warning",
                },
                on: { result: _vm.handleUnlinkResult },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "message",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "my-7",
                                  attrs: {
                                    text: "",
                                    prominent: "",
                                    border: "left",
                                    color: "warning",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "#955800" },
                                    },
                                    [_vm._v("mdi-alert")]
                                  ),
                                  _c("span", { staticClass: "text--primary" }, [
                                    _vm._v("This action cannot be undone"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "true-action",
                      fn: function ({ choose }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.unlinkingLoader,
                              },
                              on: {
                                click: function ($event) {
                                  return choose(true)
                                },
                              },
                            },
                            [_vm._v(" Unlink from appointment ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  844610196
                ),
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }