import { getResponse } from '@satellite/services/serviceUtilities';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async updateAssetVisit(assetVisitId, data, options = {}, requestConfig = {}) {
    const response = await axios.patch(`asset-visit/${assetVisitId}`, data, requestConfig);

    return getResponse(response, options);
  }
};
