<template>
  <v-autocomplete
    height="20px"
    :class="{ required: field.required }"
    :value="field.value"
    :rules="validationRules"
    :items="field.dropDownValues"
    v-bind="[$props, $attrs]"
    @input="value => $emit('input', value || '')"
    dense
    clearable
    open-on-clear
    multiple>
    <template #label>Choose one or more options</template>
  </v-autocomplete>
</template>

<script>
import customFieldTypeMixinOld from '@satellite/modules/custom_fields/mixins/customFieldTypeMixinOld';

export default {
  mixins: [customFieldTypeMixinOld]
};
</script>
