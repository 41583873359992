<template>
  <form-base :header="header" :hide-required-legend="true" :key="renderKey">
    <template slot="form">
      <v-form ref="form">
        <v-container>
          <v-row class="align-center mb-1">
            <h3 class="mr-4">
              Email Subscribers
              <help-icon-tooltip>
                List of email addresses that will receive all notifications about the appointment.
              </help-icon-tooltip>
              :
            </h3>
            <email-list-field
              v-model="ccEmails"
              ref="emailFieldList"
              small-chips
              small></email-list-field>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template slot="form-actions">
      <action-group @cancel="cancel" confirm-label="Save" @confirm="submit"> </action-group>
    </template>
  </form-base>
</template>

<script>
import renderMixin from '@satellite/components/mixins/renderMixin';

export default {
  mixins: [renderMixin],
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    },
    /**
     * @model
     */
    appointment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ccEmails: this.appointment.ccEmails
    };
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    async submit() {
      const response = await axios.patch(`appointment/${this.appointment.id}`, {
        ccEmails: this.ccEmails
      });

      if (response?.data) {
        this.cancel();
      }
    }
  },
  watch: {
    appointment(appointment) {
      this.ccEmails = appointment.ccEmails;
      this.renderKey++;
    }
  }
};
</script>
