var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-overlay",
    {
      attrs: {
        absolute: "",
        value: _vm.isVisible,
        color: _vm.color,
        opacity: _vm.opacity,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "overlay-contents",
          attrs: { "data-testid": "appts-missing-selection-overlay" },
        },
        [
          _c("svg", { attrs: { height: "150", width: "180" } }, [
            _c("g", [
              _c("circle", {
                attrs: { cx: "90", cy: "80", r: "60", fill: "#E5F1F7" },
              }),
              _c(
                "foreignObject",
                {
                  staticClass: "node",
                  attrs: { x: "60", y: "50", width: "60", height: "60" },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "icon",
                      attrs: { color: "secondary lighten-4" },
                    },
                    [_vm._v("mdi-warehouse")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("h3", { staticClass: "align-center" }, [
            _vm._v("No " + _vm._s(_vm.missingSelectionTitle) + " selected"),
          ]),
          _c("p", [
            _vm._v(
              " Please select a warehouse along with one or more " +
                _vm._s(_vm.missingSelectionTitle) +
                " to continue "
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }