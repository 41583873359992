<template>
  <custom-field-input-base-old
    single-line
    :field="field"
    v-model="value"
    type="text"></custom-field-input-base-old>
</template>

<script>
import customFieldTypeMixinOld from '@satellite/modules/custom_fields/mixins/customFieldTypeMixinOld';

export default {
  name: 'custom-field-string-old',
  mixins: [customFieldTypeMixinOld]
};
</script>
