<template>
  <v-card>
    <v-list dense flat>
      <v-subheader class="mb-3 pl-2 mx-2 d-flex align-center justify-space-between">
        <span>Add options to your drop-down list (Max {{ maxChoices }} options)</span>
        <icon-tooltip-button
          :tooltip="isBulkInput ? 'Show Single Option Input' : 'Show Bulk Option Input'"
          size="large"
          color="secondary"
          @click="toggleIsBulkInput"
          :icon="isBulkInput ? 'mdi-text-short' : 'mdi-text-long'">
        </icon-tooltip-button>
      </v-subheader>
      <template v-if="isBulkInput">
        <v-container class="d-flex flex-column align-center is-relative pt-0">
          <div class="overlay-base pa-3 d-flex flex-column justify-center" v-if="isProcessing">
            <h3 class="z-100 text-center mb-3">Processing Items...</h3>
            <v-progress-linear indeterminate class="z-100 is-relative"></v-progress-linear>
            <div class="overlay-obscure overlay-base"></div>
          </div>
          <v-textarea
            v-model="currentBulkValueTemplate"
            persistent-placeholder
            class="full-width text-area mt-0"
            height="35"
            placeholder="Copy/Paste into this box.  Options must be comma or new line separated"></v-textarea>
          <v-btn
            class="ml-3"
            @click="addCurrentBulkValueTemplate"
            :disabled="disableAddChoiceButton"
            >Add</v-btn
          >
        </v-container>
      </template>
      <v-subheader v-else>
        <v-text-field
          class="mx-2"
          v-model="currentValueTemplate"
          type="text"
          label="New option"
          placeholder="Enter a text and add it to the list..."
          @keydown.enter.native.stop
          @keypress.native.enter="addCurrentValueTemplate">
        </v-text-field>
        <v-btn class="ml-3" @click="addCurrentValueTemplate" :disabled="disableAddChoiceButton"
          >Add</v-btn
        >
      </v-subheader>
      <br />
      <v-list-item-group color="primary">
        <v-list-item v-for="(item, i) in dropDownValuesLocal" :key="i">
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-circle-small</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-btn small icon title="Remove Item" @click="removeValueTemplate(item)">
              <v-icon color="red" x-small>mdi-delete-outline</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group v-if="isEmptyListOfChoices">
        <p class="grey--text text-center">Empty list!</p>
      </v-list-item-group>
    </v-list>
    <p class="grey--text text-right small mr-3">
      <small>List items are alphabetically sorted</small>
    </p>
  </v-card>
</template>

<script>
export default {
  name: 'CustomFieldDropDownChoicesForm',
  data() {
    return {
      currentValueTemplate: '',
      currentBulkValueTemplate: '',
      dropDownValuesLocal: [],
      isBulkInput: false,
      isProcessing: false
    };
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    showDropDownValuesList() {
      return this.type === this.novaCore.CustomFieldType.DropDown;
    },
    disableAddChoiceButton() {
      return (
        ((!this.currentValueTemplate && !this.currentBulkValueTemplate) ||
          this.dropDownValuesLocal.length >= this.novaCore.CUSTOM_FIELD_DROP_DOWN_OPTIONS_LIMIT) &&
        !this.isProcessing
      );
    },
    isEmptyListOfChoices() {
      return this.dropDownValuesLocal.length === 0;
    },
    maxChoices() {
      return this.novaCore.CUSTOM_FIELD_DROP_DOWN_OPTIONS_LIMIT;
    }
  },
  methods: {
    addCurrentValueTemplate() {
      if (this.dropDownValuesLocal.length <= this.maxChoices) {
        const isNewOption = !this.dropDownValuesLocal.includes(this.currentValueTemplate);
        if (isNewOption) {
          this.dropDownValuesLocal.push(this.currentValueTemplate);
          this.dropDownValuesLocal.sort();
          this.currentValueTemplate = '';
        } else {
          this.notify('This option is already on the list of choices', 'warning');
        }
      } else {
        this.notify(`A maximum of ${this.maxChoices} options are allowed`, 'warning');
      }
    },
    async addCurrentBulkValueTemplate() {
      this.isProcessing = true;
      const itemsToPush = [];
      const bulkValueTemplateCopy = this.novaCore.deepClone(this.currentBulkValueTemplate);
      const itemArray = bulkValueTemplateCopy.replace(/,\s+/g, ',').split(/[\n,]/);
      let duplicateItems = false;
      let maxChoices = false;
      itemArray.forEach(item => {
        if (this.dropDownValuesLocal.length <= this.maxChoices) {
          const trimmedItem = item.trim();
          const isNewOption =
            !itemsToPush.includes(trimmedItem) && !this.dropDownValuesLocal.includes(trimmedItem);
          if (isNewOption) {
            // Ignore empty entries
            if (trimmedItem.length > 0) {
              itemsToPush.push(trimmedItem);
            }
          }
        } else {
          maxChoices = true;
          this.notify(`A maximum of ${this.maxChoices} options are allowed`, 'warning');
        }
      });
      if (!duplicateItems && !maxChoices) {
        this.currentBulkValueTemplate = '';
      } else {
        return;
      }
      this.isProcessing = false;
      if (itemsToPush.length > 0) {
        this.dropDownValuesLocal = [...this.dropDownValuesLocal, ...itemsToPush];
        this.dropDownValuesLocal.sort();
        this.toggleIsBulkInput();
      }
    },
    removeValueTemplate(item) {
      this.dropDownValuesLocal = this.dropDownValuesLocal.filter(i => i !== item);
      this.notify(`Option ${item} has been removed from the list of choices`, 'info');
    },
    toggleIsBulkInput() {
      if (this.isProcessing) {
        return;
      }
      this.currentBulkValueTemplate = '';
      this.currentValueTemplate = '';
      this.isBulkInput = !this.isBulkInput;
    }
  },
  watch: {
    dropDownValuesLocal() {
      this.$emit('input', this.dropDownValuesLocal);
    }
  },
  mounted() {
    this.dropDownValuesLocal = [...this.value];
  }
};
</script>
