<template>
  <custom-field-input-base
    v-bind="[$props, $attrs]"
    :field="field"
    v-model="value"
    type="number"></custom-field-input-base>
</template>

<script>
import customFieldTypeMixin from '@satellite/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-number',
  mixins: [customFieldTypeMixin],

  data() {
    return {
      value: String(this.field.value) // Cast to string to prevent "0" as default in field
    };
  },
  watch: {
    value() {
      if (this.value === '') {
        this.$emit('input', '');
        return;
      }
      this.$emit('input', Number(this.value));
    }
  }
};
</script>
