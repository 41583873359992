var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.warehouse.id
        ? _c(
            "v-tabs",
            {
              attrs: {
                grow: "",
                "background-color": "secondary",
                dark: "",
                "slider-color": "#ffe67f",
                "next-icon": "mdi-arrow-right-bold-box-outline",
                "prev-icon": "mdi-arrow-left-bold-box-outline",
                "show-arrows": "",
                centered: "",
              },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            _vm._l(_vm.tabs, function (tab) {
              return _c(
                "v-tab",
                {
                  key: tab.title,
                  attrs: {
                    disabled: _vm.isTabDisabled(tab),
                    "active-class": "active-tab",
                    to: {
                      name: tab.route,
                      params: { warehouseId: _vm.warehouseId },
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(tab.title) + " "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _vm.getTabNotification(tab)
                                  ? _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "circular-pulse",
                                            attrs: { right: "" },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-alert-circle-outline ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.getTabNotification(tab)))])]
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "div",
        { key: _vm.renderKey },
        [
          _vm.warehouse.id
            ? _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                _vm._l(_vm.tabs, function (tab) {
                  return _c(
                    "v-tab-item",
                    { key: tab.title, attrs: { value: _vm.route(tab.route) } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(tab.component, {
                            tag: "component",
                            attrs: { warehouse: _vm.warehouse },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }