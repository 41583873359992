<template>
  <v-menu
    transition="scale-transition"
    :close-on-content-click="false"
    offset-y
    :content-class="datePickerWrapperClass"
    v-model="datePicker"
    max-width="100%"
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <template v-if="!externalTrigger">
        <template v-if="iconMode">
          <div class="text-center">
            <v-btn icon large @click="datePicker = true" color="secondary" :ripple="hasRipple">
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </div>
        </template>
        <div
          class="flex-1 date-picker-input"
          :class="{ 'mb-4': !compactDisplay && !noMargins }"
          v-else>
          <h3 v-if="label" :class="{ 'text--disabled': disabled }" class="mr-4">{{ label }}:</h3>
          <div class="d-flex justify-center align-content-center" v-if="noInputMode">
            <v-btn
              :ripple="hasRipple"
              @click="datePicker = true"
              secondary
              small
              large
              plain
              class="mt-4"
              :disabled="disabled">
              <v-icon>mdi-calendar</v-icon>
              <span class="ml-2" v-if="inputValueDisplay">
                {{ inputValueDisplay }}
              </span>
            </v-btn>
          </div>
          <v-text-field
            v-else
            dense
            :outlined="outlined"
            readonly
            :height="height"
            :disabled="disabled"
            :error="error"
            :class="{
              required: required,
              'datepicker-with-label': Boolean(label),
              'ml-4': !displayFieldIcon && !noMargins,
              'mt-4': !noMargins,
              'mt-0': noMargins
            }"
            :prepend-icon="displayFieldIcon && !innerIcon ? 'mdi-calendar' : ''"
            :prepend-inner-icon="displayFieldIcon && innerIcon ? 'mdi-calendar' : ''"
            :rules="validationRules"
            :required="required"
            :placeholder="placeholder"
            hide-details="auto"
            v-model="inputValueDisplay"
            :label="displayFieldLabel ? placeholder : ''"
            v-bind="attrs"
            @click:clear="isRange ? (selectedDates = []) : (selectedDates = '')"
            @blur="selectedDates = parseDates(formattedDates)"
            v-on="on"
            :clearable="clearable"></v-text-field>
        </div>
      </template>
      <template v-else>
        <slot name="external-trigger" :open-date-picker="openDatePicker"></slot>
      </template>
    </template>
    <v-date-picker
      :min="minDate"
      :max="maxDate"
      ref="datePicker"
      class="date-picker"
      :allowed-dates.sync="allowedDates"
      v-model="selectedDates"
      :no-title="noTitle"
      :color="color"
      :range="isRange"
      @update:picker-date="yearMonth => $emit('date-navigation', `${yearMonth}-15`)"
      @input="handleDatesSelected"></v-date-picker>
  </v-menu>
</template>

<script>
/**
 * Datepicker
 * @displayName Date Picker
 */
export default {
  props: {
    /**
     * @model
     */
    value: [String, Array],
    /**
     * Disable datepicker
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Datepicker label
     */
    label: {
      type: String,
      required: false
    },
    displayFieldLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    displayFieldIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    compactDisplay: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    iconMode: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: String,
      required: false,
      default: '20px'
    },
    innerIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    isRange: {
      type: Boolean,
      required: false,
      default: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    format: {
      type: String,
      required: false,
      default: 'MM/DD/YYYY'
    },
    /*
     * It shows the selected date on a label instead of a input
     */
    noInputMode: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    // Not great, but designed to give some more control to parent without
    // having to refactor the whole usage.
    noMargins: {
      type: Boolean,
      required: false,
      default: false
    },
    minDate: {
      type: String,
      required: false
    },
    maxDate: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        let now = momentjs.utc();
        let placeholder = 'e.g. ' + now.clone().format(this.format);
        if (this.isRange) {
          placeholder += ` - ${now.clone().add('10', 'days').format(this.format)}`;
        }
        return placeholder;
      }
    },
    customValidationRules: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    openDates: {
      type: Array,
      required: false
    },
    hasRipple: {
      type: Boolean,
      required: false,
      default: true
    },
    externalTrigger: {
      type: Boolean,
      required: false,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: ''
    },
    datePickerWrapperClass: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      datePicker: false,
      selectedDates: this.isRange ? [] : '',
      formattedDates: this.isRange ? [] : ''
    };
  },
  methods: {
    openDatePicker() {
      this.datePicker = true;
    },
    handleDatesSelected(dates) {
      if (this.isRange) {
        if (dates.length < 2) {
          this.datePicker = true;
          return;
        }
      }

      this.datePicker = false;
    },
    formatDates(dates) {
      let formattedDates = this.isRange ? [] : '';
      if (dates) {
        if (!Array.isArray(dates)) {
          formattedDates = this.formatDate(dates);
        } else {
          formattedDates = dates.map(date => {
            return this.formatDate(date);
          });
        }
      }

      return formattedDates;
    },
    /**
     * Format the date from YYYY-MM-DD to MM/DD/YYYY format
     * @public
     * @param date
     * @returns {string|null}
     */
    formatDate(date) {
      if (!date) return null;

      return momentjs.utc(date, 'YYYY/MM/DD').format(this.format);
    },
    parseDates(dates) {
      let parsedDates = this.isRange ? [] : '';
      if (dates) {
        if (!Array.isArray(dates)) {
          parsedDates = this.parseDate(dates);
        } else {
          parsedDates = dates.map(date => {
            return this.parseDate(date);
          });
        }
      }

      return parsedDates;
    },
    /**
     * Parse date from MM/DD/YYYY to YYYY-MM-DD
     * @public
     * @param date
     * @returns {string|null}
     */
    parseDate(date) {
      date = String(date);
      if (!date) return null;

      return momentjs.utc(date, this.format).format('YYYY-MM-DD');
    },
    /**
     * Disable dates that are not available
     * TODO: This needs real dates
     * @param val
     * @returns {*}
     */
    allowedDates(val) {
      if (this.isRange) {
        return this.selectedDates?.length === 1 ? val >= this.selectedDates[0] : true;
      } else {
        return this.openDates?.length > 0 ? this.openDates.includes(val) : true;
      }
    }
  },
  beforeMount() {
    if (this.value) {
      this.formattedDates = this.formatDates(this.value);
      this.selectedDates = this.value;
    }
  },
  computed: {
    inputValueDisplay: {
      get() {
        return Array.isArray(this.formattedDates)
          ? this.formattedDates.join(' - ')
          : this.formattedDates;
      },
      set(value) {
        this.formattedDates = value;
      }
    },
    validationRules() {
      if (this.customValidationRules.length > 0) {
        return this.customValidationRules;
      } else {
        return this.required ? this.$validator.rules.required(this.label || 'Date') : [];
      }
    }
  },
  watch: {
    selectedDates(val) {
      if (val) {
        let valClone = this.novaCore.deepClone(val);
        if (!this.isRange && Array.isArray(val)) {
          valClone = valClone.join('');
        }
        this.formattedDates = this.formatDates(valClone);

        /**
         * Emits input event with new data
         * @event input
         * @property {string} input - new selected date
         */
        this.$emit('input', valClone);
      } else if (val === '') {
        this.$emit('input', '');
      }
    },
    formattedDates(val) {
      if (val) {
        /**
         * Emits input event with new data
         * @event input
         * @property {string} input - new selected date
         */
        this.$emit('input', this.selectedDates);
      }
    },
    value(val) {
      this.formattedDates = this.formatDates(val);
      this.selectedDates = val;
    },
    datePicker() {
      if (!this.datePicker && this.isRange && this.selectedDates.length < 2) {
        this.selectedDates = [];
        this.formattedDates = [];
      }
    }
  }
};
</script>
