<template>
  <v-card-title class="px-6">
    <span class="headline d-flex align-center">
      <slot name="prepend-button"></slot>
      <v-icon v-if="prependHeaderIcon" class="mr-2">{{ prependHeaderIcon }}</v-icon>
      {{ header }}
    </span>
    <v-spacer></v-spacer>
    <!-- @slot header actions -->
    <slot name="header-actions"></slot>
    <v-btn class="pa-3" icon light @click="$emit('close')" v-if="!hideCloseIcon">
      <v-icon>mdi-close-circle</v-icon>
    </v-btn>
  </v-card-title>
</template>

<script>
/**
 * Styled dialog header
 * @displayName Dialog Header
 */
export default {
  props: {
    /**
     * Dialog header
     */
    header: {
      type: String,
      required: true
    },
    prependHeaderIcon: {
      type: String,
      required: false,
      default: ''
    },
    hideCloseIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      /**
       * Emits close event
       * @event close
       */
      this.$emit('close');
    }
  }
};
</script>
