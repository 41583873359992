var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "secondary mb-12" }, [
    _c(
      "div",
      {
        staticClass:
          "appointment-details-wrapper d-flex justify-space-between align-center px-8 py-2",
      },
      [
        _c("img", {
          staticClass: "header-bar-logo",
          attrs: { src: _vm.$logoPath, id: "logo-image", alt: "opendock logo" },
        }),
        _c(
          "span",
          { staticClass: "white--text py-2" },
          [
            _vm._v(" Have a warehouse account? "),
            _c(
              "router-link",
              {
                staticClass: "primary-button px-6 py-1 ml-2",
                attrs: { to: { name: "login" } },
              },
              [_vm._v("Login")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }