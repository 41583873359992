var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [
        _vm._v(
          " Keep records of damages, security measures and other evidence to support claim disputes. "
        ),
      ]),
      _c(
        "div",
        { staticClass: "disclaimer" },
        [
          _vm._v(" Customize your forms at the warehouse "),
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "warehouses.details.forms",
                  params: { warehouseId: _vm.appointment.dock.warehouseId },
                },
              },
            },
            [_vm._v(" Fields and Forms ")]
          ),
          _vm._v(" section. "),
        ],
        1
      ),
      _c("custom-forms-data-panels", {
        attrs: {
          "object-id": _vm.appointment.id,
          appointment: _vm.appointment,
          triggers: _vm.claimSupportTriggers,
          warehouse: _vm.appointment.warehouse,
          timezone: _vm.warehouse.timezone,
          "military-time-enabled": _vm.$isMilitaryTimeEnabled(_vm.warehouse),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }