<template>
  <span class="d-inline-flex">
    <v-switch
      dense
      height="20px"
      v-model="value"
      color="primary"
      :label="label"
      hide-details="auto"
      class="d-inline-flex">
    </v-switch>
    <span v-if="field.required" class="red--text required-bool-label"><strong>*</strong></span>
  </span>
</template>

<script>
import customFieldTypeMixin from '@satellite/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-bool',
  mixins: [customFieldTypeMixin],
  computed: {
    label() {
      return this.value ? 'Yes' : 'No';
    }
  }
};
</script>
