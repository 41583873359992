var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          "content-class": "dock-dialog-content",
          header: "Update Dock",
          scrollable: true,
          "max-width": "60vw",
          width: "60vw",
          "loading-message": "Saving Dock...",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _c(
                            "primary-button",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mr-2",
                                  attrs: { "before-icon": "pencil", large: "" },
                                },
                                "primary-button",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            ),
                            [_vm._v(" Update Dock ")]
                          ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("dock-form", {
            attrs: { dock: _vm.dock, "warehouse-id": _vm.warehouseId },
            on: {
              saved: function ($event) {
                return _vm.closeDialog(true)
              },
              close: _vm.closeDialog,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }