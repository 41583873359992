var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("form-base", {
        attrs: { header: _vm.header, "hide-required-legend": "" },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function () {
              return [
                _c(
                  "v-form",
                  { ref: "form", class: { disabled: _vm.readOnly } },
                  [
                    _c(
                      "v-container",
                      {
                        staticClass: "elevation-1 px-8 pt-8",
                        attrs: { fluid: "" },
                      },
                      [
                        _c("h3", { staticClass: "pb-5" }, [
                          _vm._v("Basic Details"),
                        ]),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6", md: "3" } },
                              [
                                _c("text-field", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    required: true,
                                    type: "text",
                                    rules:
                                      _vm.$validator.rules.required(
                                        "Warehouse Name"
                                      ),
                                    tooltipText:
                                      "Name of Warehouse location visible to Warehouse and Carriers",
                                    label: "Warehouse Name",
                                  },
                                  model: {
                                    value: _vm.editingWarehouse.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression: "editingWarehouse.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6", md: "3" } },
                              [
                                _c("phone-number-field", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    "margin-top": "-7px",
                                    validator: _vm.$validator,
                                    "default-country": _vm.defaultPhoneCountry,
                                    tooltipText:
                                      "Warehouse contact phone number for Carriers",
                                    label: "Phone",
                                  },
                                  model: {
                                    value: _vm.editingWarehouse.phone,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "phone",
                                        $$v
                                      )
                                    },
                                    expression: "editingWarehouse.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6", md: "4" } },
                              [
                                _c("text-field", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    type: "email",
                                    tooltipText:
                                      "Warehouse contact email for Carriers. It will be used as 'Reply To' address of the appointment emails.",
                                    rules: _vm.$validator.rules.email,
                                    label: "Warehouse Contact Email",
                                  },
                                  model: {
                                    value: _vm.editingWarehouse.email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "email",
                                        $$v
                                      )
                                    },
                                    expression: "editingWarehouse.email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6", md: "2" } },
                              [
                                _c("text-field", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    "prepend-icon": "mdi-pound",
                                    tooltipText: "Warehouse store number",
                                    label: "Store Number",
                                  },
                                  model: {
                                    value: _vm.editingWarehouse.facilityNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "facilityNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editingWarehouse.facilityNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "mb-4" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "align-field",
                                attrs: { cols: "12", sm: "6", md: "4" },
                              },
                              [
                                _c("country-select", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    "limit-options": "",
                                    tooltipText:
                                      "Country of the Warehouse location",
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                  },
                                  model: {
                                    value: _vm.editingAddress.country,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingAddress,
                                        "country",
                                        $$v
                                      )
                                    },
                                    expression: "editingAddress.country",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                class: { "align-field": _vm.manualAddressMode },
                              },
                              [
                                !_vm.manualAddressMode
                                  ? _c("address-autocomplete-field", {
                                      ref: "searchAddressField",
                                      attrs: {
                                        required: "",
                                        "clear-on-country-change": "",
                                        readonly:
                                          !_vm.$rolePermissions
                                            .canUpdateWarehouse || _vm.readOnly,
                                        disabled:
                                          !_vm.editingAddress.country ||
                                          _vm.readOnly,
                                        country: _vm.editingAddress.country,
                                        tooltipText:
                                          "Address of the Warehouse location visible to carriers",
                                      },
                                      on: {
                                        filled: _vm.fillFullAddress,
                                        "manual-override":
                                          _vm.enterManualAddressMode,
                                      },
                                      model: {
                                        value: _vm.geocodingData,
                                        callback: function ($$v) {
                                          _vm.geocodingData = $$v
                                        },
                                        expression: "geocodingData",
                                      },
                                    })
                                  : _c("street-field", {
                                      ref: "manualStreetField",
                                      attrs: {
                                        required: "",
                                        rules:
                                          _vm.$validator.rules.required(
                                            "Street Address"
                                          ),
                                      },
                                      on: {
                                        search: _vm.enterSearchAddressMode,
                                      },
                                      model: {
                                        value: _vm.editingAddress.street,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingAddress,
                                            "street",
                                            $$v
                                          )
                                        },
                                        expression: "editingAddress.street",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.manualAddressMode && _vm.editingAddress.city
                          ? _c("address-display", {
                              attrs: {
                                zip: _vm.editingAddress.zip,
                                city: _vm.editingAddress.city,
                                state: _vm.editingAddress.state,
                                street: _vm.editingAddress.street,
                                timezone: _vm.editingAddress.timezone,
                              },
                            })
                          : _vm.manualAddressMode
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6", md: "3" } },
                                  [
                                    _c("text-field", {
                                      attrs: {
                                        required: "",
                                        type: "text",
                                        rules:
                                          _vm.$validator.rules.required("City"),
                                        label: "City",
                                      },
                                      model: {
                                        value: _vm.editingAddress.city,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingAddress,
                                            "city",
                                            $$v
                                          )
                                        },
                                        expression: "editingAddress.city",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6", md: "3" } },
                                  [
                                    _vm.editingAddress.country === "USA" ||
                                    _vm.editingAddress.country === "CAN"
                                      ? _c("state-select", {
                                          attrs: {
                                            required: "",
                                            rules:
                                              _vm.$validator.rules.required(
                                                "State"
                                              ),
                                            label: "State",
                                            country: _vm.editingAddress.country,
                                          },
                                          model: {
                                            value: _vm.editingAddress.state,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editingAddress,
                                                "state",
                                                $$v
                                              )
                                            },
                                            expression: "editingAddress.state",
                                          },
                                        })
                                      : _c("text-field", {
                                          attrs: {
                                            required: "",
                                            type: "text",
                                            rules:
                                              _vm.$validator.rules.required(
                                                "State"
                                              ),
                                            label: "State",
                                          },
                                          model: {
                                            value: _vm.editingAddress.state,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editingAddress,
                                                "state",
                                                $$v
                                              )
                                            },
                                            expression: "editingAddress.state",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6", md: "3" } },
                                  [
                                    _c("text-field", {
                                      attrs: {
                                        required: "",
                                        type: "text",
                                        rules:
                                          _vm.$validator.rules.required("Zip"),
                                        placeholder: "85331",
                                        label: "Zip / Postal Code",
                                      },
                                      model: {
                                        value: _vm.editingAddress.zip,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingAddress,
                                            "zip",
                                            $$v
                                          )
                                        },
                                        expression: "editingAddress.zip",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6", md: "3" } },
                                  [
                                    _c("timezone-select", {
                                      attrs: {
                                        rules:
                                          _vm.$validator.rules.required(
                                            "Timezone"
                                          ),
                                        label: "Timezone",
                                        required: "",
                                      },
                                      model: {
                                        value: _vm.editingAddress.timezone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingAddress,
                                            "timezone",
                                            $$v
                                          )
                                        },
                                        expression: "editingAddress.timezone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("email-list-field", {
                                  staticClass: "full-width mb-4",
                                  attrs: {
                                    "read-only":
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    disabled: _vm.readOnly,
                                    label: "Default Email Subscribers",
                                    "prepend-icon": "mdi-email-outline",
                                    tooltipText:
                                      "Default email subscribers for appointments at this facility.",
                                    "small-chips": "",
                                    small: "",
                                  },
                                  model: {
                                    value: _vm.editingWarehouse.ccEmails,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "ccEmails",
                                        $$v
                                      )
                                    },
                                    expression: "editingWarehouse.ccEmails",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _vm.$rolePermissions.canUpdateWarehouse &&
                                !_vm.readOnly
                                  ? _c("v-textarea", {
                                      attrs: { rows: "2", outlined: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "with-tooltip",
                                                  },
                                                  [
                                                    _vm._v(" Notes "),
                                                    _c(
                                                      "help-icon-tooltip",
                                                      {
                                                        attrs: {
                                                          iconStyleClass:
                                                            "grey darken-2",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Notes that are internal to the Warehouse "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3643118026
                                      ),
                                      model: {
                                        value: _vm.editingWarehouse.notes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingWarehouse,
                                            "notes",
                                            $$v
                                          )
                                        },
                                        expression: "editingWarehouse.notes",
                                      },
                                    })
                                  : _c(
                                      "div",
                                      { staticClass: "v-input v-text-field" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "v-text-field__slot full-width",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "v-label font-size-x-small",
                                              },
                                              [_vm._v("Notes")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text--disabled border-width-1 bordered rounded pa-4 full-width",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.editingWarehouse.notes
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _vm.$rolePermissions.canUpdateWarehouse &&
                                !_vm.readOnly
                                  ? _c("rich-text-input", {
                                      attrs: {
                                        tooltipText:
                                          "To be displayed in the email notifications and the Carrier View Portal",
                                        label: "Instructions for Carriers",
                                      },
                                      on: { ready: _vm.handleRichTextReady },
                                      model: {
                                        value:
                                          _vm.editingWarehouse.instructions,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingWarehouse,
                                            "instructions",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "editingWarehouse.instructions",
                                      },
                                    })
                                  : _c(
                                      "div",
                                      { staticClass: "v-input v-text-field" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "v-text-field__slot full-width",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "v-label font-size-x-small",
                                              },
                                              [_vm._v("Instructions")]
                                            ),
                                            _c("div", {
                                              staticClass:
                                                "text--disabled border-width-1 bordered rounded pa-4",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.editingWarehouse
                                                    .instructions
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("warehouse-amenities-ppe-requirements", {
                                  attrs: { fields: _vm.editingWarehouse },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "mt-9" },
                          [
                            _c("v-col", [
                              _c("h3", { staticClass: "black--text" }, [
                                _vm._v(
                                  "RefNumber (PO Number) Validation Setup"
                                ),
                              ]),
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: {
                                    href: "https://community.loadsmart.com/hc/en-us/articles/14946368437907-PO-Ref-Number-Validation-Implementation",
                                    target: "_blank",
                                    rel: "noopener",
                                  },
                                },
                                [_vm._v(" (Learn more here) ")]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "mt-6" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    outlined: "",
                                    "persistent-hint": "",
                                    "persistent-placeholder": "",
                                    label: "Version",
                                    items: _vm.refNumberValidationVersionItems,
                                  },
                                  model: {
                                    value:
                                      _vm.editingWarehouse
                                        .refNumberValidationVersion,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "refNumberValidationVersion",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editingWarehouse.refNumberValidationVersion",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    spellcheck: "false",
                                    type: "url",
                                    rules: _vm.$validator.rules.url,
                                    label: "Validator URL",
                                  },
                                  model: {
                                    value:
                                      _vm.editingWarehouse
                                        .refNumberValidationUrl,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "refNumberValidationUrl",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editingWarehouse.refNumberValidationUrl",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.editingWarehouse.refNumberValidationVersion ===
                        _vm.novaCore.RefNumberValidationVersion.V2
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        disabled: _vm.readOnly,
                                        readonly:
                                          !_vm.$rolePermissions
                                            .canUpdateWarehouse || _vm.readOnly,
                                        spellcheck: "false",
                                        type: "text",
                                        label:
                                          "Passcode (optional Auth-Bearer token)",
                                      },
                                      model: {
                                        value:
                                          _vm.editingWarehouse
                                            .refNumberValidationPasscode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingWarehouse,
                                            "refNumberValidationPasscode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "editingWarehouse.refNumberValidationPasscode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("v-divider", { staticClass: "mt-6" }),
                        false
                          ? _c("h3", { staticClass: "pb-2" }, [_vm._v("Tags")])
                          : _vm._e(),
                        false
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("tag-manager", {
                                      attrs: {
                                        disabled: _vm.readOnly,
                                        "read-only":
                                          !_vm.$rolePermissions
                                            .canUpdateWarehouse || _vm.readOnly,
                                        height: "60px",
                                        label: "Warehouse Tags",
                                      },
                                      model: {
                                        value: _vm.editingWarehouse.tags,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingWarehouse,
                                            "tags",
                                            $$v
                                          )
                                        },
                                        expression: "editingWarehouse.tags",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          [
                            _c("v-col", [
                              _c(
                                "h3",
                                { staticClass: "mt-16 mb-2 black--text" },
                                [_vm._v("Scheduling Portal Availability")]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("carrier-scheduling-toggle", {
                                  staticClass: "pl-2 pb-2",
                                  attrs: {
                                    entity: "Warehouse",
                                    disabled:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                  },
                                  model: {
                                    value:
                                      _vm.editingWarehouse
                                        .allowCarrierScheduling,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "allowCarrierScheduling",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editingWarehouse.allowCarrierScheduling",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.editingWarehouse.allowCarrierScheduling &&
                        _vm.$carrierViewAllowedUserRoles.includes(
                          _vm.$me.role
                        ) &&
                        !_vm.readOnly
                          ? _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "primary-button",
                                        {
                                          staticClass: "mr-4",
                                          attrs: {
                                            small: "",
                                            "test-id": "copy-warehouse-button",
                                            "before-icon": "link",
                                          },
                                          on: { click: _vm.copyWarehouseLink },
                                        },
                                        [
                                          _vm._v(
                                            " Copy warehouse link to share "
                                          ),
                                        ]
                                      ),
                                      _c("preview-as-carrier-button", {
                                        attrs: {
                                          warehouse: _vm.warehouse,
                                          disabled:
                                            !_vm.warehouse
                                              .allowCarrierScheduling,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          [
                            _c("v-col", [
                              _c(
                                "h3",
                                { staticClass: "mt-10 mb-2 black--text" },
                                [_vm._v("Experimental Feature")]
                              ),
                              _c("div", { staticClass: "mt-5" }, [
                                _c(
                                  "p",
                                  [
                                    _c("caution-message", [
                                      _vm._v(
                                        " This changes Carrier availability - make sure you understand the implications: "
                                      ),
                                    ]),
                                    _vm._v(
                                      " Prevent surprise appointments by removing the first "
                                    ),
                                    _c("text-field", {
                                      ref: "intervalTrimForCarriers",
                                      staticClass:
                                        "inline-text-field no-spinner text-center",
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.editingWarehouse
                                            .intervalTrimForCarriers,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingWarehouse,
                                            "intervalTrimForCarriers",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "editingWarehouse.intervalTrimForCarriers",
                                      },
                                    }),
                                    _c("strong", [_vm._v("intervals")]),
                                    _vm._v(
                                      ' from Carrier availability. "Interval" in this context refers to a continuous block of Warehouse open-hours. '
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c("v-divider", { staticClass: "mt-5" }),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "h3",
                                { staticClass: "mt-16 mb-2 black--text" },
                                [_vm._v("Warehouse Settings")]
                              ),
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("settings-expansion-panels", {
                                  attrs: {
                                    "read-only": _vm.readOnly,
                                    "entity-parent":
                                      _vm.novaCore.DatabaseEntities.Org,
                                    "entity-key":
                                      _vm.novaCore.DatabaseEntities.Warehouse,
                                    "should-be-expanded": false,
                                    "entity-parent-obj": _vm.editingWarehouse,
                                    "entity-settings":
                                      _vm.warehouse?.settings || {},
                                  },
                                  on: {
                                    "update-settings": (data) =>
                                      (_vm.warehouseSettings = data),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("action-group", {
                          staticClass: "pr-0 pt-8",
                          attrs: {
                            "confirm-icon": "upload",
                            "confirm-label": "Save Warehouse",
                            "hide-cancel": true,
                            "cancel-color": "error",
                            "confirm-button-tooltip": _vm.readOnly
                              ? _vm.novaCore.getRoleActionError()
                              : "",
                            "disable-confirm":
                              !_vm.$rolePermissions.canUpdateWarehouse ||
                              _vm.readOnly,
                          },
                          on: { confirm: _vm.submit },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-dialog", {
        attrs: {
          value: Boolean(_vm.notificationConfigKey),
          "retain-focus": false,
          width: "auto",
        },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            _vm.notificationConfigKey = null
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "my-2" },
                  [
                    _vm.notificationConfigKey
                      ? _c("notification-config-form", {
                          attrs: {
                            "warehouse-id": _vm.warehouse.id,
                            settings: _vm.warehouse.settings || {},
                            "notification-type": _vm.notificationConfigKey,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }