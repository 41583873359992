<template>
  <div>
    <form-base :hide-required-legend="true" class="create-recurring-series-form">
      <template #form>
        <v-form v-if="!hasFailures" class="pt-1 mx-3 inline-fields" ref="formRef">
          <template v-if="loading">
            <v-progress-linear
              indeterminate
              :loading="loading"
              height="6"
              class="mt-6"></v-progress-linear>
            <h4 class="text-center mt-4 mb-12 text--color-text-tertiary">
              Creating Appointments ...
            </h4>
          </template>
          <template v-else>
            <v-row class="mt-3 mb-6">
              <v-col md="12">
                <strong class="black--text">Repeat every:</strong>
                <weekday-checkbox-group
                  horizontal
                  short-names
                  :custom-weekdays="shortWeekdays"
                  v-model="checkedDaysProxy"></weekday-checkbox-group>
              </v-col>
            </v-row>
            <v-row class="my-3">
              <v-col md="7">
                <strong class="black--text">Ends After:</strong>
                <v-text-field
                  dense
                  min="0"
                  :max="maxNumWeeks"
                  hide-details
                  class="mt-2 weeks-input"
                  v-model="numWeeks"
                  :rules="afterRules"
                  type="number"
                  suffix="weeks in a row"></v-text-field>
              </v-col>
            </v-row>
            <p class="font-size-x-small estimated-end-date" v-html="estimatedEndDate"></p>
            <v-row v-if="!novaCore.isReserve(appointment)" class="mt-2">
              <v-col md="6">
                <p>
                  <strong class="black--text">Copy values of</strong>
                  <help-icon-tooltip>
                    Copy the selected fields for all child appointments created in the recurring
                    series
                  </help-icon-tooltip>
                </p>
                <v-checkbox
                  :key="item"
                  v-for="item in recurringExtraFieldsToCopyEnum"
                  :label="formatFieldName(item)"
                  :value="copyFields.includes(item)"
                  @click="() => toggleItem(item)"
                  class="mt-2"
                  hide-details
                  dense></v-checkbox>
              </v-col>
              <v-col md="6">
                <p>
                  <strong class="black--text">Starting appointment status</strong>
                  <help-icon-tooltip>
                    The starting status for all child appointments created in the recurring series
                  </help-icon-tooltip>
                </p>
                <v-radio-group v-model="startingStatus" hide-details v-if="isRequestedStatusSet">
                  <v-radio
                    :value="novaCore.AppointmentStatus.Scheduled"
                    :label="novaCore.AppointmentStatus.Scheduled"></v-radio>
                  <v-radio
                    :value="novaCore.AppointmentStatus.Requested"
                    :label="novaCore.AppointmentStatus.Requested"></v-radio>
                </v-radio-group>
                <p v-else class="text--black">
                  <v-label>Scheduled</v-label>
                </p>
              </v-col>
            </v-row>
          </template>
        </v-form>

        <v-card v-else class="px-7" max-height="100%" elevation="0">
          <v-alert dense border="left" colored-border color="error" class="error lighten-5">
            <strong class="font-size-small">
              Appointments with no availability:
              <span class="error--text text--darken-4">{{ failureCount }}</span>
              / {{ totalAttemptedCount }}
            </strong>
          </v-alert>
          <v-card-text>
            Sorry, we couldn't create the following appointments due to
            <strong class="black--text">no availability on your current schedule.</strong>
            <div class="pt-4">
              <span
                class="font-size-small d-block"
                v-for="(failure, i) in failures"
                :key="`failure-${i}`">
                {{
                  novaCore.transformForUserFriendlyTimezone(
                    novaCore.renderUtcInTimezone(
                      failure.start,
                      appointment.dock.warehouse.timezone
                    ),
                    appointment.dock.warehouse.timezone
                  )
                }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template #form-actions>
        <action-group
          key="recurring-action-create"
          v-if="!loading && !hasFailures"
          confirm-label="Create Recurrence"
          :disable-confirm="!canSubmit"
          @cancel="$emit('close')"
          @confirm="createRecurringSeries"></action-group>
        <action-group
          key="recurring-action-export"
          cancel-label="Got It"
          v-else-if="!loading && hasFailures"
          cancel-icon=""
          hide-confirm
          :is-spacer-visible="false"
          :pre-spacer="true"
          @cancel="$emit('close')">
          <template v-slot:additional-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="exportToExcel" class="mr-2">Export List</v-btn>
          </template>
        </action-group>
      </template>
    </form-base>
  </div>
</template>

<script>
import WeekdayCheckboxGroup from '@satellite/components/elements/checkbox/WeekdayCheckboxGroup';
import { utils, writeFile } from 'xlsx';
import { computed, onMounted, ref, watch } from 'vue';
import { useNotify, useNovaCore, useStore, useValidator } from '@/composables';
import { DateTime } from 'luxon';

export default {
  components: { WeekdayCheckboxGroup },
  props: {
    /**
     * Calendar Event
     */
    appointment: {
      type: Object,
      required: true
    }
  },
  emits: ['update-title', 'close'],
  setup(props, context) {
    const store = useStore();
    const novaCore = useNovaCore();
    const validator = useValidator();
    const notify = useNotify();
    const formRef = ref(null);
    const loading = ref(false);
    const checkedDaysProxy = ref([]);
    const numWeeks = ref(1);
    const successes = ref([]);
    const failures = ref([]);
    const copyFields = ref([]);
    const shortWeekdays = novaCore.getShortWeekdays();
    const isRequestedStatusSet = computed(() => {
      return (
        props.appointment.dock.warehouse.settings?.appointmentCreationStatus ===
        novaCore.AppointmentStatus.Requested
      );
    });

    const startingStatus = ref(
      isRequestedStatusSet?.value
        ? novaCore.AppointmentStatus.Requested
        : novaCore.AppointmentStatus.Scheduled
    );

    const orderedCheckedDays = computed(() => {
      const checkedDays = novaCore.deepClone(checkedDaysProxy.value);
      const sortedDays = novaCore.sortByArray(checkedDays, shortWeekdays);
      return sortedDays;
    });

    const estimatedEndDate = computed(() => {
      if (checkedDaysProxy.value.length === 0) {
        return '&nbsp;';
      } else {
        const apptStartDateTime = DateTime.fromISO(props.appointment.start).setZone(
          props.appointment.dock.warehouse.timezone
        );
        let estimatedEndDate;
        const latestCheckedDay = shortWeekdays.indexOf(
          novaCore.upperFirst(orderedCheckedDays.value[orderedCheckedDays.value.length - 1])
        );

        estimatedEndDate = apptStartDateTime
          .plus({ weeks: numWeeks.value })
          .set({ weekday: latestCheckedDay });

        return `Estimated End Date: ${estimatedEndDate.toFormat(
          novaCore.LuxonDateTimeFormats.ShortMonthDayYear
        )}`;
      }
    });

    const recurringExtraFieldsToCopyEnum = computed(() => {
      return Object.values(novaCore.RecurringExtraFieldsToCopyEnum);
    });

    const canSubmit = computed(() => {
      return (
        checkedDaysProxy.value.length > 0 &&
        numWeeks.value > 0 &&
        numWeeks.value <= novaCore.MAX_RECURRING_APPOINTMENT_WEEKS
      );
    });

    const maxNumWeeks = computed(() => {
      return novaCore.MAX_RECURRING_APPOINTMENT_WEEKS;
    });

    const afterRules = computed(() => {
      return [
        ...validator.rules.required('Number of Weeks'),
        ...validator.rules.numberBetween(1, novaCore.MAX_RECURRING_APPOINTMENT_WEEKS)
      ];
    });
    const formattedCheckedDays = computed(() => {
      return orderedCheckedDays.value.map(day => {
        return novaCore.getLongWeekdayByShortName(day);
      });
    });

    const hasFailures = computed(() => {
      return failures.value.length > 0;
    });

    const failureCount = computed(() => {
      return failures.value.length;
    });

    const totalAttemptedCount = computed(() => {
      return failureCount.value + successes.value.length;
    });

    const addEventListeners = () => {
      document.querySelectorAll('.disable-label-click').forEach(element => {
        element.querySelector('label').addEventListener('click', clickHandler);
      });
    };

    const clickHandler = e => {
      e.stopPropagation();
    };

    const createRecurringSeries = async () => {
      if (formRef.value.validate()) {
        loading.value = true;
        await axios
          .post(`appointment/${props.appointment.id}/recurring`, {
            numWeeks: parseInt(numWeeks.value),
            weekDays: novaCore.mapLongWeekdaysToEnLocale(formattedCheckedDays.value),
            copyFields: copyFields.value,
            startingStatus: startingStatus.value
          })
          .then(({ data: response }) => {
            successes.value = response.data.successes;
            if (response.data.failures.length > 0) {
              failures.value = novaCore.sortBy(response.data.failures, 'start');
            } else {
              store.dispatch('Appointments/trackMixpanelEvent', {
                appointment: props.appointment,
                change: 'Recurrence Created'
              });
              notify('Recurrence Created Successfully', 'success');
              context.emit('close');
            }
            if (successes.value.length > 0) {
              store.dispatch('Calendar/refreshEventByAppointmentId', {
                id: props.appointment.id,
                mutation: 'insertEvent'
              });
            }
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    const formatFieldName = fieldName => {
      return _.startCase(fieldName);
    };

    const toggleItem = item => {
      if (!copyFields.value) {
        copyFields.value = [];
      }
      const existingItemIndex = copyFields.value.findIndex(existingItem => existingItem === item);
      if (existingItemIndex < 0) {
        copyFields.value.push(item);
      } else {
        copyFields.value.splice(existingItemIndex, 1);
      }
    };

    const exportToExcel = () => {
      const data = failures.value.map(failure => {
        return {
          'Date/Time': novaCore.renderUtcInTimezone(
            failure.start,
            props.appointment.dock.warehouse.timezone
          ),
          Reason: failure.reason
        };
      });
      const wb = utils.book_new();

      utils.book_append_sheet(wb, utils.json_to_sheet(data), 'Failed Recurring Appointments');

      writeFile(wb, 'Recurring Appointment Failures.xlsx');
    };

    watch(failures, newFailures => {
      if (newFailures.length) {
        context.emit('update-title', 'We found some issues');
      } else {
        context.emit('update-title', 'Create Recurrence');
      }
    });

    onMounted(() => {
      addEventListeners();
    });

    return {
      loading,
      checkedDaysProxy,
      numWeeks,
      successes,
      failures,
      copyFields,
      startingStatus,
      isRequestedStatusSet,
      orderedCheckedDays,
      estimatedEndDate,
      recurringExtraFieldsToCopyEnum,
      canSubmit,
      maxNumWeeks,
      afterRules,
      formattedCheckedDays,
      hasFailures,
      failureCount,
      totalAttemptedCount,
      addEventListeners,
      clickHandler,
      createRecurringSeries,
      formatFieldName,
      toggleItem,
      exportToExcel,
      shortWeekdays,
      formRef
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep .create-recurring-series-form {
  .actions {
    border-top: 1px solid $color-line-divider;
  }

  .form-wrapper {
    margin-bottom: 60px !important;
  }

  .v-text-field__suffix {
    font-size: 14px;
    color: $color-text-tertiary;
  }

  .weeks-input {
    max-width: 150px !important;
  }

  .v-progress-linear {
    margin-top: calc(30vh - 10%) !important;
  }
}
</style>
