<template>
  <div class="d-flex">
    <div class="pr-8 sidebar border-r">
      <div class="sidebar-content" :class="{ 'has-asset-visit': assetVisit?.id }">
        <h3 class="black--text">Summary</h3>
        <p v-if="shouldShowSummaryDescription" class="font-size-x-small py-2 text-dense">
          You can follow the appointment summary here.
        </p>
        <div class="py-3">
          <template v-if="shouldShowStep1Summary">
            <summary-item
              class="mb-4"
              title="Carrier Contact"
              :data="selectedCarrierSummary"></summary-item>
            <summary-item
              class="mb-4"
              title="Warehouse"
              :data="selectedWarehouse.name"></summary-item>
            <summary-item
              class="mb-4"
              title="Load Type"
              :data="selectedLoadType.name"></summary-item>
            <summary-item
              class="mb-4"
              :title="dockSummaryTitle"
              :data="selectedDocksSummary"></summary-item>
          </template>
          <summary-item
            v-if="shouldShowStep2Summary"
            class="mb-4"
            title="Date & Time"
            :data="dateTimeSummary"></summary-item>
        </div>

        <template v-if="assetVisit?.id">
          <v-divider class="my-4"></v-divider>
          <div>
            <h3 class="black--text">Asset Details</h3>
            <p class="font-size-x-small pt-2 pb-0 text-dense">
              Details of the asset you'd like to create an appointment from
            </p>
            <div
              class="d-flex flex-column"
              data-testid="create-appointment-dialog-asset-details-container">
              <asset-visit-form-data
                :asset-visit="assetVisit"
                :is-list="true"
                is-compact
                bold-labels
                :is-inline="false"
                phone-title="phone"
                company-title="Company"></asset-visit-form-data>
            </div>
          </div>
        </template>
      </div>
    </div>
    <form-base :header="header" v-if="!submitted" class="flex-grow-1 pl-8">
      <template #form>
        <v-form ref="form" class="d-flex create-appointment-form inline-fields">
          <v-stepper v-model="step" class="full-width">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">Basic Information</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2">Date & Time</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">Appointment Details</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1" class="px-0">
                <v-container>
                  <v-row v-show="false">
                    <org-select
                      :required="true"
                      label="Org"
                      v-model="selectedOrg"
                      :hide-icon="true"></org-select>
                  </v-row>
                  <v-row>
                    <warehouse-select
                      :disabled="!selectedCarrier && !context.hasOwnProperty('selectedWarehouse')"
                      :restricted-warehouse-ids="$me.warehouseAccessList"
                      :required="true"
                      label="Warehouse"
                      tooltipText="Select warehouse location for the appointment"
                      v-model="selectedWarehouse"
                      :hide-icon="true"
                      @clear-warehouse="clearWarehouseContext"
                      @close="$emit('close')"></warehouse-select>
                  </v-row>
                  <v-row>
                    <carrier-select
                      required
                      v-model="selectedCarrier"
                      :warehouse="selectedWarehouse"
                      showWarning
                      v-if="isContextSet"
                      entry-point="Create Appointment Modal"
                      label="Carrier Contact"
                      tooltipText="Select carrier contact for the appointment"
                      hide-icon></carrier-select>
                  </v-row>
                  <v-row>
                    <load-type-select
                      :disabled="!selectedWarehouse.id"
                      :selected-docks="selectedDocks"
                      :required="true"
                      label="Load Type"
                      tooltipText="Select load type for the appointment"
                      v-model="selectedLoadType"
                      :selected-warehouse="selectedWarehouse"
                      :showOnlyAssignedLoadTypes="true"
                      :hide-icon="true"
                      @close="$emit('close')"></load-type-select>
                  </v-row>
                  <v-row>
                    <dock-select
                      :disabled="!selectedWarehouse.id && !context.hasOwnProperty('selectedDocks')"
                      label="Docks"
                      tooltipText="Select dock for the appointment (or allow system to select automatically)"
                      :required="true"
                      :multi-select="true"
                      v-model="selectedDocks"
                      :docks="warehouseDocks"
                      :hide-icon="true"></dock-select>
                  </v-row>
                </v-container>
              </v-stepper-content>

              <v-stepper-content step="2" class="px-0">
                <date-time-picker
                  v-if="step === 2"
                  v-model="selectedTime"
                  :selected-docks="selectedDocks"
                  :selected-load-type="selectedLoadType"
                  :start-date="selectedDate"
                  :selected-warehouse="selectedWarehouse"
                  :timezone="selectedWarehouse.timezone"
                  @availability-error="handleAvailabilityError"></date-time-picker>
              </v-stepper-content>

              <v-stepper-content step="3" class="px-0">
                <v-container>
                  <v-row v-if="$refNumSettings(selectedWarehouse).isVisible">
                    <h3>
                      {{ $refNumSettings(selectedWarehouse).displayName }}:
                      <help-icon-tooltip v-if="$refNumSettings(selectedWarehouse).helperText">
                        {{ $refNumSettings(selectedWarehouse).helperText }}
                      </help-icon-tooltip>
                      <help-icon-tooltip v-else>
                        Reference or PO Number associated with appointment or load delivery
                      </help-icon-tooltip>
                    </h3>
                    <auto-expandable-textarea
                      v-model="refNumber"
                      :label="refNumberPlaceholder"
                      :placeholder="refNumberPlaceholder"
                      validate-on-blur
                      :rules="
                        $validator.rules.required($refNumSettings(selectedWarehouse).displayName)
                      "
                      required
                      v-if="
                        $refNumSettings(selectedWarehouse).isRequired
                      "></auto-expandable-textarea>
                    <auto-expandable-textarea
                      v-else
                      v-model="refNumber"
                      :label="refNumberPlaceholder"
                      :placeholder="refNumberPlaceholder"
                      validate-on-blur></auto-expandable-textarea>
                  </v-row>
                  <custom-fields-old
                    v-if="selectedWarehouse.id"
                    :initial-custom-fields="customFields"
                    v-model="customFields"
                    :warehouse="selectedWarehouse"
                    @toggle-confirm-button="toggleConfirmButton"></custom-fields-old>
                  <v-row class="mt-4">
                    <h3>
                      Appointment Notes:
                      <help-icon-tooltip>
                        Include any additional notes for this appointment
                      </help-icon-tooltip>
                    </h3>

                    <rich-text-input
                      class="flex-fill"
                      :editor-config="{
                        plugins: ['Essentials', 'Paragraph'],
                        toolbar: { items: [] }
                      }"
                      v-model="notes"></rich-text-input>
                  </v-row>
                  <v-row class="mt-4">
                    <h3>
                      Appointment Tags:
                      <help-icon-tooltip>
                        Select from list of tags or create your own custom tag
                      </help-icon-tooltip>
                    </h3>
                    <v-col>
                      <tag-manager
                        outlined
                        v-model="tags"
                        :outlined-chips="true"
                        :should-enable-custom-tags="true"
                        small-chips
                        placeholder="Add tags"></tag-manager>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <h3>
                      Email Subscribers:
                      <help-icon-tooltip>
                        List of emails that will receive all notifications about the appointment
                      </help-icon-tooltip>
                    </h3>
                    <email-list-field
                      small-chips
                      small
                      v-model="ccEmails"
                      :disabled="fetchingOrgCarrierSettings"
                      ref="emailFieldList"></email-list-field>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col>
                      <v-switch
                        dense
                        v-model="notificationsEnabled"
                        :disabled="!notificationsEnabledOrgLevel">
                        <template v-slot:label>
                          <span>
                            <v-icon small class="ml-2 mr-1">
                              {{ notificationsEnabled ? 'mdi-bell' : 'mdi-bell-off-outline' }}
                            </v-icon>
                            Email notifications
                            <strong>{{ notificationsEnabled ? 'on' : 'off' }}</strong>
                            <small v-if="!notificationsEnabledOrgLevel">
                              (disabled on the Org level)
                            </small>
                          </span>
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </template>
      <template #form-actions>
        <action-group
          :loading="loading"
          :hide-cancel="true"
          :confirm-label="stepperLabelNext"
          :disable-confirm="disableConfirm || !showNextButton"
          @confirm="proceed">
          <template v-slot:additional-actions>
            <outline-button @click="step--" v-if="step > 1" before-icon="arrow-left">
              Previous
            </outline-button>
          </template>
        </action-group>
      </template>
    </form-base>
  </div>
</template>

<script>
import { isAxiosError } from '@satellite/plugins/util';

/**
 * Form that creates an appointment
 * @displayName Create Appointment Form
 */
export default {
  props: {
    /**
     * Form header
     */
    header: {
      type: String,
      required: false
    },
    /**
     * Calendar context when dayview is clicked
     * contains date, time, warehouse, and dock
     */
    context: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    assetVisit: {
      type: Object,
      required: false
    }
  },
  computed: {
    displayTimePicker() {
      return this.selectedLoadType?.id;
    },
    /**
     * Label for the next button depending on step
     * @returns {string}
     */
    stepperLabelNext() {
      return this.step < this.stepsTotal ? 'Next' : 'Create';
    },
    /**
     * If a warehouse has been selected
     * @returns {boolean}
     */
    warehouseSelected() {
      return !!(this.selectedWarehouse && this.selectedWarehouse.id);
    },
    /**
     * Display the next button in the stepper
     * @returns {*}
     */
    showNextButton() {
      let showNextButton = false;
      if (this.step === 1) {
        showNextButton = this.validateStep1();
      } else if (this.step === 2) {
        showNextButton = this.validateStep2();
      } else {
        showNextButton = true;
      }

      return showNextButton;
    },
    warehouseDocks() {
      let docks = this.selectedWarehouse?.id ? this.selectedWarehouse.docks : [];

      if (this.selectedLoadType?.id) {
        docks = docks.filter(dock => {
          return dock.loadTypeIds.includes(this.selectedLoadType.id);
        });
      }

      return docks;
    },
    shouldShowStep1Summary() {
      return Boolean(
        this.selectedWarehouse?.id &&
          this.selectedCarrier?.id &&
          this.selectedLoadType?.id &&
          this.selectedDocks.length
      );
    },
    shouldShowStep2Summary() {
      return Boolean(this.selectedTime?.start);
    },
    shouldShowSummaryDescription() {
      return !this.shouldShowStep1Summary && !this.shouldShowStep2Summary;
    },
    selectedCarrierSummary() {
      return this.selectedCarrier?.id
        ? `${this.selectedCarrier.firstName} ${this.selectedCarrier.lastName} - ${this.selectedCarrier.company.name}`
        : '';
    },
    dockSummaryTitle() {
      return this.selectedDocks.length === 1 ? 'Dock' : 'Docks';
    },
    selectedDocksSummary() {
      return this.novaCore
        .deepClone(this.selectedDocks)
        .map(dock => dock.name)
        .join(', ');
    },
    dateTimeSummary() {
      return this.selectedTime?.start
        ? `${this.novaCore.formatDateTimeWithMilitarySupport(
            this.selectedTime.start.format(),
            this.selectedTime.start.tz(),
            this.novaCore.LuxonDateTimeFormats.LongDateTimeShortMonth,
            this.$isMilitaryTimeEnabled(this.selectedWarehouse),
            this.novaCore.LuxonDateTimeFormats.LongDateTime24ShortMonth
          )} (${this.selectedWarehouse.timezone})`
        : '';
    },
    refNumberPlaceholder() {
      return (
        this.$refNumSettings(this.selectedWarehouse).helperText ||
        this.$refNumSettings(this.selectedWarehouse).displayName
      );
    }
  },
  data() {
    return {
      loading: false,
      step: 1,
      stepsTotal: 3,
      submitted: false,
      selectedOrg: {},
      selectedWarehouse: {},
      selectedDocks: [],
      selectedLoadType: {},
      selectedCarrier: null,
      selectedDate: '',
      selectedTime: null,
      customFields: [],
      refNumber: '',
      ccEmails: [],
      disableConfirm: false,
      mounted: false,
      notes: '',
      tags: [],
      isContextSet: false,
      fetchingOrgCarrierSettings: false,
      notificationsEnabled: true,
      notificationsEnabledOrgLevel: true,
      assetDetailsPanel: false
    };
  },
  methods: {
    /**
     * Submit the form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      if (
        this.$refs.form.validate() &&
        this.$refs.emailFieldList.validate() &&
        this.validateStep1() &&
        this.validateStep2()
      ) {
        return this.createAppointment();
      } else {
        this.$nextTick(() => {
          this.util.scrollFirstErrorIntoView();
        });
      }
    },
    /**
     * Create appointment
     * @public
     * @returns {Promise<void>}
     */
    async createAppointment() {
      this.loading = true;
      const isClone = Boolean(this.context.id);
      const metadata = this.context.metadata
        ? {
            ...this.context.metadata,
            ...{ clonedFromId: this.context.id }
          }
        : { clonedFromId: this.context.id };

      try {
        let response = await this.$store.dispatch('Appointments/createAppointment', {
          userId: this.selectedCarrier.id,
          warehouseId: this.selectedWarehouse.id,
          loadTypeId: this.selectedLoadType.id,
          dockId: this.selectedTime.docks?.[0]?.id || this.selectedDocks[0].id,
          start: this.selectedTime.start.clone().utc().format(),
          refNumber: this.refNumber,
          notes: this.notes,
          tags: this.tags,
          ccEmails: this.ccEmails,
          customFields: this.customFields,
          isClone,
          metadata: isClone ? metadata : null,
          muteNotifications: !this.notificationsEnabledOrgLevel ? false : !this.notificationsEnabled
        });

        if (response?.data) {
          const entryPoint = this.mixpanel.getEntryPoint(this, [
            {
              entryPoint: 'Sidebar',
              component: 'navigation-drawer'
            },
            {
              entryPoint: 'Clone Appointment',
              component: 'appointment-details-dialog'
            },
            {
              entryPoint: 'Appointments Grid',
              component: 'appointments-page'
            },
            {
              entryPoint: 'Docks Page',
              component: 'dock-list'
            },
            {
              entryPoint: 'Warehouses Page',
              component: 'warehouses-page'
            },
            {
              entryPoint: 'Carrier Contacts Page',
              component: 'carrier-list'
            }
          ]);
          this.mixpanel.track(this.mixpanel.events.MODULE.APPOINTMENT.CREATED, {
            'Appointment ID': response.data.id,
            'Appointment Start': response.data.start,
            'Appointment Ref #': response.data.refNumber,
            'Appointment Tags': response.data.tags,
            'Entry Point': entryPoint,
            'Created By': 'Warehouse user',
            'Creation Status': response.data.status,
            'Warehouse ID': this.selectedWarehouse?.id,
            'Warehouse Name': this.selectedWarehouse?.name,
            'Org ID': this.$org.id,
            'Org Name': this.$org.name
          });

          this.$emit('close');
          this.$emit('scheduled', response?.data);
          this.handleAppointmentQueryString(this.$route.path, response?.data?.id);
        }
      } catch (err) {
        if (!isAxiosError(err)) {
          this.notify('An error occurred while creating the appointment', 'error');
          console.error(err);
        }
      } finally {
        this.loading = false;
      }
    },
    /**
     * Emit close event when form lives in dialog
     * @public
     */
    cancel() {
      /**
       * Emits close event
       * @public
       * @event close
       */
      this.$emit('close');
    },
    /**
     * proceed to next step
     * @public
     */
    async proceed() {
      if (this.step === 1) {
        await this.updateSelectedWarehouse();
      }
      if (this.step < this.stepsTotal) {
        this.step++;
        this.scrollToHeader();
      } else {
        await this.submit();
      }
    },
    /**
     * Validate whether step 1 is completed or not
     * @public
     * @returns {boolean}
     */
    validateStep1() {
      return Boolean(
        this.selectedCarrier?.id &&
          this.selectedWarehouse?.id &&
          this.selectedLoadType?.id &&
          this.selectedDocks?.length > 0 &&
          this.selectedDocks?.every(Boolean)
      );
    },
    /**
     * Validate whether step 2 is completed or not
     * @public
     * @returns {boolean}
     */
    validateStep2() {
      return Boolean(this.selectedTime?.start);
    },
    /**
     * Clears data dependent on warehouse when new warehouse is selected
     * @public
     */
    clearWarehouseContext() {
      this.selectedDocks = [];
      this.selectedLoadType = {};
    },
    toggleConfirmButton(disableButton) {
      this.disableConfirm = disableButton;
    },
    /**
     * if a value is passed and there is no context, the form will auto increment to the next step
     * @param value
     */
    autoIncrementStep(value) {
      const stepHasValue = Object.keys(value).length;
      const autoIncrementConditions = !Object.keys(this.context).length || this.assetVisit?.id;
      if (stepHasValue && autoIncrementConditions && this.mounted) {
        this.step++;
      }
    },
    scrollToHeader() {
      const titleElements = document.getElementsByClassName('v-stepper__header');
      if (titleElements?.length > 0) {
        titleElements[0].scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    },
    async updateSelectedWarehouse() {
      if (this.selectedWarehouse?.id) {
        this.selectedWarehouse = await this.services.warehouse.getWarehouseById(
          this.selectedWarehouse.id,
          {},
          {
            fields: [
              ...this.services.warehouse.requestOptions.grid.fields,
              'customApptFieldsTemplate'
            ],
            joins: [
              'docks||name,loadTypeIds,isActive,capacityParentId,sortOrder',
              'docks.capacityChildren||name,isActive,capacityParentId,loadTypeIds,sortOrder',
              'org||name,settings'
            ]
          }
        );
      }
    },
    async buildContextFromAppointment(appointment) {
      const context = this.novaCore.deepClone(appointment);
      context.selectedWarehouse = context.dock?.warehouse;
      context.selectedDocks = [appointment.dock];
      context.selectedCarrier = appointment.user || {};
      context.selectedLoadType = appointment.loadType;

      return context;
    },
    setContext(context) {
      this.selectedWarehouse = context.selectedWarehouse || {};
      this.selectedCarrier = context.selectedCarrier || {};
      this.selectedDate = context.selectedDate || '';
      // When cloning the appointment, this property is never set
      // The user is forced to select a time to move forward.
      if (context.selectedTime?.start) {
        this.selectedTime = context.selectedTime;
      }
      this.customFields = context.customFields || [];
      this.refNumber = context.refNumber || '';
      this.notes = context.notes || '';
      this.tags = context.tags || [];
      this.ccEmails = context.ccEmails || [];
      this.selectedDocks = context.selectedDocks || [];
      this.selectedLoadType = context.selectedLoadType || {};
      this.notificationsEnabled = !context.muteNotifications;
      this.isContextSet = true;
    },
    async handleAvailabilityError() {
      /**
       * Back to step 2 after 750ms
       * The intention of the delay is just a UX improvement to avoid a fast transition between steps
       */
      setTimeout(() => {
        if (this.validateStep1() && this.step === 2) {
          this.step = 1;
        }
      }, 750);
    },
    getNotificationSettingsValue() {
      this.notificationsEnabledOrgLevel = !this.$getSettingValue(
        'muteAppointmentNotifications',
        this.$org?.settings
      );
      this.notificationsEnabled = this.notificationsEnabledOrgLevel;
    }
  },
  async created() {
    // Check if the context is an actual appointment or pre-built context
    if (this.context?.id) {
      this.setContext(await this.buildContextFromAppointment(this.context));
    } else {
      this.setContext(this.context);
    }
  },
  mounted() {
    this.getNotificationSettingsValue();
    this.mounted = true;
  },
  watch: {
    selectedTime(value) {
      this.autoIncrementStep(value);
    },
    selectedWarehouse(newWarehouse, oldWarehouse) {
      if (this.mounted) {
        const warehouseChanged =
          newWarehouse?.id && oldWarehouse?.id && newWarehouse.id !== oldWarehouse.id;

        if (warehouseChanged) {
          this.clearWarehouseContext();
          this.customFields = newWarehouse.customFields ?? [];
        }
      }
    },
    async selectedCarrier(newCarrier, oldCarrier) {
      if (oldCarrier?.email) {
        this.ccEmails.splice(this.ccEmails.indexOf(oldCarrier.email), 1);
      }
      if (newCarrier?.id) {
        this.fetchingOrgCarrierSettings = true;
        try {
          const orgCarrierSettings = newCarrier.orgCarrierSettings?.[0] ?? [];
          this.ccEmails = orgCarrierSettings?.emailCCs ?? [];
        } finally {
          this.fetchingOrgCarrierSettings = false;
        }
      }

      // Keep origin ccEmails when cloning an appointment
      if (this.context.id && this.context.ccEmails?.length > 0) {
        this.ccEmails = [...this.ccEmails, ...this.context.ccEmails].sort();
      }

      // Ensure there is no duplicates or rubbish
      this.ccEmails = _.compact(_.uniq([...this.ccEmails]));
    }
  }
};
</script>

<style scoped lang="scss">
.sidebar-content {
  &.has-asset-visit {
    max-height: 75dvh;
    overflow: auto;
    padding-bottom: 65px;
  }
}
</style>
