var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.showLoadTypeForm
        ? _c(
            "v-card",
            { class: { disabled: _vm.readOnly } },
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    { staticClass: "search-field", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c("text-field", {
                            staticClass: "mb-5",
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: "Search load types",
                              hint: "Search load types",
                              "single-line": "",
                            },
                            model: {
                              value: _vm.filters.searchStr,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "searchStr", $$v)
                              },
                              expression: "filters.searchStr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        disabled: !_vm.isCreationButtonDisabled,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "div",
                                  _vm._g(_vm._b({}, "div", attrs, false), on),
                                  [
                                    _c(
                                      "primary-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          disabled: Boolean(
                                            _vm.getDisabledCreationMessage
                                          ),
                                          "before-icon": "plus",
                                          large: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showLoadTypeForm = true
                                          },
                                        },
                                      },
                                      [_vm._v(" Create Load Type ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        652069749
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.getDisabledCreationMessage)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                class: { disabled: _vm.readOnly },
                attrs: {
                  headers: _vm.rowHeaders,
                  items: _vm.$loadTypes,
                  loading: _vm.loading,
                  "server-items-length": _vm.$loadTypePagination.total,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  "footer-props": _vm.footerProps,
                  options: _vm.options,
                },
                on: {
                  "update:sortBy": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.name`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "copy-content",
                            { attrs: { content: item.name } },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.description`,
                      fn: function ({ item }) {
                        return [
                          item.description
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c("span", _vm._g({}, on), [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.novaCore.truncateString(
                                                    item.description,
                                                    25
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v(_vm._s(item.description))])]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "header.warehouses",
                      fn: function () {
                        return [
                          _c("span", { staticClass: "d-inline-block mr-1" }, [
                            _vm._v("Warehouses"),
                          ]),
                          _c("help-icon-tooltip", [
                            _vm._v(
                              " List of all warehouses where this load type is assigned to at least one dock "
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "item.duration_min",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.novaCore.formatMinutesToHuman(
                                  item.duration_min
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.allowCarrierScheduling",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                !item.allowCarrierScheduling ? "Yes" : "No"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.warehouses",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              staticClass: "link px-0",
                              attrs: { text: "", "x-small": "" },
                              on: {
                                click: function ($event) {
                                  _vm.warehouseDialog = {
                                    shouldShow: true,
                                    entity: item,
                                  }
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getWarehouseList(item.warehouses)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.actions",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "row-actions" },
                            [
                              _c("audit-log-entity-icon-button", {
                                attrs: {
                                  item: item,
                                  timezone: _vm.warehouse?.timezone ?? "UTC",
                                  "entity-type":
                                    _vm.novaCore.DatabaseEntities.LoadType,
                                },
                              }),
                              _c("icon-tooltip-button", {
                                attrs: {
                                  disabled: _vm.readOnly,
                                  "icon-class": "ml-2",
                                  size: "large",
                                  tooltip: _vm.readOnly
                                    ? _vm.novaCore.getRoleActionError()
                                    : "Edit Load Type",
                                  edit: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showEditDialog(item)
                                  },
                                },
                              }),
                              _c("icon-tooltip-button", {
                                attrs: {
                                  disabled: _vm.readOnly,
                                  "icon-class": "ml-2",
                                  size: "large",
                                  tooltip: _vm.readOnly
                                    ? _vm.novaCore.getRoleActionError()
                                    : "Delete Load Type",
                                  delete: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showDeleteLoadTypeConfirmation(
                                      item
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        : _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-uppercase text-caption grey--text text--darken-2",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.editingLoadType
                                ? "edit load type"
                                : "create load type"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("br"),
                    _c("span", [_vm._v(" Load Type Details ")]),
                  ]),
                  _c("v-spacer"),
                  _c("v-icon", { on: { click: _vm.closeEditOrCreateDialog } }, [
                    _vm._v("mdi-close"),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("load-type-form", {
                    ref: "createLoadTypeForm",
                    attrs: {
                      warehouse: _vm.warehouse,
                      loadType: _vm.editingLoadType,
                    },
                    on: { close: _vm.closeEditOrCreateDialog },
                  }),
                ],
                1
              ),
            ],
            1
          ),
      _vm.warehouseDialog.entity
        ? _c("list-warehouse-dialog", {
            attrs: {
              "warehouse-dialog": _vm.warehouseDialog,
              warehouses: _vm.warehouseDialog.entity.warehouses,
              label: `${_vm.warehouseDialog.entity.name} is being used at the following locations:`,
              header: `Warehouses using ${_vm.warehouseDialog.entity.name}`,
            },
            on: { close: _vm.closeWarehouseDialog },
          })
        : _vm._e(),
      _vm.deletingLoadType
        ? _c("confirm", {
            attrs: {
              "should-show": _vm.showDeleteConfirm,
              "is-manual-mode": "",
              persistent: "",
              icon: "mdi-delete-forever",
              "delete-confirmation": "",
              "show-delete-warning": !_vm.deleteMessageHtml,
              title: "Delete this Load Type?",
              width: 650,
              loading: _vm.deleting,
              "confirmation-input-text": _vm.deleteConfirmationInputText,
              "entity-name": _vm.deletingLoadType.name,
              "button-true-text": "YES, DELETE",
              "button-true-color": "error",
              color: "error",
            },
            on: { result: _vm.handleDeleteResult },
            scopedSlots: _vm._u(
              [
                {
                  key: "message",
                  fn: function () {
                    return [
                      _vm.deleting
                        ? _c("v-skeleton-loader", {
                            attrs: { type: "list-item-three-line" },
                          })
                        : _vm.deleteMessageHtml
                        ? _c(
                            "div",
                            [
                              _c("h2", { staticClass: "text-center my-3" }, [
                                _vm._v("Appointments will also be deleted!"),
                              ]),
                              _c(
                                "v-alert",
                                {
                                  staticClass: "my-8",
                                  attrs: {
                                    text: "",
                                    prominent: "",
                                    border: "left",
                                    color: "red",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "red" },
                                    },
                                    [_vm._v("mdi-alert")]
                                  ),
                                  _c("span", {
                                    staticClass: "text--primary",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.deleteMessageHtml),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3154727195
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }