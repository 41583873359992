var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          loading: _vm.loading,
          header: "Appointment Details",
          "loading-message": "",
          "max-width": "100%",
          value: _vm.showDialog,
          width: "980px",
        },
        on: { close: _vm.closeDialog },
        scopedSlots: _vm._u(
          [
            {
              key: "header-actions",
              fn: function () {
                return [
                  _vm.appointmentWarehouse
                    ? _c("appointment-action-group-old", {
                        attrs: {
                          "appointment-warehouse": _vm.appointmentWarehouse,
                          "parent-appointment": _vm.parentAppointment,
                          appointment: _vm.appointment,
                          "appointment-actions": _vm.appointmentActionsOld,
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "primary-button",
                        _vm._g(
                          _vm._b({}, "primary-button", slotProps.attrs, false),
                          slotProps.on
                        ),
                        [_vm._v("View Appointment")]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _vm.appointmentWarehouse
                    ? _c("appointment-details-old", {
                        attrs: {
                          "read-only": !_vm.canUserAccessWarehouse,
                          "appointment-warehouse": _vm.appointmentWarehouse,
                          "parent-appointment": _vm.parentAppointment,
                          appointment: _vm.appointment,
                          "should-edit-status": true,
                          "should-display-cancel-button": false,
                        },
                        on: {
                          close: _vm.close,
                          "change-active-tab": _vm.handleChangeActiveTab,
                        },
                      })
                    : _vm._e(),
                  _c("cancel-appointment-dialog", {
                    ref: "cancelAppointment",
                    attrs: {
                      "external-activator": true,
                      "show-dialog": _vm.showConfirmDialog,
                      appointment: _vm.appointment,
                    },
                    on: {
                      close: function ($event) {
                        _vm.showConfirmDialog = false
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
            _vm.showFooter && (_vm.canCancel || _vm.isCancelled)
              ? {
                  key: "dialog-actions",
                  fn: function () {
                    return [
                      !_vm.isCancelled &&
                      _vm.$rolePermissions.canUpdateAppointment &&
                      _vm.canUserAccessWarehouse
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "text__error font-weight-bold",
                              attrs: {
                                elevation: "0",
                                color: "white",
                                disabled:
                                  _vm.appointment.status ===
                                  _vm.novaCore.AppointmentStatus.Cancelled,
                              },
                              on: { click: _vm.confirmCancel },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.cancelButtonIcon)),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.cancelAppointmentLabel) + " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }