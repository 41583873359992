var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("form-base", {
        staticClass: "create-recurring-series-form",
        attrs: { "hide-required-legend": true },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function () {
              return [
                !_vm.hasFailures
                  ? _c(
                      "v-form",
                      {
                        ref: "formRef",
                        staticClass: "pt-1 mx-3 inline-fields",
                      },
                      [
                        _vm.loading
                          ? [
                              _c("v-progress-linear", {
                                staticClass: "mt-6",
                                attrs: {
                                  indeterminate: "",
                                  loading: _vm.loading,
                                  height: "6",
                                },
                              }),
                              _c(
                                "h4",
                                {
                                  staticClass:
                                    "text-center mt-4 mb-12 text--color-text-tertiary",
                                },
                                [_vm._v(" Creating Appointments ... ")]
                              ),
                            ]
                          : [
                              _c(
                                "v-row",
                                { staticClass: "mt-3 mb-6" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c(
                                        "strong",
                                        { staticClass: "black--text" },
                                        [_vm._v("Repeat every:")]
                                      ),
                                      _c("weekday-checkbox-group", {
                                        attrs: {
                                          horizontal: "",
                                          "short-names": "",
                                          "custom-weekdays": _vm.shortWeekdays,
                                        },
                                        model: {
                                          value: _vm.checkedDaysProxy,
                                          callback: function ($$v) {
                                            _vm.checkedDaysProxy = $$v
                                          },
                                          expression: "checkedDaysProxy",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "my-3" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { md: "7" } },
                                    [
                                      _c(
                                        "strong",
                                        { staticClass: "black--text" },
                                        [_vm._v("Ends After:")]
                                      ),
                                      _c("v-text-field", {
                                        staticClass: "mt-2 weeks-input",
                                        attrs: {
                                          dense: "",
                                          min: "0",
                                          max: _vm.maxNumWeeks,
                                          "hide-details": "",
                                          rules: _vm.afterRules,
                                          type: "number",
                                          suffix: "weeks in a row",
                                        },
                                        model: {
                                          value: _vm.numWeeks,
                                          callback: function ($$v) {
                                            _vm.numWeeks = $$v
                                          },
                                          expression: "numWeeks",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("p", {
                                staticClass:
                                  "font-size-x-small estimated-end-date",
                                domProps: {
                                  innerHTML: _vm._s(_vm.estimatedEndDate),
                                },
                              }),
                              !_vm.novaCore.isReserve(_vm.appointment)
                                ? _c(
                                    "v-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "strong",
                                                { staticClass: "black--text" },
                                                [_vm._v("Copy values of")]
                                              ),
                                              _c("help-icon-tooltip", [
                                                _vm._v(
                                                  " Copy the selected fields for all child appointments created in the recurring series "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.recurringExtraFieldsToCopyEnum,
                                            function (item) {
                                              return _c("v-checkbox", {
                                                key: item,
                                                staticClass: "mt-2",
                                                attrs: {
                                                  label:
                                                    _vm.formatFieldName(item),
                                                  value:
                                                    _vm.copyFields.includes(
                                                      item
                                                    ),
                                                  "hide-details": "",
                                                  dense: "",
                                                },
                                                on: {
                                                  click: () =>
                                                    _vm.toggleItem(item),
                                                },
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "strong",
                                                { staticClass: "black--text" },
                                                [
                                                  _vm._v(
                                                    "Starting appointment status"
                                                  ),
                                                ]
                                              ),
                                              _c("help-icon-tooltip", [
                                                _vm._v(
                                                  " The starting status for all child appointments created in the recurring series "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _vm.isRequestedStatusSet
                                            ? _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { "hide-details": "" },
                                                  model: {
                                                    value: _vm.startingStatus,
                                                    callback: function ($$v) {
                                                      _vm.startingStatus = $$v
                                                    },
                                                    expression:
                                                      "startingStatus",
                                                  },
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      value:
                                                        _vm.novaCore
                                                          .AppointmentStatus
                                                          .Scheduled,
                                                      label:
                                                        _vm.novaCore
                                                          .AppointmentStatus
                                                          .Scheduled,
                                                    },
                                                  }),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      value:
                                                        _vm.novaCore
                                                          .AppointmentStatus
                                                          .Requested,
                                                      label:
                                                        _vm.novaCore
                                                          .AppointmentStatus
                                                          .Requested,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "p",
                                                { staticClass: "text--black" },
                                                [
                                                  _c("v-label", [
                                                    _vm._v("Scheduled"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                      ],
                      2
                    )
                  : _c(
                      "v-card",
                      {
                        staticClass: "px-7",
                        attrs: { "max-height": "100%", elevation: "0" },
                      },
                      [
                        _c(
                          "v-alert",
                          {
                            staticClass: "error lighten-5",
                            attrs: {
                              dense: "",
                              border: "left",
                              "colored-border": "",
                              color: "error",
                            },
                          },
                          [
                            _c("strong", { staticClass: "font-size-small" }, [
                              _vm._v(" Appointments with no availability: "),
                              _c(
                                "span",
                                { staticClass: "error--text text--darken-4" },
                                [_vm._v(_vm._s(_vm.failureCount))]
                              ),
                              _vm._v(
                                " / " + _vm._s(_vm.totalAttemptedCount) + " "
                              ),
                            ]),
                          ]
                        ),
                        _c("v-card-text", [
                          _vm._v(
                            " Sorry, we couldn't create the following appointments due to "
                          ),
                          _c("strong", { staticClass: "black--text" }, [
                            _vm._v("no availability on your current schedule."),
                          ]),
                          _c(
                            "div",
                            { staticClass: "pt-4" },
                            _vm._l(_vm.failures, function (failure, i) {
                              return _c(
                                "span",
                                {
                                  key: `failure-${i}`,
                                  staticClass: "font-size-small d-block",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.novaCore.transformForUserFriendlyTimezone(
                                          _vm.novaCore.renderUtcInTimezone(
                                            failure.start,
                                            _vm.appointment.dock.warehouse
                                              .timezone
                                          ),
                                          _vm.appointment.dock.warehouse
                                            .timezone
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ],
                      1
                    ),
              ]
            },
            proxy: true,
          },
          {
            key: "form-actions",
            fn: function () {
              return [
                !_vm.loading && !_vm.hasFailures
                  ? _c("action-group", {
                      key: "recurring-action-create",
                      attrs: {
                        "confirm-label": "Create Recurrence",
                        "disable-confirm": !_vm.canSubmit,
                      },
                      on: {
                        cancel: function ($event) {
                          return _vm.$emit("close")
                        },
                        confirm: _vm.createRecurringSeries,
                      },
                    })
                  : !_vm.loading && _vm.hasFailures
                  ? _c("action-group", {
                      key: "recurring-action-export",
                      attrs: {
                        "cancel-label": "Got It",
                        "cancel-icon": "",
                        "hide-confirm": "",
                        "is-spacer-visible": false,
                        "pre-spacer": true,
                      },
                      on: {
                        cancel: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "additional-actions",
                          fn: function () {
                            return [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: { text: "" },
                                  on: { click: _vm.exportToExcel },
                                },
                                [_vm._v("Export List")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }