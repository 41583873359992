var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c(
        "div",
        [
          _c("v-progress-linear", {
            staticClass: "mt-12",
            attrs: { indeterminate: "", height: "6" },
          }),
          _c("h4", { staticClass: "text-center mt-4" }, [
            _vm._v("Loading Audit Log ..."),
          ]),
        ],
        1
      )
    : _c(
        "audit-log-base",
        _vm._b(
          { attrs: { "log-lines": _vm.logLines } },
          "audit-log-base",
          [_vm.$props, _vm.$attrs],
          false
        )
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }