<template>
  <div>
    <div class="appointment-details">
      <status-manager-old
        :read-only="readOnly"
        :appointment="appointment"
        :warehouse-triggers="warehouseTriggers"
        :should-edit-status="shouldEditStatus"></status-manager-old>
      <div class="mx-2 mb-8">
        <v-row>
          <v-icon class="text-lg-h5 mr-2">mdi-calendar</v-icon>
          <v-btn
            v-if="canReschedule"
            text
            class="text-lg-h6 pa-0 black--text reschedule-date-button"
            @click="showRescheduleDialog = true"
            v-html="appointmentDateTimeHeader"></v-btn>
          <template v-else>
            <span class="text-lg-h6 black--text" v-html="appointmentDateTimeHeader"></span>
          </template>
          <template v-if="reschedulesCount > 0">
            <span class="reschedules-count">{{ reschedulesString }}</span>
          </template>
        </v-row>
        <v-row v-if="recurringPattern.weekDays">
          <div class="font-size-x-small">
            Recurs every
            <strong>{{ recurringPattern.weekDays }} @ {{ recurringPattern.endTime }}</strong>
            until
            <strong>{{ recurringPattern.endDate }}</strong>
          </div>
        </v-row>
      </div>

      <v-simple-table dense class="appointment-details-table" v-if="!loading">
        <template v-slot:default>
          <thead></thead>
          <tbody>
            <template v-for="(row, index) in appointmentTableRows">
              <template v-if="!row.isHidden">
                <tr :key="index + '-detail'">
                  <template>
                    <td class="font-weight-bold" :class="row.tdClass">
                      {{ row.label }}
                    </td>
                    <td :class="row.tdClass">
                      <div v-html="row.value"></div>
                    </td>
                  </template>
                </tr>
              </template>
            </template>

            <tr v-for="customField in Object.values(customFields)" :key="customField.key">
              <td class="font-weight-bold" :class="customField.tdClass">
                {{ customField.label }}
              </td>
              <td :class="customField.tdClass">
                <div v-html="customField.value" class="text-pre-wrap"></div>
              </td>
            </tr>

            <tr>
              <td class="font-weight-bold">
                <div>
                  Appointment Notes
                  <help-icon-tooltip>
                    Notify the Carrier about any appointment observations
                  </help-icon-tooltip>
                </div>
              </td>
              <td>
                <div class="appointment-notes" v-html="appointment.notes || '----'"></div>
              </td>
            </tr>

            <tr>
              <td class="font-weight-bold pt-4">
                <div>
                  Confirmation #
                  <help-icon-tooltip>Unique Appointment Confirmation Number</help-icon-tooltip>
                </div>
              </td>
              <td class="pt-4">
                <div>
                  <span class="mr-4">{{ appointment.confirmationNumber }}</span>
                  <secondary-button
                    x-small
                    @click="copyValueToClipboard(appointment.confirmationNumber)"
                    class="ml-4">
                    Copy
                  </secondary-button>
                </div>
              </td>
            </tr>

            <tr>
              <td class="font-weight-bold pt-4">
                <div>
                  Appointment ID
                  <help-icon-tooltip>Unique Appointment Reference</help-icon-tooltip>
                </div>
              </td>
              <td class="pt-4">
                <div>
                  <span class="mr-4">{{ appointment.id }}</span>
                  <secondary-button
                    x-small
                    @click="copyApptId"
                    class="ml-4"
                    data-testid="appointment-details-copy-appointment-id">
                    Copy
                  </secondary-button>
                </div>
              </td>
            </tr>

            <tr class="divider">
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td class="pt-5 font-weight-bold">Asset details</td>
              <td class="pt-5" data-testid="asset-event-status">
                <span v-if="!appointment.assetVisit?.id">No asset linked</span>
                <v-expansion-panels accordion v-else>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div class="d-flex align-center font-weight-bold">
                        <div class="mr-1">{{ lastEventType ?? 'Not Arrived' }}</div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <asset-visit-form-data
                        v-if="appointment.assetVisit"
                        :company="appointment?.user?.company"
                        :asset-visit="appointment.assetVisit"
                        is-list></asset-visit-form-data>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </td>
            </tr>

            <tr class="divider">
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td class="font-weight-bold pt-5">Carrier Contact</td>
              <td class="pt-5">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div class="d-flex align-center font-weight-bold">
                        <div class="mr-1">{{ companyName }}</div>
                        <div class="mr-2 font-weight-light">- {{ appointment.user.email }}</div>
                        <favorite-button
                          :carrier="appointment.user"
                          :org="$org"
                          @toggle-favorite="toggleFavoriteCarrier"></favorite-button>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="d-block">Name: {{ getUserName(appointment.user) }}</span>
                      <span class="d-block">
                        Email:
                        <copy-content
                          :content="appointment.user.email"
                          label="Click to copy carrier email">
                          {{ appointment.user.email }}
                        </copy-content>
                      </span>
                      <span class="d-block">Phone: {{ appointment.user.phone || '----' }}</span>
                      <span class="d-block">SCAC: {{ scac }}</span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </td>
            </tr>

            <tr class="divider">
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td class="pt-5 font-weight-bold">Warehouse</td>
              <td class="pt-5">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span class="d-block font-weight-bold">
                        {{ appointment.dock.warehouse.name }}
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="d-block">
                        Address:
                        <copy-content
                          :content="getWarehouseAddress(appointment.dock.warehouse)"
                          label="Click to copy warehouse address">
                          {{ getWarehouseAddress(appointment.dock.warehouse) }}
                        </copy-content>
                      </span>
                      <span class="d-block">Email: {{ appointment.dock.warehouse.email }}</span>
                      <span class="d-block">Phone: {{ appointment.dock.warehouse.phone }}</span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </td>
            </tr>

            <tr class="divider">
              <td colspan="2"></td>
            </tr>

            <tr>
              <td class="pt-5 font-weight-bold">
                <div>
                  Appointment Tags
                  <help-icon-tooltip>Tags for internal Usage</help-icon-tooltip>
                </div>
              </td>
              <td class="pt-5">
                <tag-manager
                  outlined
                  v-model="tags"
                  :outlined-chips="true"
                  :should-enable-custom-tags="true"
                  small-chips
                  small
                  :read-only="!$rolePermissions.canUpdateAppointmentTags || readOnly"
                  placeholder="Add tags"
                  :allowTagDelete="!lockTagEditing" />
              </td>
            </tr>

            <tr class="divider">
              <td colspan="2"></td>
            </tr>

            <tr v-for="trigger of gateManagementTriggers" :key="trigger.id" class="divider">
              <td class="font-weight-bold pt-5">
                {{ trigger.flow?.name || novaCore.CustomFormsFeaturesData[trigger.feature].title }}
              </td>
              <td class="py-5">
                <custom-forms-data
                  :key="trigger.id"
                  :trigger="trigger"
                  :object-id="appointment.id"
                  :timezone="warehouse.timezone"
                  :military-time-enabled="$isMilitaryTimeEnabled(warehouse)"></custom-forms-data>
              </td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <template v-if="(canCancel || isCancelled) && shouldDisplayCancelButton">
        <div class="d-flex justify-center my-8">
          <v-btn
            v-if="!isCancelled"
            elevation="0"
            class="text__error font-weight-bold"
            color="white"
            @click="confirmCancel"
            :disabled="appointment.status === novaCore.AppointmentStatus.Cancelled">
            <v-icon class="mr-2">{{ cancelButtonIcon }}</v-icon>
            {{ cancelAppointmentLabel }}
          </v-btn>
        </div>
      </template>

      <v-alert color="error" class="white--text" v-if="!appointment && !loading">
        Could not load appointment data
      </v-alert>
      <template v-if="loading">
        <v-card-text>
          Loading Appointment Data...
          <v-progress-linear color="secondary" indeterminate rounded height="8"></v-progress-linear>
        </v-card-text>
      </template>

      <reschedule-appointment-dialog
        :appointment-warehouse="warehouse"
        @close="showRescheduleDialog = false"
        :external-activator="true"
        :show-dialog="showRescheduleDialog"
        :appointment="appointment"></reschedule-appointment-dialog>

      <cancel-appointment-dialog
        ref="cancelAppointment"
        @confirm="$emit('confirm')"
        :external-activator="true"
        :show-dialog="showConfirmDialog"
        :appointment="appointment"
        @close="showConfirmDialog = false"></cancel-appointment-dialog>
    </div>

    <custom-forms-flow-dialog-old
      :external-activator="true"
      :warehouse="warehouse"
      :show-dialog="hasActiveTrigger"
      :object-id="appointment.id"
      :trigger="activeTrigger"
      @close="cleanUpCustomFormsData"
      @update="updateCustomFormsData"
      @create="
        value => createBulkCustomFormsData(value, warehouse.id)
      "></custom-forms-flow-dialog-old>
  </div>
</template>

<script>
import AppointmentDetailsBase from '@/modules/appointments/components/details/AppointmentDetailsBase.vue';

export default {
  extends: AppointmentDetailsBase
};
</script>
