<template>
  <phone-number-field
    single-line
    v-bind="[$props]"
    v-model="value"
    :validator="$validator"
    :label="field.label"></phone-number-field>
</template>

<script>
import customFieldTypeMixin from '@satellite/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-phone',
  mixins: [customFieldTypeMixin]
};
</script>
