<template>
  <dialog-base
    v-bind="[$props, $attrs]"
    :key="renderKey"
    header="Clone Custom Fields"
    :scrollable="false"
    :loading="false"
    loading-message="Saving..."
    @close="close"
    @confirm="cloneCustomFields">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <v-tooltip top :disabled="!readOnly">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              :disabled="readOnly"
              color="secondary"
              v-on="slotProps.on"
              large
              v-bind="slotProps.attrs"
              ><v-icon left class="ml-0" small>mdi-content-copy</v-icon>Clone Fields
            </v-btn>
          </div>
        </template>
        <span>{{ novaCore.getRoleActionError() }}</span>
      </v-tooltip>
    </template>
    <template slot="body">
      <v-alert border="top" dense colored-border type="info" color="info" elevation="2">
        Selected Custom fields will be appended to this warehouse.<br />
        Duplicate fields will be omitted.
      </v-alert>

      <h4 class="mb-0 pb-0">Warehouse to Clone From</h4>
      <warehouse-select
        v-model="selectedWarehouse"
        :multi-select="false"
        :disable-if-no-docks="false"
        :disabled-warehouse-ids="[this.targetWarehouse.id]"
        :show-create-dock-button="false"
        class="pb-5"
        :disabled="loadingWarehouse"
        :external-loading="loadingWarehouse"
        hide-icon></warehouse-select>

      <h4 class="mb-0 pb-0">Custom Fields to Clone</h4>
      <v-tooltip top :disabled="!!customFieldsTemplate.length">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <custom-field-select
              multiple
              label="Select custom fields"
              placeholder="Select custom fields"
              item-text="label"
              item-value="name"
              :disabled="!selectedWarehouse || !customFieldsTemplate"
              class="mt-0 pt-0"
              return-object
              single-line
              auto-select
              v-model="selectedFields"
              :customFields="customFieldsTemplate"></custom-field-select>
          </div>
        </template>
        <span v-if="!selectedWarehouse">Please select a warehouse</span>
        <span v-else>{{ selectedWarehouse.name }} does not have any custom fields</span>
      </v-tooltip>
    </template>
    <template v-slot:dialog-actions>
      <v-spacer></v-spacer>
      <outline-button before-icon="close-circle" class="mr-3" @click="close">
        Nevermind
      </outline-button>
      <v-tooltip top :disabled="!!selectedFields.length">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn color="success" :disabled="!selectedFields.length" @click="cloneCustomFields">
              <v-icon left class="ml-0" small>mdi-content-copy</v-icon>
              Clone Fields
            </v-btn>
          </div>
        </template>
        <span>Select at least one custom field to clone</span>
      </v-tooltip>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Field Form Dialog
 * @displayName Field Form Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    targetWarehouse: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedWarehouse: null,
      selectedFields: [],
      loadingWarehouse: false,
      customFieldsTemplate: []
    };
  },
  methods: {
    async cloneCustomFields() {
      const targetFields = this.targetWarehouse.customApptFieldsTemplate || [];
      let fieldsToClone = this.selectedFields;

      // Filter out duplicates
      if (targetFields.length) {
        fieldsToClone = this.selectedFields.filter(
          field =>
            !targetFields.find(
              targetField =>
                targetField.label.toLowerCase() === field.label.toLowerCase() &&
                targetField.type === field.type
            )
        );
      }

      // Save warehouse
      await this.$store
        .dispatch('Warehouses/updateWarehouse', {
          id: this.targetWarehouse.id,
          customApptFieldsTemplate: [...targetFields, ...fieldsToClone]
        })
        .then(() => {
          this.close();
        })
        .finally(() => {
          for (const field of fieldsToClone) {
            this.mixpanel.track(this.mixpanel.events.MODULE.APPOINTMENT.APPOINTMENT_FIELD_CREATED, {
              'Org Name': this.$org.name,
              'Org ID': this.$org.id,
              'Warehouse Name': this.targetWarehouse.name,
              'Warehouse ID': this.targetWarehouse.id,
              'Appointment Field Name': field.label,
              'Entry Point': 'Clone Appointment Fields'
            });
          }
        });
    },
    close() {
      this.selectedWarehouse = null;
      this.selectedFields = [];
      this.customFieldsTemplate = [];
      this.selectedFields = [];
      this.$emit('close');
      this.renderKey++;
    }
  },
  watch: {
    async selectedWarehouse(newVal, oldVal) {
      if (newVal?.id && newVal.id !== oldVal?.id) {
        this.loadingWarehouse = true;
        try {
          const warehouse = await this.services.warehouse.getWarehouseById(
            newVal.id,
            {},
            { fields: ['customApptFieldsTemplate'] }
          );
          this.customFieldsTemplate = warehouse.customApptFieldsTemplate ?? [];
        } finally {
          this.loadingWarehouse = false;
        }
      }
    }
  }
};
</script>
