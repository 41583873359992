<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="My Account"
    loading-message="Updating Account Information"
    @close="close"
    @confirm="$refs.myAccountForm.submit()">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <!--
        @slot Custom activator for the dialog
        @binding {object} slotProps Object containing {on, attrs}
      -->
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button
        v-else
        before-icon="account"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        class="mr-2">
        My Account
      </primary-button>
    </template>
    <template slot="body">
      <my-account-form @close="close" ref="myAccountForm"></my-account-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for my Account form
 * @displayName My Account Dialog
 */
export default {
  name: 'my-account-dialog',
  mixins: [dialogMixin]
};
</script>
