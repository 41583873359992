import { ref, computed } from 'vue';
import { IsDefined, isDefined, isEmpty, isObject } from 'class-validator';
import { useEventHub, useNotify, useNovaCore } from '@/composables'; // Assuming you have a composable for event hub

export default function useCustomForms(timezone, militaryTimeEnabled) {
  const novaCore = useNovaCore();
  const activeTrigger = ref(null);
  const customFormsData = ref({});
  const pendingStatusChange = ref(null);
  const eventHub = useEventHub();
  const notify = useNotify();

  const hasActiveTrigger = computed(() => isObject(activeTrigger.value));
  const hasCustomFormsData = computed(
    () => customFormsData.value?.skip === true || customFormsData.value?.length > 0
  );

  const getCustomFormsData = async (objectId, triggerId, lastChangedDateTimeOnly = false) => {
    const params = {
      s: { objectId, triggerId },
      limit: lastChangedDateTimeOnly ? 1 : 1000
    };
    if (lastChangedDateTimeOnly) {
      params.fields = 'id,lastChangedDateTime,formField.id'; // select the least amount of data possible for counts
    }
    if (objectId && triggerId) {
      const response = await window.axios.get('custom-forms/form-data', { params });

      if (lastChangedDateTimeOnly) {
        return response.data?.data?.[0]?.lastChangedDateTime;
      }

      customFormsData.value[triggerId] = (response?.data?.data || []).map(data => {
        data.label = data.formField?.overrideLabel || data.label;
        data.value = getFormattedValue(data);
        return data;
      });
    }
  };

  const createBulkCustomFormsData = async (value, warehouseId) => {
    if (Array.isArray(value)) {
      const firstSubmit = value.every(formData => !formData.id);

      if (firstSubmit) {
        await window.axios.post(`custom-forms/form-data/bulk`, { bulk: value, warehouseId });
        notify(`Appointment data has been updated`, 'success');
        customFormsDataChange();
      } else {
        await updateCustomFormsData(value);
      }
    }
  };

  const updateCustomFormsData = async value => {
    for (const formData of value) {
      if (formData.id) {
        await window.axios.patch(`custom-forms/form-data/${formData.id}`, {
          value: formData.value
        });
      } else if (formData.value !== null) {
        await window.axios.post(`custom-forms/form-data`, { ...formData });
      }
    }

    notify(`Appointment data has been updated`, 'success');
    customFormsDataChange();
  };

  const handleActionFieldTriggered = actionName => {
    if (!actionName) {
      return;
    }
    const featureTrigger = warehouseTriggers?.find(trigger => trigger.feature === actionName);

    if (featureTrigger) {
      if (featureTrigger.isActive && isDefined(featureTrigger.flow)) {
        setTimeout(() => {
          activeTrigger.value = featureTrigger;
        }, 600);
      } else {
        notify('Feature is not enabled', 'warning');
      }
    } else {
      notify('Feature not found', 'warning');
    }
  };

  const customFormsDataChange = () => {
    eventHub.$emit('customformdata-updated');
    cleanUpCustomFormsData();
  };

  const cleanUpCustomFormsData = triggerId => {
    activeTrigger.value = null;
    pendingStatusChange.value = null;
    customFormsData.value[triggerId] = [];
  };

  const checkForTriggerOnStatus = (status, triggers) => {
    let category = null;

    if (hasCustomFormsData.value || isDefined(pendingStatusChange.value)) {
      return;
    }

    const entityName = novaCore.CustomFormFlowEntitiesEnum.warehouse;
    const app = novaCore.AppsEnum.Warehouse;

    if (status === novaCore.AppointmentStatus.Completed) {
      category = novaCore.CustomFormFlowCategoryEnum.appointmentStatusCompleted;
    }

    if (status === novaCore.AppointmentStatus.Arrived) {
      category = novaCore.CustomFormFlowCategoryEnum.appointmentStatusArrived;
    }

    return triggers?.find(
      trigger =>
        (!app || trigger.app === app) &&
        (!entityName || trigger.entityName === entityName) &&
        trigger.category === category &&
        trigger.isActive &&
        IsDefined(trigger.flow)
    );
  };

  const getFormattedValue = field => {
    if (
      field.value === '' ||
      !isDefined(field.value) ||
      isEmpty(field.value) ||
      (Array.isArray(field.value) && field.value.length === 0)
    ) {
      return '--';
    }
    return novaCore.getCustomFieldFormattedValue(field, {
      [novaCore.CustomFieldType.Document]: { generateLink: true },
      [novaCore.CustomFieldType.MultiDocument]: { generateLink: true },
      [novaCore.CustomFieldType.Timestamp]: {
        timezone: timezone,
        formatAsMilitary: militaryTimeEnabled
      }
    });
  };

  return {
    activeTrigger,
    customFormsData,
    pendingStatusChange,
    hasActiveTrigger,
    createBulkCustomFormsData,
    updateCustomFormsData,
    handleActionFieldTriggered,
    customFormsDataChange,
    cleanUpCustomFormsData,
    checkForTriggerOnStatus,
    getCustomFormsData
  };
}
